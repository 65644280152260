import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import { useFetchCreds } from './hooks/fetchCreds'
import { RootState } from './redux/rootReducer'

interface RequireAuthProps {
  children: React.ReactElement
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const isAuthenticated = useSelector((state: RootState) => state?.auth.isAuthenticated)
  const userId = useSelector((state: RootState) => state?.auth.userId)
  const user = useSelector((state: RootState) => state?.auth.user)
  const location = useLocation()
  const { fetchUserCredentials } = useFetchCreds()
  const initialRender = useRef(true)

  const fetchAuth = async () => {
    await fetchUserCredentials(userId || null, user || null)
    initialRender.current = false
  }

  useEffect(() => {
    if (initialRender.current && !isAuthenticated) {
      fetchAuth()
    } else {
      initialRender.current = false
    }
  }, [])

  if (!userId || (!initialRender.current && !isAuthenticated)) {
    return <Navigate to='/' state={{ from: location }} replace />
  }

  return children
}

export default RequireAuth
