import {
  PaletteColor,
  ThemeOptions,
  TypographyVariantsOptions,
  createTheme,
} from '@mui/material/styles'
import { Typography, TypographyOptions } from '@mui/material/styles/createTypography'

declare module '@mui/material/styles' {
  interface CustomPaletteColor extends PaletteColor {
    states: {
      hover: string
      selected: string
      focus: string
      focusVisible: string
      outlinedBorder?: string
    }
  }

  interface Palette {
    primary: CustomPaletteColor
    secondary: CustomPaletteColor
    error: CustomPaletteColor
    warning: CustomPaletteColor
    success: CustomPaletteColor
    text: {
      primary: string
      secondary: string
      disabled: string
      states: {
        hover: string
        selected: string
        focus: string
        focusVisible: string
      }
    }
    action: {
      active: string
      hover: string
      selected: string
      focus: string
      disabled: string
      disabledBackground: string
    }
  }

  interface PaletteOptions {
    primary?: Partial<CustomPaletteColor>
    secondary?: Partial<CustomPaletteColor>
    error?: Partial<CustomPaletteColor>
    warning?: Partial<CustomPaletteColor>
    success?: Partial<CustomPaletteColor>
    text?: Partial<Palette['text']>
    action?: Partial<Palette['action']>
  }

  interface TypographyVariants {
    label: React.CSSProperties
    link: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    label?: React.CSSProperties
    link?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true
    link: true
  }
}

const createCustomTheme = (mode: 'light' | 'dark'): ThemeOptions => {
  const typography: TypographyVariantsOptions = {
    fontFamily: 'avenir-book, sans-serif',
    h1: {
      fontFamily: 'avenir-book, sans-serif',
      fontSize: '28px',
      lineHeight: '125%',
    },
    h2: {
      fontFamily: 'avenir-book, sans-serif',
      fontSize: '24px',
      letterSpacing: '1%',
      lineHeight: '125%',
    },
    h3: {
      fontFamily: 'avenir-book, sans-serif',
      fontSize: '20px',
      letterSpacing: '1%',
      lineHeight: '150%',
    },
    h4: {
      fontFamily: 'avenir-book, sans-serif',
      fontSize: '18px',
      letterSpacing: '1%',
      lineHeight: '150%',
    },
    h5: {
      fontFamily: 'avenir-heavy, sans-serif',
      fontSize: '16px',
      letterSpacing: '2%',
      lineHeight: '24px',
    },
    body1: {
      fontFamily: 'avenir-book, sans-serif',
      fontSize: '16px',
      letterSpacing: '0.25px',
      lineHeight: '24px',
    },
    body2: {
      fontFamily: 'avenir-book, sans-serif',
      fontSize: '14px',
      letterSpacing: '0.25px',
      lineHeight: '24px',
    },
    button: {
      fontFamily: 'avenir-light, sans-serif',
      fontSize: '16px',
      fontWeight: 700,
      letterSpacing: '0.5px',
      lineHeight: '24px',
      textTransform: 'none',
    },
    subtitle1: {
      fontFamily: 'avenir-medium, sans-serif',
      fontSize: '24px',
      letterSpacing: '0.2px',
      lineHeight: '150%',
    },
    subtitle2: {
      fontFamily: 'avenir-medium, sans-serif',
      fontSize: '20px',
      letterSpacing: '0.2px',
      lineHeight: '150%',
    },
    caption: {
      fontFamily: 'avenir-book, sans-serif',
      fontSize: '12px',
      letterSpacing: '0.4px',
    },
    overline: {
      fontFamily: 'avenir-book, sans-serif',
      fontSize: '10px',
      letterSpacing: '1px',
    },
    label: {
      fontFamily: 'avenir-book, sans-serif',
      fontSize: '14px',
      letterSpacing: '0.25px',
      lineHeight: '18px',
      color: mode === 'light' ? '#666666' : '#FFFFFFB3',
    },
    link: {
      fontFamily: 'avenir-heavy, sans-serif',
      fontSize: '16px',
      letterSpacing: '0.25px',
      lineHeight: '24px',
      color: mode === 'light' ? '#0894CF' : '#90CAF9',
    },
  }

  return {
    typography,
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            '& path': {
              strokeWidth: 0.5,
            },
          },
        },
      },
    },
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            primary: {
              main: '#0894CF',
              dark: '#0070A8',
              light: '#0FA1DD',
              contrastText: '#FFFFFF',
              states: {
                hover: '#0894CF0A',
                selected: '#0894CF14',
                focus: '#0894CF1F',
                focusVisible: '#0894CF4D',
                outlinedBorder: '#0894CF80',
              },
            },
            secondary: {
              main: '#000000',
              dark: '#4A4A4A',
              light: '#888888',
              contrastText: '#FFFFFF',
              states: {
                hover: '#00000009',
                selected: '#00000014',
                focus: '#0000001F',
                focusVisible: '#0000004D',
                outlinedBorder: '#00000080',
              },
            },
            error: {
              main: '#ED1C24',
              dark: '#CE0015',
              light: '#FC2B22',
              contrastText: '#FFFFFF',
              states: {
                hover: '#ED1C240A',
                selected: '#ED1C2414',
                focus: '#ED1C241F',
                focusVisible: '#ED1C244D',
                outlinedBorder: '#ED1C2480',
              },
            },
            warning: {
              main: '#FF6E3C',
              dark: '#E6460D',
              light: '#FF8760',
              contrastText: '#FFFFFF',
              states: {
                hover: '#FF6E3C0A',
                selected: '#FF6E3C14',
                focus: '#FF6E3C1F',
                focusVisible: '#FF6E3C4D',
                outlinedBorder: '#FF6E3C80',
              },
            },
            success: {
              main: '#009C6D',
              dark: '#007F53',
              light: '#2AAB80',
              contrastText: '#FFFFFF',
              states: {
                hover: '#009C6D0A',
                selected: '#009C6D14',
                focus: '#009C6D1F',
                focusVisible: '#009C6D4D',
                outlinedBorder: '#009C6D80',
              },
            },
            text: {
              primary: '#000000',
              secondary: '#666666',
              disabled: '#888888',
              states: {
                hover: '#0000000A',
                selected: '#00000014',
                focus: '#0000001F',
                focusVisible: '#0000004D',
              },
            },
            action: {
              active: '#0000008F',
              hover: '#0000000A',
              selected: '#00000014',
              focus: '#0000001F',
              disabled: '#00000061',
              disabledBackground: '#0000001F',
            },
          }
        : {
            primary: {
              main: 'blue',
              dark: 'blue.400',
              light: 'blue.50',
              contrastText: '#000000DE',
              states: {
                hover: '#90CAF914',
                selected: '#90CAF929',
                focus: '#90CAF91F',
                focusVisible: '#90CAF94D',
                outlinedBorder: '#90CAF980',
              },
            },
            secondary: {
              main: 'purple',
              dark: 'purple.400',
              light: 'purple.50',
              contrastText: '#000000DE',
              states: {
                hover: '#CE93D814',
                selected: '#CE93D829',
                focus: '#CE93D81F',
                focusVisible: '#CE93D84D',
                outlinedBorder: '#CE93D880',
              },
            },
            error: {
              main: 'red.500',
              dark: 'red.700',
              light: 'red.300',
              contrastText: '#FFFFFF',
              states: {
                hover: '#F4433614',
                selected: '#F4433629',
                focus: '#F4433621',
                focusVisible: '#F443364D',
                outlinedBorder: '#F4433680',
              },
            },
            warning: {
              main: 'orange.400',
              dark: 'orange.700',
              light: 'orange.300',
              contrastText: '#000000DE',
              states: {
                hover: '#FFA72614',
                selected: '#FFA72629',
                focus: '#FFA72621',
                focusVisible: '#FFA7264D',
                outlinedBorder: '#FFA72680',
              },
            },
            success: {
              main: 'green.400',
              dark: 'green.700',
              light: 'green.300',
              contrastText: '#000000DE',
              states: {
                hover: '#66BB6A14',
                selected: '#66BB6A29',
                focus: '#66BB6A21',
                focusVisible: '#66BB6A4D',
                outlinedBorder: '#66BB6A80',
              },
            },
            text: {
              primary: '#FFFFFF',
              secondary: '#FFFFFFB3', // 70% opacity
              disabled: '#FFFFFF61', // 38% opacity
              states: {
                hover: '#FFFFFF14',
                selected: '#FFFFFF29',
                focus: '#FFFFFF1F',
                focusVisible: '#FFFFFF4D',
              },
            },
            action: {
              active: '#FFFFFF8F',
              hover: '#FFFFFF14',
              selected: '#FFFFFF29',
              focus: '#FFFFFF1F',
              disabled: '#FFFFFF61',
              disabledBackground: '#FFFFFF1F',
            },
          }),
    },
  }
}

export const lightTheme = createTheme(createCustomTheme('light'))
export const darkTheme = createTheme(createCustomTheme('dark'))

// Ensure type safety
declare module '@mui/material/styles' {
  interface Theme {
    typography: Typography
  }
  interface ThemeOptions {
    typography?: TypographyOptions | ((theme: Theme) => TypographyOptions)
  }
}
