import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { User } from '~types'

interface AuthState {
  userId: string | null
  user: User | null
  isAuthenticated: boolean
}

const initialState: AuthState = {
  userId: null,
  user: null,
  isAuthenticated: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      const userObj = action.payload
      state.userId = userObj.id
      state.user = userObj
    },
    setUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload
    },
    setAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload
    },
    setUnauthenticated(state) {
      state.isAuthenticated = false
      state.user = null
    },
    updateUserInfo(state, action: PayloadAction<User>) {
      state.user = action.payload
    },
    loginUser(state, action: PayloadAction<User>) {
      state.userId = action.payload.id
      state.user = action.payload
      state.isAuthenticated = true
    },
    logoutUser(state) {
      state.userId = null
      state.user = null
    },
  },
})

export const {
  setUser,
  setUserId,
  setAuthenticated,
  setUnauthenticated,
  updateUserInfo,
  loginUser,
  logoutUser,
} = authSlice.actions

export default authSlice.reducer
