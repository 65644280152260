const getApiUrl = (): string => {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL
  }

  if (process.env.NODE_ENV === 'test') {
    return 'https://api.polimorphic.com'
  }

  if (typeof window === 'undefined') {
    return 'https://api.polimorphic.com'
  }

  const hostname = window.location.hostname

  if (hostname.includes('localhost') || hostname.includes('127.0.0.1')) {
    return 'http://localhost:8000'
  } else if (hostname.includes('staging.usepoli.com')) {
    return 'https://api-staging.usepoli.com'
  }

  return 'https://api.polimorphic.com'
}

export const API_URL = getApiUrl()
export const IS_LOCAL = API_URL.includes('localhost')
export const IS_STAGING = API_URL.includes('staging')
export const IS_PROD = API_URL.includes('https://api.polimorphic.com')
export const QUILL_PUBLIC_KEY = IS_PROD ? '66cf63a4f0d207000b2f594f' : '66ceb7f2d816f6000b6bf10e'
export const COMMIT_HASH = process.env.REACT_APP_COMMIT_HASH
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_KEY
export const STRIPE_TEST_PUBLIC_KEY = process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY
export const STRIPE_LIVE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY

export const STRIPE_PUBLIC_KEY = IS_PROD ? STRIPE_LIVE_PUBLIC_KEY : STRIPE_TEST_PUBLIC_KEY

export const BASE_URL_WITH_REACT = IS_LOCAL
  ? 'http://localhost:3000/react'
  : IS_STAGING
    ? 'https://staging.usepoli.com/react'
    : 'https://app.polimorphic.com'

export const BASE_URL_WITHOUT_REACT = IS_LOCAL
  ? 'https://staging.usepoli.com'
  : IS_STAGING
    ? 'https://staging.usepoli.com'
    : 'https://app.polimorphic.com'

export const BASE_URL = IS_LOCAL
  ? ''
  : IS_STAGING
    ? 'https://staging.usepoli.com/react'
    : 'https://app.polimorphic.com'
