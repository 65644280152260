import React, { Suspense, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Box } from '@mui/material'

import { apiZodios } from '~api/apiZodios'
import { IS_LOCAL, IS_STAGING } from '~config'
import { setUser } from '~redux/slices/authSlice'
import { setEmailIdentities, setSelectedEIOrganization } from '~redux/slices/organizationSlice'

import './components/styles/global.less'
import { RootState } from './redux/rootReducer'
import { AppDispatch, persistor } from './redux/store'
import RequireAuth from './RequireAuth'

const AccountSettingsScreen = React.lazy(
  () => import('./pages/AccountSettingsScreen/AccountSettingsScreen'),
)
const AISettingsScreen = React.lazy(() => import('./pages/AISettingsScreen/AISettingsScreen'))
const QueryCustomizePage = React.lazy(
  () => import('./pages/AISettingsScreen/pages/QueryCustomizePage'),
)
const QueryLogPage = React.lazy(() => import('./pages/AISettingsScreen/pages/QueryLogPage'))
const QueryPublishPage = React.lazy(() => import('./pages/AISettingsScreen/pages/QueryPublishPage'))
const QuerySourcesPage = React.lazy(() => import('./pages/AISettingsScreen/pages/QuerySourcesPage'))
const AnalyticsScreen = React.lazy(() => import('./pages/AnalyticsScreen/AnalyticsScreen'))
const CasesScreen = React.lazy(() => import('./pages/CasesScreen/CasesScreen'))
const CaseFeed = React.lazy(() => import('./pages/CasesScreen/pages/CaseFeed'))
const CreateCaseScreen = React.lazy(() => import('./pages/CasesScreen/pages/CreateCaseScreen'))
const CaseApplyPage = React.lazy(() => import('./pages/ConstituentScreens/CaseApplyPage'))
const ConstituentCasesPage = React.lazy(
  () => import('./pages/ConstituentScreens/ConstituentCasesPage'),
)
const ContactPage = React.lazy(() => import('./pages/ContactsScreen/ContactPage'))
const ContactsScreen = React.lazy(() => import('./pages/ContactsScreen/ContactsScreen'))
const MassCommunicationScreen = React.lazy(
  () => import('./pages/MassCommunicationScreen/MassCommunicationScreen'),
)
const MenuScreen = React.lazy(() => import('./pages/MenuScreen/MenuScreen'))
const FormLetterPage = React.lazy(() => import('./pages/MenuScreen/pages/FormLetterPage'))
const FormLettersPage = React.lazy(() => import('./pages/MenuScreen/pages/FormLettersPage'))
const OrganizationInviteScreen = React.lazy(
  () => import('./pages/OrganizationSettings/OrganizationInviteScreen'),
)
const OrganizationSettingsScreen = React.lazy(
  () => import('./pages/OrganizationSettings/OrganizationSettingsScreen'),
)
const OutlookScreen = React.lazy(() => import('./pages/OutlookScreen/OutlookScreen'))
const PaymentLookupPage = React.lazy(() => import('./pages/PaymentsScreen/pages/PaymentLookupPage'))
const ReceiptPage = React.lazy(() => import('./pages/PaymentsScreen/pages/ReceiptPage'))
const TakePaymentScreen = React.lazy(() => import('./pages/PaymentsScreen/pages/TakePaymentScreen'))
const PaymentsScreen = React.lazy(() => import('./pages/PaymentsScreen/PaymentsScreen'))
const ProcessEditPage = React.lazy(() => import('./pages/ProcessesScreen/pages/ProcessEditPage'))
const ProcessPage = React.lazy(() => import('./pages/ProcessesScreen/pages/ProcessPage'))
const ProcessApplyPage = React.lazy(() => import('./pages/ProcessesScreen/ProccessApplyScreen'))
const ProcessesScreen = React.lazy(() => import('./pages/ProcessesScreen/ProcessesScreen'))
const CreateProcessScreen = React.lazy(
  () => import('./pages/CreateProcessScreen/CreateProcessScreen'),
)
const ResetPasswordScreen = React.lazy(() => import('./pages/ResetPasswordScreen'))
const SuperUserDashboard = React.lazy(() => import('./pages/SuperScreen/SuperScreen'))
const ChatEmbedScreen = React.lazy(
  () => import('./pages/AISettingsScreen/components/ChatEmbedScreen'),
)
const QueryEmbedScreen = React.lazy(
  () => import('./pages/AISettingsScreen/components/QueryEmbedScreen'),
)
const Root = React.lazy(() => import('./pages/Root'))

const HealthCheck = () => {
  return <div>OK</div>
}

const NotFound = () => {
  return <div>404 - Page Not Found</div>
}

const allowListPages = ['/form-letters', '/account', '/organizations', '/settings']

const routeTitles: { [key: string]: string } = {
  '/': 'Home',
  '/cases': 'Cases',
  '/create-case': 'Create Case',
  '/contacts': 'Contacts',
  '/processes': 'Processes',
  '/bills': 'Payments',
  '/collect-payment': 'Collect Payment',
  '/analytics': 'Analytics',
  '/menu': 'Menu',
  '/process-apply': 'Process Apply',
  '/account': 'Account Settings',
  '/mass-communication': 'Mass Communication',
  '/form-letters': 'Form Letters',
  '/settings': 'Settings',
}

const TitleUpdater = () => {
  const location = useLocation()

  useEffect(() => {
    const baseRoute = '/' + location.pathname.split('/')[1]
    const title = routeTitles[baseRoute] || routeTitles[location.pathname] || 'Polimorphic'
    document.title = `${title} | Polimorphic`
  }, [location])

  return null
}

const PathChangeReloader = () => {
  const location = useLocation()
  const previousPath = useRef(location.pathname)
  const isAllowListed =
    allowListPages.some((prefix) => location.pathname.startsWith(prefix)) || IS_LOCAL || IS_STAGING

  useEffect(() => {
    if (!isAllowListed && previousPath.current !== location.pathname) {
      window.location.reload()
    }
    previousPath.current = location.pathname
  }, [location.pathname])

  return null
}

const EmbedLoading = () => (
  <div className='flex items-center justify-center w-full h-full'>
    <div className='w-8 h-8 border-4 border-blue-500 rounded-full animate-spin border-t-transparent'></div>
  </div>
)

const App = () => {
  const userId = useSelector((state: RootState) => state?.auth.userId)
  const emailIdentities = useSelector((state: RootState) => state?.organization.emailIdentities)
  const orgRefreshTrigger = useSelector((state: RootState) => state?.organization.refreshTrigger)
  const selectedOrganizationHandle = useSelector(
    (state: RootState) => state?.organization.selectedOrganization?.handle,
  )
  const [appInitialized, setAppInitialized] = useState(false)
  const dispatch: AppDispatch = useDispatch()

  const getUser = async () => {
    if (!userId) {
      return
    }
    const user = await apiZodios.core_getUser({ user_id: userId })
    dispatch(setUser(user))
  }

  const getEmailIdentities = async () => {
    if (!userId) {
      return
    }
    try {
      const response = await apiZodios.core_listDistinctEmailIdentities({
        user_id: userId,
        u_first: false,
      })
      const identities = response.data
      dispatch(setEmailIdentities(identities))
      if (
        identities?.length > 0 &&
        !identities.find((i) => i.handle === selectedOrganizationHandle)
      ) {
        dispatch(setSelectedEIOrganization(identities[0]))
      }
    } catch (error: unknown) {
      console.error('Error fetching email identities', error)
    }
  }

  useEffect(() => {
    const bootstrapped = persistor.getState().bootstrapped
    if (bootstrapped) {
      setAppInitialized(true)
      return
    }
    const unsubscribe = persistor.subscribe(() => {
      const { bootstrapped } = persistor.getState()
      if (bootstrapped) {
        setAppInitialized(true)
        unsubscribe()
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (!emailIdentities || !selectedOrganizationHandle) {
      getEmailIdentities()
    }
    getUser()
  }, [userId])

  useEffect(() => {
    if (appInitialized) {
      getEmailIdentities()
    }
  }, [orgRefreshTrigger])

  if (!appInitialized) {
    return <div key={'root-routes-key'} />
  }

  return (
    <Box>
      <Routes>
        <Route
          path='/'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <Root />
            </Suspense>
          }
        />
        <Route
          path='/cases'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <CasesScreen />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/create-case*'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <CreateCaseScreen />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/cases/:caseHandle'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <CaseFeed />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/contacts'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <ContactsScreen />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/processes'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <ProcessesScreen />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/create-process'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <CreateProcessScreen />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/bills'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <PaymentsScreen />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/collect-payment'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <TakePaymentScreen />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/payments/:organizationHandle/lookups/:paymentCode'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <PaymentLookupPage />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/analytics'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <AnalyticsScreen />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/menu'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <MenuScreen />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/process-apply'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <ConstituentCasesPage />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/organizations/:organizationHandle'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <AISettingsScreen />
              </RequireAuth>
            </Suspense>
          }
        >
          <Route
            path='sources'
            element={
              <Suspense fallback={<EmbedLoading />}>
                <QuerySourcesPage />
              </Suspense>
            }
          />
          <Route
            path='query-customize'
            element={
              <Suspense fallback={<EmbedLoading />}>
                <QueryCustomizePage />
              </Suspense>
            }
          />
          <Route
            path='query-publish'
            element={
              <Suspense fallback={<EmbedLoading />}>
                <QueryPublishPage />
              </Suspense>
            }
          />
          <Route
            path='query-log'
            element={
              <Suspense fallback={<EmbedLoading />}>
                <QueryLogPage />
              </Suspense>
            }
          />
          <Route index element={<Navigate to='sources' replace />} />
        </Route>
        {selectedOrganizationHandle && (
          <Route
            path='/'
            element={
              <Navigate to={`/organizations/${selectedOrganizationHandle}/sources`} replace />
            }
          />
        )}
        <Route
          path='/organizations/:organizationHandle/settings/:settingType'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <OrganizationSettingsScreen />
            </Suspense>
          }
        />
        <Route
          path='/settings/:settingType'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <OrganizationSettingsScreen />
            </Suspense>
          }
        />
        <Route
          path='/organizations/:organizationHandle/contacts/:contactHandle'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <ContactPage />
            </Suspense>
          }
        />
        <Route
          path='/organizations/:organizationHandle/blueprints/:processCode/apply'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <ProcessApplyPage />
            </Suspense>
          }
        />
        <Route
          path='/organizations/:organizationHandle/blueprints/:processCode/history'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <ProcessPage />
            </Suspense>
          }
        />
        <Route
          path='/organizations/:organizationHandle/blueprints/:processCode/dashboard'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <ProcessPage />
            </Suspense>
          }
        />
        <Route
          path='/organizations/:organizationHandle/blueprints/:processCode/edit'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <ProcessEditPage />
            </Suspense>
          }
        />
        <Route
          path='/organizations/:organizationHandle/bills/:billCode/payments/:paymentCode/receipt'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <ReceiptPage />
            </Suspense>
          }
        />
        <Route
          path='/organizations/:organizationHandle/invite'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <OrganizationInviteScreen />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/organizations/:organizationHandle/chat'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <ChatEmbedScreen />
            </Suspense>
          }
        />
        <Route
          path='/organizations/:organizationHandle/query'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <QueryEmbedScreen />
            </Suspense>
          }
        />
        <Route
          path='/outlook-add-in'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <OutlookScreen />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/account'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <AccountSettingsScreen />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/mass-communication'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <MassCommunicationScreen />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/form-letters'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <FormLettersPage />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/form-letters/:organizationHandle/:templateCode'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <FormLetterPage />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/cases/:caseHandle/apply'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <CaseApplyPage />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route path='/health' element={<HealthCheck />} />
        <Route
          path='/super'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <RequireAuth>
                <SuperUserDashboard />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path='/reset-password/:userId/:resetCode'
          element={
            <Suspense fallback={<EmbedLoading />}>
              <ResetPasswordScreen />
            </Suspense>
          }
        />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Box>
  )
}

const AppWrapper = () => {
  return (
    <BrowserRouter basename='/react'>
      <TitleUpdater />
      <PathChangeReloader />
      <App />
    </BrowserRouter>
  )
}

export default AppWrapper
