import { Zodios, ZodiosOptions } from '@zodios/core'
import { AxiosInstance, InternalAxiosRequestConfig } from 'axios'

import { API_URL } from '~config'
import { createApiClient as baseCreateApiClient, schemas } from '~interface/zod-schema'
import { store } from '~redux/store'

import { csrfToken, sessionId, sessionToken } from './cookies'

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  const baseApi = baseCreateApiClient(baseUrl, options)

  // Create a new Zodios instance with additional configuration
  const api = new Zodios(baseUrl, baseApi.api, {
    ...options,
    axiosConfig: {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  })

  // Apply custom interceptor to the underlying Axios instance
  ;(api.axios as AxiosInstance).interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const userId = store.getState().auth.userId

      if (csrfToken) {
        config.headers.set('X-CSRFToken', csrfToken)
      }
      if (sessionId) {
        config.headers.set('X-Session-ID', sessionId)
      }
      if (sessionToken) {
        config.headers.set('X-Session-Token', sessionToken)
      }

      if (config.data instanceof FormData) {
        if (userId) {
          config.data.append('user_id', userId)
        }
      } else if (typeof config.data === 'object' && config.data !== null && userId) {
        config.data = {
          ...config.data,
          user_id: userId,
        }
      }

      return config
    },
    (error) => Promise.reject(error),
  )

  return api
}

// Create and export the API instance
export const apiZodios = createApiClient(API_URL || '')

// Re-export schemas
export { schemas }

// Define error handling
export function isErrorResponse(error: unknown): error is Error {
  return error instanceof Error
}
