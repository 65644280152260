import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { EmailIdentity } from '~types'

interface OrganizationState {
  selectedOrganization: EmailIdentity | null
  emailIdentities: EmailIdentity[]
  refreshTrigger: number
}

const initialState: OrganizationState = {
  selectedOrganization: null,
  emailIdentities: [],
  refreshTrigger: 0,
}

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setSelectedEIOrganization(state, action: PayloadAction<EmailIdentity>) {
      state.selectedOrganization = action.payload
    },
    setEmailIdentities(state, action: PayloadAction<EmailIdentity[]>) {
      state.emailIdentities = action.payload
    },
    resetOrganizationState(state) {
      state.selectedOrganization = null
      state.emailIdentities = []
      state.refreshTrigger = 0
    },
    refreshEmailIdentities(state) {
      state.refreshTrigger += 1
    },
  },
})

export const {
  setSelectedEIOrganization,
  setEmailIdentities,
  resetOrganizationState,
  refreshEmailIdentities,
} = organizationSlice.actions

export default organizationSlice.reducer
