import { makeApi, Zodios, type ZodiosOptions } from '@zodios/core'
import { z } from 'zod'

const EndUserIdField = z.string()
const OrganizationIdField = z.string()
const IdentityIdField = z.string()
const CrawlDomainViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization: OrganizationIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    domain_crawl: z.string().min(1),
    non_xml_sitemap: z.string().optional(),
  })
  .passthrough()
const EmptyResponseSerializerEmptyResponse = z
  .object({ status: z.string().default('ok') })
  .partial()
  .passthrough()
const Type495Enum = z.enum(['ChatSearch', 'ChatChat', 'ChatVoice'])
const CreateChatViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_id: OrganizationIdField.uuid(),
    type: Type495Enum,
  })
  .passthrough()
const ChatIdField = z.string()
const CreateChatViewResponseData = z.object({ chat_id: ChatIdField.uuid() }).passthrough()
const ChatTypeEnum = z.enum(['ChatSearch', 'ChatChat', 'ChatVoice'])
const NullEnum = z.unknown()
const ChatFilterRequest = z
  .object({
    chat_ids: z.array(z.string().uuid()).nullable(),
    organization_ids: z.array(z.string().uuid()).nullable(),
    user_ids: z.array(z.string().uuid()).nullable(),
    created_after: z.string().datetime({ offset: true }).nullable(),
    created_before: z.string().datetime({ offset: true }).nullable(),
    case_ids: z.array(z.string().uuid()).nullable(),
    query: z.string().nullable(),
    chat_type: z.union([ChatTypeEnum, NullEnum]).nullable(),
    impression: z.boolean().nullable(),
  })
  .partial()
  .passthrough()
const DownloadChatsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), filter: ChatFilterRequest })
  .passthrough()
const DownloadChatsViewResponseData = z
  .object({ email_recipient: z.string().email() })
  .passthrough()
const EditOrganizationAiSettingsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    organization_id: OrganizationIdField.uuid(),
    bg_color: z.string().nullish(),
    font_color: z.string().nullish(),
    name: z.string().nullish(),
    search_url: z.string().nullish(),
    banner: z.string().nullish(),
    phone: z.string().nullish(),
  })
  .passthrough()
const Organization = z
  .object({
    id: OrganizationIdField.uuid(),
    identity: IdentityIdField.uuid(),
    parent: OrganizationIdField.uuid(),
    name: z.string().nullable(),
    image: z.string().nullable(),
    information: z.string(),
    banner: z.string().nullish(),
    font_color: z.string(),
    bg_color: z.string(),
    search_url: z.string().nullish(),
    phone_number: z.string().nullable(),
  })
  .passthrough()
const EditOrganizationAiSettingsViewResponseData = z
  .object({ organization: Organization })
  .passthrough()
const ContextEnum = z.enum(['ChatSearch', 'ChatChat', 'ChatVoice'])
const GenerateResponseViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid().optional(),
    organization_id: OrganizationIdField.uuid(),
    chat_id: ChatIdField.uuid().optional(),
    context: ContextEnum,
    query: z.string().optional(),
    raw_text: z.boolean().optional().default(false),
  })
  .passthrough()
const QueryAnalysis = z
  .object({
    is_context_dependent: z.boolean(),
    rewritten_query: z.string(),
    relevant_context_points: z.array(z.string()),
    confidence_score: z.number(),
  })
  .passthrough()
const ResponseIdField = z.string()
const ChatDisplaySource = z
  .object({ url: z.string(), title: z.string(), text: z.string(), score: z.number() })
  .passthrough()
const GenerateResponseViewResponseData = z
  .object({
    user_id: EndUserIdField.uuid().optional(),
    chat_id: ChatIdField.uuid(),
    query_analysis: QueryAnalysis.optional(),
    response_id: ResponseIdField.uuid(),
    response_text: z.string(),
    sources: z.array(ChatDisplaySource),
  })
  .passthrough()
const GetChatCountViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), filter: ChatFilterRequest })
  .passthrough()
const GetChatCountViewResponseData = z.object({ count: z.number().int() }).passthrough()
const GetOrganizationAiSettingsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_id: OrganizationIdField.uuid(),
    organization_handle: z.string().nullable(),
  })
  .partial()
  .passthrough()
const OrganizationAISettings0 = z
  .object({
    organization: Organization,
    chat_name: z.string().nullish(),
    chat_icon: z.string().nullish(),
    chat_greeting: z.string().nullish(),
  })
  .passthrough()
const GetOrganizationAiSettingsViewResponseData = z
  .object({ organization: OrganizationAISettings0 })
  .passthrough()
const GetPageCountViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid().optional(),
    organization_identity_id: IdentityIdField.uuid(),
    organization: OrganizationIdField.uuid().optional(),
    query: z.string().min(1).optional(),
    source_type: z.string().min(1).optional(),
    reducto_job: z.string().min(1).optional(),
  })
  .passthrough()
const GetPageCountViewResponseData = z.object({ data: z.number().int() }).passthrough()
const ListChatsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    filter: ChatFilterRequest,
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    sort_ascending: z.boolean().optional(),
  })
  .passthrough()
const CaseIdField = z.string()
const BlankEnum = z.unknown()
const Chat = z
  .object({
    id: ChatIdField.uuid(),
    organization: OrganizationIdField.uuid(),
    case: CaseIdField.nullable(),
    created: z.string().datetime({ offset: true }).optional(),
    type: z.union([Type495Enum, BlankEnum, NullEnum]).nullish(),
    call_sid: z.string().nullish(),
    transfer_call_sid: z.string().nullish(),
    user: z.string().uuid(),
  })
  .passthrough()
const Query = z
  .object({
    id: z.string().uuid().optional(),
    metadata: z.unknown().nullish(),
    body: z.string(),
    language: z.string().max(10).nullish(),
    english_text: z.string().nullish(),
    created: z.string().datetime({ offset: true }).optional(),
    chat: z.string().uuid(),
  })
  .passthrough()
const Response = z
  .object({
    id: z.string().uuid().optional(),
    metadata: z.unknown().nullish(),
    body: z.string(),
    language: z.string().max(10).nullish(),
    english_text: z.string().nullish(),
    created: z.string().datetime({ offset: true }).optional(),
    impression: z.boolean().nullish(),
    chat: z.string().uuid(),
  })
  .passthrough()
const ScrapeStatusEnum = z.enum(['pending', 'in_progress', 'finished', 'failed'])
const Page = z
  .object({
    id: z.string().uuid().optional(),
    url: z.string(),
    title: z.string(),
    scrape_status: ScrapeStatusEnum.optional(),
    scraped: z.string().datetime({ offset: true }).nullish(),
    reducto_job: z.string().nullish(),
    cadence: z.string().nullish(),
    next_scrape: z.string().datetime({ offset: true }).nullish(),
    archived: z.boolean().optional(),
    flagged: z.boolean().optional(),
    organization: z.string().uuid(),
    crawl_task: z.string().uuid().nullish(),
  })
  .passthrough()
const Chunk = z
  .object({
    id: z.string().uuid().optional(),
    page: Page,
    text: z.string(),
    tokens: z.number().int().gte(-9223372036854776000).lte(9223372036854776000),
  })
  .passthrough()
const Response1 = z.object({ response: Response, sources: z.array(Chunk) }).passthrough()
const Chat2 = z
  .object({ chat: Chat, queries: z.array(Query), responses: z.array(Response1) })
  .passthrough()
const ListChatsViewResponseData = z.object({ chats: z.array(Chat2) }).passthrough()
const ListPagesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    pgla: z.object({}).partial().passthrough(),
  })
  .passthrough()
const ListPagesViewResponseData = z
  .object({ data: z.array(z.object({}).partial().passthrough()) })
  .passthrough()
const ListSourcesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid().optional(),
    organization_id: OrganizationIdField.uuid(),
    query: z.string().min(1),
    offset: z.number().int().optional(),
    limit: z.number().int().optional(),
  })
  .passthrough()
const ListSourcesViewResponseData = z.object({ sources: z.array(ChatDisplaySource) }).passthrough()
const ListSuggestionsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid().optional(),
    organization_id: OrganizationIdField.uuid(),
  })
  .passthrough()
const SuggestionIdField = z.string()
const Suggestion = z
  .object({
    id: SuggestionIdField.uuid(),
    organization: OrganizationIdField.uuid(),
    text: z.string(),
    order: z.number().int().gte(-9223372036854776000).lte(9223372036854776000),
  })
  .passthrough()
const ListSuggestionsViewResponseData = z.object({ suggestions: z.array(Suggestion) }).passthrough()
const SetImpressionViewRequestDataRequest = z
  .object({ response_id: ResponseIdField.uuid(), impression: z.boolean().nullable() })
  .passthrough()
const SetSuggestionsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_id: OrganizationIdField.uuid(),
    suggestions: z.array(z.string().nullable()).max(4),
  })
  .passthrough()
const SetSuggestionsViewResponseData = z.object({ suggestions: z.array(Suggestion) }).passthrough()
const TranslateTextViewRequestDataRequest = z
  .object({
    text: z.string().min(1),
    source_language: z.string().min(1),
    target_language: z.string().min(1),
  })
  .passthrough()
const TranslateTextViewResponseData = z.object({ translated_text: z.string() }).passthrough()
const ItemIdField = z.string()
const AddCaseItemViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    case_id: CaseIdField.uuid(),
    item_id: ItemIdField.uuid(),
  })
  .passthrough()
const AddCaseMemberViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    case_id: CaseIdField.uuid(),
    member_id: IdentityIdField.uuid(),
  })
  .passthrough()
const LogIdField = z.string()
const AddCityworksCommentViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), log_id: LogIdField.uuid() })
  .passthrough()
const ProcessIdField = z.string()
const AttachProcessViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    process_id: ProcessIdField.uuid(),
    case_id: CaseIdField.uuid(),
  })
  .passthrough()
const NotificationIdField = z.string()
const BumpNotificationViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), notification_id: NotificationIdField.uuid() })
  .passthrough()
const CopyProcessViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    new_owner_id: IdentityIdField.uuid(),
    old_process_id: ProcessIdField.uuid(),
    name: z.string().min(1),
    description: z.string().min(1),
    keep_assignees: z.boolean(),
  })
  .passthrough()
const CopyProcessViewResponseData = z.object({ process_id: ProcessIdField.uuid() }).passthrough()
const SectionStepIdField = z.string()
const FormStepIdField = z.string()
const CopySectionSubStepViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    section_step_id: SectionStepIdField.uuid(),
    section_id: SectionStepIdField.uuid().optional(),
    form_step_id: FormStepIdField.uuid().optional(),
    order: z.number().int(),
  })
  .passthrough()
const CopySectionSubStepViewResponseData = z
  .object({ section_step_id: SectionStepIdField.uuid() })
  .passthrough()
const ExpressionIdField = z.string()
const ChargeSubStepCreateRequest = z
  .object({
    code: z.string(),
    name: z.string(),
    description: z.string(),
    dollars: ExpressionIdField.uuid(),
  })
  .passthrough()
const StatusesEnum = z.enum(['Unsubmitted', 'Open', 'Closed', 'Deleted', 'Hidden'])
const CreateBillStepViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    process_id: ProcessIdField.uuid(),
    code: z.string().min(1),
    name: z.string().min(1),
    description: z.string().min(1),
    order: z.number().int(),
    charges: z.array(ChargeSubStepCreateRequest),
    set_status: StatusesEnum.optional(),
    allow_ach: z.boolean(),
  })
  .passthrough()
const BillStepIdField = z.string()
const CreateBillStepViewResponseData = z
  .object({ bill_step_id: BillStepIdField.uuid() })
  .passthrough()
const CreateCaseViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    name: z.string(),
    status: StatusesEnum,
    organization_identity_id: IdentityIdField.uuid(),
  })
  .passthrough()
const CreateCaseViewResponseData = z
  .object({ case_id: CaseIdField.uuid(), handle: z.string().nullable() })
  .passthrough()
const CreateCasesTabViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    code: z.string().min(1),
    name: z.string().min(1),
    order: z.number().int(),
  })
  .passthrough()
const CasesTabIdField = z.string()
const CreateCasesTabViewResponseData = z.object({ tab_id: CasesTabIdField.uuid() }).passthrough()
const CreateCityworksSRViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), case_id: CaseIdField.uuid() })
  .passthrough()
const CreateDefaultCasesTabsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid() })
  .passthrough()
const CreateExpressionViewRequestDataTypeEnum = z.enum([
  'case_creator',
  'constant',
  'input_value',
  'operator',
])
const ValueIdField = z.string()
const InputStepIdField = z.string()
const CreateExpressionViewRequestDataOperatorEnum = z.enum([
  'addition',
  'and',
  'conditional',
  'equals',
  'greater_than',
  'less_than',
  'maximum',
  'minimum',
  'multiplication',
  'or',
  'subtraction',
])
const CreateExpressionViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    type: CreateExpressionViewRequestDataTypeEnum,
    process_id: ProcessIdField.uuid().optional(),
    value_id: ValueIdField.uuid().optional(),
    input_step_id: InputStepIdField.uuid().optional(),
    operator: CreateExpressionViewRequestDataOperatorEnum.optional(),
    parameter_one: ExpressionIdField.uuid().optional(),
    parameter_two: ExpressionIdField.uuid().optional(),
    parameter_three: ExpressionIdField.uuid().optional(),
  })
  .passthrough()
const CreateExpressionViewResponseData = z.object({ data: ExpressionIdField.uuid() }).passthrough()
const CreateFormStepViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    process_id: ProcessIdField.uuid(),
    code: z.string().min(1),
    name: z.string().min(1),
    description: z.string().min(1),
    order: z.number().int(),
    external: z.boolean(),
    due: z.string().optional(),
    sla: z.string().optional(),
    set_status: StatusesEnum.optional(),
  })
  .passthrough()
const FormIdField = z.string()
const CreateFormStepViewResponseData = z.object({ form_step_id: FormIdField.uuid() }).passthrough()
const LocationIdField = z.string()
const ValueCreateRequest = z
  .object({
    bool_value: z.boolean().nullable(),
    datetime_value: z.string().datetime({ offset: true }).nullable(),
    file_value: z.string().nullable(),
    identity_value: IdentityIdField.nullable(),
    location_value: LocationIdField.nullable(),
    number_value: z.string().nullable(),
    text_value: z.string().nullable(),
  })
  .partial()
  .passthrough()
const Type12eEnum = z.enum(['bool', 'datetime', 'file', 'identity', 'location', 'number', 'text'])
const InputIdField = z.string()
const InputCreateRequest = z
  .object({
    value: ValueCreateRequest.nullish(),
    form_id: FormIdField.uuid(),
    step: InputStepIdField.uuid(),
    type: Type12eEnum,
    input_id: InputIdField.nullish(),
  })
  .passthrough()
const CreateInputViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), input_create: InputCreateRequest })
  .passthrough()
const CreateInputViewResponseData = z.object({ input_id: InputIdField.nullable() }).passthrough()
const ValueTypeEnum = z.enum(['Bool', 'Datetime', 'File', 'Identity', 'Location', 'Number', 'Text'])
const StyleBadEnum = z.enum([
  'RawInputStyle',
  'DropdownInputStyle',
  'RadioInputStyle',
  'LongTextInputStyle',
])
const CreateInputStepViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    process_id: ProcessIdField.uuid(),
    name: z.string().min(1),
    description: z.string().min(1),
    value_type: ValueTypeEnum,
    order: z.number().int(),
    style: StyleBadEnum,
    choices: z.array(ValueIdField),
    section: SectionStepIdField.uuid().optional(),
    form: FormStepIdField.uuid(),
    template: ValueIdField.uuid().optional(),
    required: z.boolean(),
    pinned: z.boolean(),
  })
  .passthrough()
const CreateInputStepViewResponseData = z
  .object({ input_step_id: InputStepIdField.uuid() })
  .passthrough()
const MediumEnum = z.enum(['ViaSite', 'ViaPhone', 'ViaEmail', 'ViaInPerson'])
const AttachmentRequest = z.object({ name: z.string(), url: z.string() }).passthrough()
const CreateMessageViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    author_identity_id: IdentityIdField.uuid(),
    recipients: z.array(IdentityIdField),
    case_id: CaseIdField.uuid(),
    medium: MediumEnum,
    body: z.string().min(1),
    attachments: z.array(AttachmentRequest),
  })
  .passthrough()
const MessageIdField = z.string()
const CreateMessageViewResponseData = z
  .object({ message_id: MessageIdField.uuid(), item_id: ItemIdField.uuid() })
  .passthrough()
const Status2efEnum = z.enum(['draft', 'live', 'archived'])
const CreateProcessViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    name: z.string().min(1),
    description: z.string().min(1),
    status: Status2efEnum,
    appliable: z.boolean(),
    copyable: z.boolean(),
  })
  .passthrough()
const CreateProcessViewResponseData = z.object({ process_id: ProcessIdField.uuid() }).passthrough()
const CreateRelationViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    from_case_id: CaseIdField.uuid(),
    to_case_id: CaseIdField.uuid(),
  })
  .passthrough()
const CreateSectionStepViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    process_id: ProcessIdField.uuid(),
    name: z.string().min(1),
    description: z.string().min(1),
    order: z.number().int(),
    section: SectionStepIdField.uuid().optional(),
    form: FormStepIdField.uuid(),
    required: z.boolean(),
    button_name: z.string().min(1).optional(),
  })
  .passthrough()
const CreateSectionStepViewResponseData = z
  .object({ section_step_id: SectionStepIdField.uuid() })
  .passthrough()
const SectionSubstep0Request = z
  .object({
    id: z.string().uuid(),
    process: z.string().uuid(),
    name: z.string(),
    description: z.string(),
    order: z.number().int(),
    form: z.string().uuid(),
    section: z.string().uuid().nullable(),
    button_name: z.string().nullable(),
    added_at: z.string().datetime({ offset: true }).nullable(),
    removed_at: z.string().datetime({ offset: true }).nullable(),
    multiple: z.boolean(),
    min: z.number().int(),
    max: z.number().int(),
    render: z.string().uuid().nullable(),
  })
  .passthrough()
const CreateSectionsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    form_id: FormIdField.uuid(),
    steps: z.array(SectionSubstep0Request),
  })
  .passthrough()
const SectionIdField = z.string()
const CreateSectionsViewResponseData = z.object({ sections: z.array(SectionIdField) }).passthrough()
const CreateTaskViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    case_id: CaseIdField.uuid(),
    assignees: z.array(IdentityIdField),
    name: z.string().min(1),
    description: z.string(),
    due: z.string().datetime({ offset: true }).optional(),
  })
  .passthrough()
const TaskIdField = z.string()
const CreateTaskViewResponseData = z.object({ task_id: TaskIdField.uuid() }).passthrough()
const CreateTestCaseViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    name: z.string().min(1),
    owner: IdentityIdField.uuid(),
    process_id: ProcessIdField.uuid(),
  })
  .passthrough()
const CreateTestCaseViewResponseData = z
  .object({ case_id: CaseIdField.uuid(), handle: z.string() })
  .passthrough()
const DeleteBillStepViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), bill_step_id: BillStepIdField.uuid() })
  .passthrough()
const DeleteCaseViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    case_ids: z.array(CaseIdField).optional(),
    process_id: ProcessIdField.uuid().optional(),
  })
  .passthrough()
const DeleteCasesTabViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), tab_id: CasesTabIdField.uuid() })
  .passthrough()
const DeleteDraftsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    case_id: CaseIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
  })
  .passthrough()
const DeleteExpressionsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), expression_ids: z.array(ExpressionIdField) })
  .passthrough()
const DeleteFormStepViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), form_step_id: FormStepIdField.uuid() })
  .passthrough()
const DeleteInputSubstepViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), input_step_id: InputStepIdField.uuid() })
  .passthrough()
const DeleteSectionViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), section_id: SectionIdField.uuid() })
  .passthrough()
const DeleteSectionSubstepViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), section_substep_id: SectionStepIdField.uuid() })
  .passthrough()
const MessageTemplateIdField = z.string()
const DeleteTemplateViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), template_id: MessageTemplateIdField.uuid() })
  .passthrough()
const ProcessStatusesEnum = z.enum(['draft', 'live', 'archived'])
const CaseFilterRequest = z
  .object({
    assignees: z.array(z.string().uuid()).nullable(),
    no_manager: z.boolean().nullable(),
    item_assignee_ids: z.array(z.string().uuid()).nullable(),
    owner_ids: z.array(z.string().uuid()).nullable(),
    requester_ids: z.array(z.string().uuid()).nullable(),
    statuses: z.array(StatusesEnum).nullable(),
    case_ids: z.array(z.string().uuid()).nullable(),
    tag_ids: z.array(z.string().uuid()).nullable(),
    process_ids: z.array(z.string().uuid()).nullable(),
    process_statuses: z.array(ProcessStatusesEnum).nullable(),
    created_after: z.string().datetime({ offset: true }).nullable(),
    created_before: z.string().datetime({ offset: true }).nullable(),
    next_due_after: z.string().datetime({ offset: true }).nullable(),
    next_due_before: z.string().datetime({ offset: true }).nullable(),
    process_step_ids: z.array(z.string().uuid().nullable()).nullable(),
    input_step_assignees: z.record(z.string().uuid()),
    input_choices: z.record(z.string().min(1)),
    input_text: z.record(z.string().min(1)),
  })
  .partial()
  .passthrough()
const DownloadCasesViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), filter: CaseFilterRequest })
  .passthrough()
const DownloadCasesViewResponseData = z
  .object({ email_recipient: z.string().email() })
  .passthrough()
const EditBillStepViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    bill_step_id: BillStepIdField.uuid(),
    name: z.string().min(1),
    description: z.string().min(1),
    order: z.number().int(),
    charges: z.array(ChargeSubStepCreateRequest),
    account: z.string().min(1).nullish(),
    distribution: z.string().min(1).nullish(),
  })
  .passthrough()
const EditCaseViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    case_id: CaseIdField.uuid(),
    owner: IdentityIdField.uuid().optional(),
    assignee: IdentityIdField.nullish(),
    requester: IdentityIdField.nullish(),
    name: z.string().min(1).optional(),
  })
  .passthrough()
const EditCasesTabViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    tab_id: CasesTabIdField.uuid(),
    code: z.string().min(1).optional(),
    name: z.string().min(1).optional(),
    order: z.number().int().optional(),
  })
  .passthrough()
const DatetimeStepIdField = z.string()
const EditDateTimeStepViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    datetime_step_id: DatetimeStepIdField.uuid(),
    request_time: z.boolean(),
  })
  .passthrough()
const EditDueDateViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    item_ids: z.array(ItemIdField),
    due_date: z.string().datetime({ offset: true }).optional(),
  })
  .passthrough()
const EditFormStepViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    form_step_id: FormStepIdField.uuid(),
    name: z.string().min(1).optional(),
    description: z.string().min(1).optional(),
    order: z.number().int().optional(),
    external: z.boolean().optional(),
    sla: z.string().nullish(),
    due: z.string().nullish(),
    button_name: z.string().min(1).nullish(),
  })
  .passthrough()
const IdentityStepIdField = z.string()
const EditIdentityStepViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    identity_step_id: IdentityStepIdField.uuid(),
    add_contact: z.boolean().optional(),
    require_me: z.boolean().optional(),
    show_address: z.boolean().optional(),
    require_address: z.boolean().optional(),
    show_date_of_birth: z.boolean().optional(),
    require_date_of_birth: z.boolean().optional(),
    show_email: z.boolean().optional(),
    require_email: z.boolean().optional(),
    verify_email: z.boolean().optional(),
    show_name: z.boolean().optional(),
    require_name: z.boolean().optional(),
    show_phone: z.boolean().optional(),
    require_phone: z.boolean().optional(),
  })
  .passthrough()
const Type623Enum = z.enum(['Bool', 'Datetime', 'File', 'Identity', 'Location', 'Number', 'Text'])
const EditInputStepViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    input_step_id: InputStepIdField.uuid(),
    name: z.string().min(1).optional(),
    description: z.string().min(1).optional(),
    type: Type623Enum.optional(),
    order: z.number().int().optional(),
    style: StyleBadEnum.optional(),
    choices: z.array(ValueIdField).optional(),
    template: ValueIdField.nullish(),
    required: z.boolean().optional(),
    pinned: z.boolean().optional(),
  })
  .passthrough()
const EditProcessViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    process_id: ProcessIdField.uuid(),
    owner: IdentityIdField.uuid().optional(),
    name: z.string().min(1).optional(),
    description: z.string().min(1).optional(),
    status: Status2efEnum.optional(),
    appliable: z.boolean().optional(),
    copyable: z.boolean().optional(),
    velosimo_integrated: z.boolean().optional(),
  })
  .passthrough()
const EditSectionSubStepViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    section_step_id: SectionStepIdField.uuid(),
    name: z.string().min(1).optional(),
    description: z.string().min(1).optional(),
    order: z.number().int().optional(),
    button_name: z.string().min(1).nullish(),
    min: z.number().int().optional(),
    max: z.number().int().optional(),
  })
  .passthrough()
const StepIdField = z.string()
const EditStepViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    step_id: StepIdField.uuid(),
    set_status: z.union([StatusesEnum, NullEnum]).nullable(),
  })
  .passthrough()
const EditTaskViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    task_id: TaskIdField.uuid(),
    name: z.string().min(1).optional(),
    description: z.string().optional(),
    due: z.string().datetime({ offset: true }).optional(),
    completed: z.boolean().optional(),
    assignees: z.array(IdentityIdField).optional(),
  })
  .passthrough()
const GetBillStepViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), bill_step_id: BillStepIdField.uuid() })
  .passthrough()
const ChargeSubstepIdField = z.string()
const DollarsTypeEnum = z.enum([
  'bool',
  'datetime',
  'file',
  'identity',
  'location',
  'number',
  'text',
])
const ChargeSubstep = z
  .object({
    id: ChargeSubstepIdField.uuid(),
    bill: BillStepIdField.uuid(),
    dollars: ExpressionIdField.uuid(),
    code: z.string().regex(/^[a-z0-9-]+$/),
    name: z.string(),
    description: z.string(),
    dollars_type: DollarsTypeEnum,
  })
  .passthrough()
const BillStep1 = z
  .object({
    charges: z.array(ChargeSubstep),
    id: z.string().uuid(),
    process: z.string().uuid(),
    code: z.string(),
    step: z.string().uuid(),
    name: z.string(),
    description: z.string(),
    installments: z.number().int(),
    order: z.number().int(),
    account: z.string().nullable(),
    distribution: z.string().nullable(),
    set_status: z.union([StatusesEnum, NullEnum]).nullable(),
    added_at: z.string().datetime({ offset: true }).nullable(),
    removed_at: z.string().datetime({ offset: true }).nullable(),
    allow_ach: z.boolean(),
  })
  .passthrough()
const GetBillStepViewResponseData = z.object({ bill_step: BillStep1 }).passthrough()
const GetCaseViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), case_id: CaseIdField.uuid() })
  .passthrough()
const ContactIdField = z.string()
const Identity0 = z
  .object({
    name: z.string(),
    id: IdentityIdField.uuid(),
    contact_id: ContactIdField.uuid(),
    handle: z.string(),
    image: z.string().nullable(),
    stripe_id: z.string().nullable(),
    email: z.string().nullable(),
  })
  .passthrough()
const GeoPoint = z.object({ lon: z.number(), lat: z.number() }).passthrough()
const Address0 = z
  .object({
    id: z.string().uuid(),
    location_id: z.string().uuid(),
    contact_id: z.string().uuid().nullable(),
    street: z.string(),
    city: z.string(),
    state: z.string(),
    zip: z.string(),
    location: GeoPoint,
  })
  .passthrough()
const Location0 = z
  .object({ id: LocationIdField.uuid(), location: GeoPoint, address: Address0 })
  .passthrough()
const ContactEntryIdField = z.string()
const ContactEmailIdField = z.string()
const EmailAddressIdField = z.string()
const ContactEmail0 = z
  .object({
    signature: z.string(),
    address: z.string(),
    id: ContactEmailIdField.uuid(),
    email: EmailAddressIdField.uuid(),
    contact: ContactIdField.uuid(),
    primary: z.boolean(),
    verified: z.boolean(),
    ses_verified: z.boolean(),
  })
  .passthrough()
const NoteIdField = z.string()
const TargetIdField = z.string()
const Note0 = z
  .object({
    id: NoteIdField.uuid(),
    target: TargetIdField.uuid(),
    note: z.string(),
    created: z.string().datetime({ offset: true }),
    modified: z.string().datetime({ offset: true }),
    author: Identity0,
    owner: IdentityIdField.uuid(),
  })
  .passthrough()
const Identity2 = z
  .object({
    id: IdentityIdField.uuid(),
    contact_id: ContactIdField.uuid(),
    contact_entry_id: ContactEntryIdField.nullish(),
    emails: z.array(ContactEmail0),
    notes: z.array(Note0),
    first_name: z.string(),
    last_name: z.string(),
    owner: z.string().nullable(),
    handle: z.string(),
    name: z.string(),
    date_of_birth: z.string().nullable(),
    image: z.string().nullable(),
    is_contact: z.boolean(),
    phones: z.array(z.string()),
    addresses: z.array(z.string()),
  })
  .passthrough()
const Case2 = z
  .object({
    creator: Identity0.nullable(),
    location: Location0.nullable(),
    assignee: Identity0.nullable(),
    requester: Identity2.nullable(),
    id: z.string().uuid(),
    handle: z.string(),
    target_id: z.string().uuid(),
    owner_identity_id: z.string().uuid(),
    process: z.string().uuid().nullable(),
    status: StatusesEnum,
    title: z.string(),
    created_time: z.string().datetime({ offset: true }),
    message: z.string().nullable(),
    message_time: z.string().datetime({ offset: true }).nullable(),
    unread: z.boolean(),
    tags: z.array(z.string().uuid()),
  })
  .passthrough()
const GetCaseViewResponseData = z.object({ case: Case2 }).passthrough()
const GetCaseCountViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), filter: CaseFilterRequest })
  .passthrough()
const GetCaseCountViewResponseData = z
  .object({ total: z.number().int(), unread: z.number().int() })
  .passthrough()
const GetCaseIdViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), handle: z.string().min(1) })
  .passthrough()
const GetCaseIdViewResponseData = z.object({ case_id: CaseIdField.uuid() }).passthrough()
const GetCaseOwnerViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), case_id: CaseIdField.uuid() })
  .passthrough()
const GetCaseOwnerViewResponseData = z.object({ owner: Identity0 }).partial().passthrough()
const GetFormStepCountViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    step_id: FormStepIdField.uuid(),
    complete: z.boolean().optional().default(false),
    after: z.string().datetime({ offset: true }).nullish(),
    before: z.string().datetime({ offset: true }).nullish(),
    completion_time: z.string().nullish(),
  })
  .passthrough()
const GetFormStepCountViewResponseData = z.object({ count: z.number().int() }).passthrough()
const FormStepTimeFilterRequest = z
  .object({
    step_id: FormStepIdField.uuid(),
    after_created: z.string().datetime({ offset: true }).nullish(),
    before_created: z.string().datetime({ offset: true }).nullish(),
    after_completed: z.string().datetime({ offset: true }).nullish(),
    before_completed: z.string().datetime({ offset: true }).nullish(),
  })
  .passthrough()
const GetFormStepTimeViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), form_step_time_filter: FormStepTimeFilterRequest })
  .passthrough()
const GetFormStepTimeViewResponseData = z
  .object({ average_duration: z.string().nullable() })
  .partial()
  .passthrough()
const ProcessInfoArgsRequest = z
  .object({
    process_id: ProcessIdField.nullable(),
    owner_handle: z.string().nullable(),
    process_code: z.string().nullable(),
  })
  .partial()
  .passthrough()
const GetProcessViewRequestDataRequest = z
  .object({ process_info_args: ProcessInfoArgsRequest })
  .passthrough()
const FormStep0 = z
  .object({
    description: z.string(),
    id: z.string().uuid(),
    process: z.string().uuid(),
    code: z.string(),
    step: z.string().uuid(),
    name: z.string(),
    order: z.number().int(),
    external: z.boolean(),
    due: z.string().nullable(),
    sla: z.string().nullable(),
    button_name: z.string().nullable(),
    set_status: z.union([StatusesEnum, NullEnum]).nullable(),
    added_at: z.string().datetime({ offset: true }).nullable(),
    removed_at: z.string().datetime({ offset: true }).nullable(),
  })
  .passthrough()
const InputStepStyleEnum = z.enum([
  'RawInputStyle',
  'DropdownInputStyle',
  'RadioInputStyle',
  'LongTextInputStyle',
])
const InputStep = z
  .object({
    id: z.string().uuid().optional(),
    type: Type12eEnum,
    name: z.string(),
    description: z.string(),
    style: InputStepStyleEnum,
    required: z.boolean(),
    pinned: z.boolean(),
    order: z.number().int().gte(-9223372036854776000).lte(9223372036854776000),
    removed_at: z.string().datetime({ offset: true }).nullish(),
    added_at: z.string().datetime({ offset: true }).nullish(),
    template: z.string().uuid().nullish(),
    section: z.string().uuid().nullish(),
    form: z.string().uuid(),
    process: z.string().uuid(),
  })
  .passthrough()
const DatetimeStep = z
  .object({
    id: z.string().uuid().optional(),
    input_step: InputStep,
    type: Type12eEnum,
    request_time: z.boolean(),
  })
  .passthrough()
const IdentityStep = z
  .object({
    id: z.string().uuid().optional(),
    input_step: InputStep,
    type: Type12eEnum,
    require_me: z.boolean(),
    show_address: z.boolean(),
    require_address: z.boolean(),
    show_date_of_birth: z.boolean(),
    require_date_of_birth: z.boolean(),
    show_email: z.boolean(),
    require_email: z.boolean(),
    verify_email: z.boolean(),
    show_name: z.boolean(),
    require_name: z.boolean(),
    show_phone: z.boolean(),
    require_phone: z.boolean(),
    add_contact: z.boolean(),
  })
  .passthrough()
const InputStepSub0 = z
  .object({
    datetime_step_sub: DatetimeStep.nullish(),
    identity_step_sub: IdentityStep.nullish(),
    type: Type12eEnum,
  })
  .passthrough()
const BoolValue0 = z
  .object({ id: z.string().uuid(), value: z.string().uuid(), checked: z.boolean() })
  .passthrough()
const DatetimeValue0 = z
  .object({
    id: z.string().uuid(),
    value: z.string().uuid(),
    datetime: z.string().datetime({ offset: true }),
  })
  .passthrough()
const FileValue0 = z
  .object({ id: z.string().uuid(), value: z.string().uuid(), file: z.string() })
  .passthrough()
const IdentityValue0 = z
  .object({ id: z.string().uuid(), value: z.string().uuid(), identity: z.string().uuid() })
  .passthrough()
const LocationValue0 = z
  .object({
    id: z.string().uuid(),
    value: z.string().uuid(),
    location: z.string().uuid(),
    address: z.string().uuid().nullable(),
    street: z.string(),
    city: z.string(),
    state: z.string(),
    zip: z.string(),
  })
  .passthrough()
const NumberValue0 = z
  .object({ id: z.string().uuid(), value: z.string().uuid(), number: z.string() })
  .passthrough()
const TextValue0 = z
  .object({ id: z.string().uuid(), value: z.string().uuid(), text: z.string() })
  .passthrough()
const Value0 = z
  .object({
    bool_value: BoolValue0.nullish(),
    datetime_value: DatetimeValue0.nullish(),
    file_value: FileValue0.nullish(),
    identity_value: IdentityValue0.nullish(),
    location_value: LocationValue0.nullish(),
    number_value: NumberValue0.nullish(),
    text_value: TextValue0.nullish(),
    type: Type12eEnum,
  })
  .passthrough()
const InputStepChoice1 = z
  .object({ value: Value0, step: z.string().uuid(), order: z.number().int() })
  .passthrough()
const InputSubstep2 = z
  .object({
    sub: InputStepSub0,
    choices: z.array(InputStepChoice1),
    template: Value0.nullish(),
    id: z.string().uuid(),
    type: Type12eEnum,
    process: z.string().uuid(),
    code: z.string(),
    name: z.string(),
    description: z.string(),
    order: z.number().int(),
    style: StyleBadEnum,
    form: z.string().uuid(),
    section: z.string().uuid().nullable(),
    required: z.boolean(),
    external: z.boolean(),
    sla: z.string().nullable(),
    due: z.string().nullable(),
    pinned: z.boolean(),
    added_at: z.string().datetime({ offset: true }).nullable(),
    removed_at: z.string().datetime({ offset: true }).nullable(),
  })
  .passthrough()
const SectionSubstep0 = z
  .object({
    id: z.string().uuid(),
    process: z.string().uuid(),
    name: z.string(),
    description: z.string(),
    order: z.number().int(),
    form: z.string().uuid(),
    section: z.string().uuid().nullable(),
    button_name: z.string().nullable(),
    added_at: z.string().datetime({ offset: true }).nullable(),
    removed_at: z.string().datetime({ offset: true }).nullable(),
    multiple: z.boolean(),
    min: z.number().int(),
    max: z.number().int(),
    render: z.string().uuid().nullable(),
  })
  .passthrough()
const Process3 = z
  .object({
    bill_steps: z.array(BillStep1),
    form_steps: z.array(FormStep0),
    inputs: z.array(InputSubstep2),
    subsections: z.array(SectionSubstep0),
    id: z.string().uuid(),
    owner: Identity0,
    code: z.string(),
    name: z.string(),
    description: z.string(),
    status: Status2efEnum,
    appliable: z.boolean(),
    copyable: z.boolean(),
    velosimo_integrated: z.boolean(),
  })
  .passthrough()
const GetProcessViewResponseData = z.object({ process: Process3 }).passthrough()
const ProcessCaseListArgsRequest = z
  .object({
    process_id: ProcessIdField.nullable(),
    case_ids: z.array(z.string().uuid()).nullable(),
    case_handle: z.string().nullable(),
    managed_only: z.boolean(),
    limit: z.number().int().nullable(),
    offset: z.number().int().nullable(),
    status: z.union([StatusesEnum, NullEnum]).nullable(),
  })
  .partial()
  .passthrough()
const GetProcessCaseCountViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), process_case_list_args: ProcessCaseListArgsRequest })
  .passthrough()
const GetProcessCaseCountViewResponseData = z.object({ count: z.number().int() }).passthrough()
const GetTaskViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), task_id: TaskIdField.uuid() })
  .passthrough()
const Task1 = z
  .object({
    id: z.string().uuid(),
    owner: z.string().uuid(),
    name: z.string(),
    description: z.string(),
    created: z.string().datetime({ offset: true }),
    creator: Identity0.nullable(),
    complete: z.boolean(),
    completed: z.string().datetime({ offset: true }).nullable(),
    due: z.string().datetime({ offset: true }).nullable(),
    case_id: z.string().uuid().nullable(),
    item_id: z.string().uuid(),
  })
  .passthrough()
const GetTaskViewResponseData = z.object({ task: Task1 }).passthrough()
const ImportEmailViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    internet_message_id: z.string().min(1),
    subject: z.string().min(1),
    sender: z.string().min(1),
    recipients: z.array(z.string().min(1)),
    message: z.string().min(1),
  })
  .passthrough()
const ImportEmailViewResponseData = z.object({ message_id: MessageIdField.uuid() }).passthrough()
const InstantiateStepsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    step_ids: z.array(StepIdField),
    case_id: CaseIdField.uuid(),
  })
  .passthrough()
const ListCaseMembersViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), case_id: CaseIdField.uuid() })
  .passthrough()
const ListCaseMembersViewResponseData = z.object({ members: z.array(Identity2) }).passthrough()
const CaseStatusChangeIdField = z.string()
const ListCaseStatusChangesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    case_ids: z.array(CaseIdField),
    exclude_ids: z.array(CaseStatusChangeIdField).optional(),
  })
  .passthrough()
const CaseStatusChange0 = z
  .object({
    setter: Identity0.nullable(),
    id: z.string().uuid(),
    case: z.string().uuid(),
    status: StatusesEnum,
    time: z.string().datetime({ offset: true }),
  })
  .passthrough()
const ListCaseStatusChangesViewResponseData = z
  .object({ status_changes: z.array(CaseStatusChange0) })
  .passthrough()
const SortEnum = z.enum([
  'by_last_action_asc',
  'by_last_action_desc',
  'by_next_due',
  'by_last_message',
  'by_created_asc',
  'by_created_desc',
])
const ListCasesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    filter: CaseFilterRequest,
    sort: SortEnum,
    offset: z.number().int(),
    limit: z.number().int(),
  })
  .passthrough()
const ListCasesViewResponseData = z.object({ data: z.array(Case2) }).passthrough()
const ListCasesTabsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), tabs: z.array(CasesTabIdField).optional() })
  .passthrough()
const CasesTab = z
  .object({
    id: z.string().uuid().optional(),
    code: z.string().regex(/^[a-z0-9-]+$/),
    name: z.string(),
    order: z.number().int().gte(-9223372036854776000).lte(9223372036854776000),
    owner: z.string().uuid(),
  })
  .passthrough()
const CasesConfigTypeEnum = z.literal('saved')
const CasesConfig = z
  .object({
    id: z.string().uuid().optional(),
    type: CasesConfigTypeEnum.optional().default('saved'),
    created_after: z.string().datetime({ offset: true }).nullish(),
    created_before: z.string().datetime({ offset: true }).nullish(),
    next_due_after: z.string().datetime({ offset: true }).nullish(),
    next_due_before: z.string().datetime({ offset: true }).nullish(),
    no_manager: z.boolean().nullish(),
    tab: z.string().uuid(),
  })
  .passthrough()
const CasesConfig1 = z
  .object({
    config: CasesConfig,
    assignee: z.array(z.string().uuid()).nullable(),
    manager: z.array(z.string().uuid()).nullable(),
    owner: z.array(z.string().uuid()).nullable(),
    process: z.array(z.string().uuid()).nullable(),
    status: z.array(StatusesEnum).nullable(),
    tag: z.array(z.string().uuid()).nullable(),
  })
  .passthrough()
const CasesTab1 = z.object({ tab: CasesTab, config: CasesConfig1.nullish() }).passthrough()
const ListCasesTabsViewResponseData = z.object({ tabs: z.array(CasesTab1) }).passthrough()
const ListCasesWithLocationViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), filter: CaseFilterRequest })
  .passthrough()
const CaseWithLocation = z
  .object({
    id: z.string().uuid(),
    title: z.string(),
    handle: z.string(),
    owner_identity_id: z.string().uuid(),
    location: Location0,
  })
  .passthrough()
const ListCasesWithLocationViewResponseData = z
  .object({ cases: z.array(CaseWithLocation) })
  .passthrough()
const ListDraftsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), case_id: CaseIdField.uuid().optional() })
  .passthrough()
const Attachment = z.object({ name: z.string(), url: z.string() }).passthrough()
const Draft = z
  .object({
    message_id: MessageIdField.uuid(),
    author: Identity0,
    attachments: z.array(Attachment),
    case: z.string(),
    text: z.string(),
    body: z.string(),
    time: z.string().datetime({ offset: true }),
  })
  .passthrough()
const ListDraftsViewResponseData = z.object({ drafts: z.array(Draft) }).passthrough()
const ListExpressionsViewRequestDataRequest = z
  .object({
    expression_ids: z.array(ExpressionIdField),
    process_id: ProcessIdField.uuid(),
    input_step_ids: z.array(InputStepIdField),
  })
  .partial()
  .passthrough()
const ExpressionOperatorEnum = z.enum([
  'addition',
  'and',
  'conditional',
  'equals',
  'greater_than',
  'less_than',
  'maximum',
  'minimum',
  'multiplication',
  'or',
  'subtraction',
])
const ParameterOneTypeEnum = z.enum([
  'bool',
  'datetime',
  'file',
  'identity',
  'location',
  'number',
  'text',
])
const ParameterTwoTypeEnum = z.enum([
  'bool',
  'datetime',
  'file',
  'identity',
  'location',
  'number',
  'text',
])
const ParameterThreeTypeEnum = z.enum([
  'bool',
  'datetime',
  'file',
  'identity',
  'location',
  'number',
  'text',
])
const Expression = z
  .object({
    id: ExpressionIdField.uuid(),
    process: ProcessIdField.uuid(),
    type: Type12eEnum,
    case_creator: z.boolean().nullish(),
    name: z.string().nullish(),
    operator: z.union([ExpressionOperatorEnum, BlankEnum, NullEnum]).nullish(),
    parameter_one_type: z.union([ParameterOneTypeEnum, BlankEnum, NullEnum]).nullish(),
    parameter_two_type: z.union([ParameterTwoTypeEnum, BlankEnum, NullEnum]).nullish(),
    parameter_three_type: z.union([ParameterThreeTypeEnum, BlankEnum, NullEnum]).nullish(),
    constant: z.string().uuid().nullish(),
    input_value: z.string().uuid().nullish(),
    parameter_one: z.string().uuid().nullish(),
    parameter_two: z.string().uuid().nullish(),
    parameter_three: z.string().uuid().nullish(),
  })
  .passthrough()
const ListExpressionsViewResponseData = z.object({ data: z.array(Expression) }).passthrough()
const ListFormStepsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    form_step_id: FormStepIdField.uuid().optional(),
    process_ids: z.array(ProcessIdField).optional(),
    has_sla: z.boolean().optional().default(false),
    managed_only: z.boolean().optional().default(false),
  })
  .passthrough()
const ListFormStepsViewResponseData = z.object({ form_steps: z.array(FormStep0) }).passthrough()
const ListFormsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    form_ids: z.array(FormIdField).optional(),
    item_ids: z.array(ItemIdField).optional(),
    case_ids: z.array(CaseIdField).optional(),
    step_ids: z.array(StepIdField).optional(),
  })
  .passthrough()
const Form0 = z
  .object({
    id: z.string().uuid(),
    name: z.string(),
    description: z.string(),
    created: z.string().datetime({ offset: true }),
    completed: z.string().datetime({ offset: true }).nullable(),
    completer: z.string().uuid().nullable(),
    process_id: z.string().uuid(),
    case_id: z.string().uuid(),
    form_step_id: z.string().uuid(),
    step_id: z.string().uuid(),
    order: z.number().int(),
    due: z.string().datetime({ offset: true }).nullable(),
    item: z.string().uuid(),
    action: z.string().uuid(),
  })
  .passthrough()
const ListFormsViewResponseData = z.object({ forms: z.array(Form0) }).passthrough()
const InputStepFilterRequest = z
  .object({
    input_step_ids: z.array(z.string().uuid()).nullable(),
    process_ids: z.array(z.string().uuid()).nullable(),
    form_step_ids: z.array(z.string().uuid()).nullable(),
    pinned_only: z.boolean(),
    has_sla: z.boolean(),
    managed_only: z.boolean(),
  })
  .partial()
  .passthrough()
const ListInputSubStepsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), input_step_filter: InputStepFilterRequest })
  .passthrough()
const ListInputSubStepsViewResponseData = z
  .object({ input_substeps: z.array(InputSubstep2) })
  .passthrough()
const InputFilterRequest = z
  .object({
    input_id: InputIdField.nullable(),
    form_ids: z.array(z.string().uuid()).nullable(),
    case_ids: z.array(z.string().uuid()).nullable(),
    process_id: ProcessIdField.nullable(),
    input_step_ids: z.array(z.string().uuid()).nullable(),
    complete: z.boolean().nullable(),
    external_only: z.boolean(),
    pinned_only: z.boolean(),
  })
  .partial()
  .passthrough()
const ListInputsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), input_filter: InputFilterRequest })
  .passthrough()
const Input1 = z
  .object({
    value: Value0.nullish(),
    template: Value0.nullish(),
    id: z.string().uuid(),
    name: z.string(),
    description: z.string(),
    type: Type12eEnum,
    value_id: z.string().uuid().nullable(),
    template_id: z.string().uuid().nullable(),
    setter: z.string().uuid().nullable(),
    complete: z.boolean(),
    created: z.string().datetime({ offset: true }),
    completed: z.string().datetime({ offset: true }).nullable(),
    process_id: z.string().uuid(),
    case_id: z.string().uuid(),
    step_id: z.string().uuid(),
    form_id: z.string().uuid(),
    item_id: z.string().uuid(),
    order: z.number().int().nullable(),
    due: z.string().datetime({ offset: true }).nullable(),
    section: z.string().uuid().nullable(),
  })
  .passthrough()
const ListInputsViewResponseData = z.object({ inputs: z.array(Input1) }).passthrough()
const ListItemsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    item_ids: z.array(ItemIdField).optional(),
    complete: z.boolean().optional(),
    case_ids: z.array(CaseIdField).optional(),
  })
  .passthrough()
const BillIdField = z.string()
const ActionIdField = z.string()
const Bill0 = z
  .object({
    id: BillIdField.uuid(),
    receiver_id: IdentityIdField.uuid(),
    code: z.string(),
    name: z.string(),
    description: z.string(),
    payer: IdentityIdField.nullable(),
    installments: z.number().int(),
    editable: z.boolean(),
    complete: z.boolean(),
    created: z.string().datetime({ offset: true }),
    due: z.string().datetime({ offset: true }).nullable(),
    completed: z.string().datetime({ offset: true }).nullable(),
    process_id: ProcessIdField.uuid(),
    bill_step_id: BillStepIdField.uuid(),
    allow_ach: z.boolean(),
    step_id: StepIdField.uuid(),
    case_id: CaseIdField.uuid(),
    order: z.number().int().nullable(),
    action_id: ActionIdField.uuid(),
    item_id: ItemIdField.uuid(),
  })
  .passthrough()
const Message0 = z
  .object({
    id: z.string().uuid(),
    body: z.string(),
    text: z.string(),
    author_id: z.string().uuid(),
    creator_id: z.string().uuid(),
    time: z.string().datetime({ offset: true }),
    due: z.string().datetime({ offset: true }).nullable(),
    medium: MediumEnum,
    email_message_id: z.string().nullable(),
    email_subject: z.string().nullable(),
    html: z.string().nullable(),
    completed: z.string().datetime({ offset: true }).nullable(),
    case: z.string().uuid().nullable(),
    item_id: z.string().uuid(),
  })
  .passthrough()
const ListItemsViewResponseData = z
  .object({
    bills: z.array(Bill0),
    forms: z.array(Form0),
    messages: z.array(Message0),
    tasks: z.array(Task1),
  })
  .passthrough()
const ListMessagesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    message_id: MessageIdField.uuid().optional(),
    item_ids: z.array(ItemIdField).optional(),
    case_handles: z.array(z.string().min(1)).optional(),
    case_ids: z.array(CaseIdField).optional(),
    participant_ids: z.array(ContactIdField).optional(),
    start: z.string().datetime({ offset: true }).optional(),
    end: z.string().datetime({ offset: true }).optional(),
    only_my_mailbox: z.boolean().optional().default(false),
    email_message_ids: z.array(z.string().min(1)).optional(),
    excludes: z.array(MessageIdField).optional().default([]),
    limit: z.number().int().optional(),
  })
  .passthrough()
const Case1 = z
  .object({
    location: Location0.nullable(),
    id: CaseIdField.uuid(),
    creator_user_id: EndUserIdField.nullable(),
    handle: z.string(),
    target_id: TargetIdField.uuid(),
    owner_identity_id: IdentityIdField.uuid(),
    assignee_identity_id: IdentityIdField.nullable(),
    process_id: ProcessIdField.nullable(),
    requester_identity_id: IdentityIdField.nullable(),
    status: StatusesEnum,
    title: z.string(),
    created_time: z.string().datetime({ offset: true }),
    message: z.string().nullable(),
    message_time: z.string().datetime({ offset: true }).nullable(),
    unread: z.boolean(),
  })
  .passthrough()
const Log1 = z
  .object({
    case: Case1.optional(),
    attachments: z.array(Attachment),
    author: Identity2,
    creator: Identity2,
    id: z.string().uuid(),
    body: z.string(),
    text: z.string(),
    participant_ids: z.array(z.string().uuid()),
    time: z.string().datetime({ offset: true }),
    medium: MediumEnum,
    completed: z.string().datetime({ offset: true }).nullable(),
    item_id: z.string().uuid(),
  })
  .passthrough()
const EmailAddress0 = z.object({ id: IdentityIdField.uuid(), address: z.string() }).passthrough()
const EmailIdField = z.string()
const EmailParticipant0TypeEnum = z.enum(['to', 'cc', 'bcc', 'from'])
const EmailParticipant0 = z
  .object({
    address: EmailAddress0,
    email: EmailIdField.uuid(),
    type: EmailParticipant0TypeEnum,
    name: z.string(),
    message_id: MessageIdField.uuid(),
  })
  .passthrough()
const Email1 = z
  .object({
    participants: z.array(EmailParticipant0),
    case: Case1.optional(),
    attachments: z.array(Attachment),
    body: z.string(),
    text: z.string(),
    time: z.string().datetime({ offset: true }),
    medium: MediumEnum,
    message_id: z.string().nullable(),
    subject: z.string(),
    html: z.string().nullable(),
    completed: z.string().datetime({ offset: true }).nullable(),
    item_id: ItemIdField.uuid(),
    id: MessageIdField.uuid(),
  })
  .passthrough()
const ListMessagesViewResponseData = z
  .object({ logs: z.array(Log1), emails: z.array(Email1), limit: z.boolean() })
  .passthrough()
const ListNearbyCasesViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), location_id: LocationIdField.uuid() })
  .passthrough()
const ListNearbyCasesViewResponseData = z.object({ cases: z.array(Case2) }).passthrough()
const NotificationFilterRequest = z
  .object({
    notification_id: NotificationIdField.nullable(),
    item_ids: z.array(z.string().uuid()).nullable(),
    case_ids: z.array(z.string().uuid()).nullable(),
  })
  .partial()
  .passthrough()
const ListNotificationsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), notification_filter: NotificationFilterRequest })
  .passthrough()
const Notification1 = z
  .object({
    assignee: Identity0,
    id: z.string().uuid(),
    item_id: z.string().uuid(),
    after: z.string().datetime({ offset: true }),
    assigned: z.boolean(),
    read: z.boolean(),
  })
  .passthrough()
const ListNotificationsViewResponseData = z
  .object({ notifications: z.array(Notification1) })
  .passthrough()
const ListProcessCasesViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), process_case_list_args: ProcessCaseListArgsRequest })
  .passthrough()
const ProcessCase3 = z
  .object({
    process: Process3,
    case: Case2,
    case_id: z.string().uuid(),
    name: z.string(),
    bill_actions: z.array(Bill0),
    form_actions: z.array(Form0),
    inputs: z.array(Input1),
  })
  .passthrough()
const ListProcessCasesViewResponseData = z
  .object({ process_case_3s: z.array(ProcessCase3) })
  .passthrough()
const ListProcessSummariesViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), case_ids: z.array(CaseIdField) })
  .passthrough()
const ProcessSummary = z
  .object({
    id: z.string().uuid(),
    case_id: z.string().uuid(),
    name: z.string(),
    current_step_id: z.string().uuid().nullable(),
    current_step_name: z.string().nullable(),
    total_steps: z.number().int(),
    completed_steps: z.number().int(),
  })
  .passthrough()
const ListProcessSummariesViewResponseData = z
  .object({ processes: z.array(ProcessSummary) })
  .passthrough()
const PhoneNumberIdField = z.string()
const ProcessFilterRequest = z
  .object({
    owners: z.array(z.string().uuid()).nullable(),
    has_bill: z.boolean(),
    code: z.string().nullable(),
    owner_handle: z.string().nullable(),
    process_ids: z.array(z.string().uuid()).nullable(),
    phone_line: PhoneNumberIdField.nullable(),
    has_phone: z.boolean(),
    managed_only: z.boolean(),
    copyable_only: z.boolean(),
    status: z.array(Status2efEnum).nullable(),
    query: z.string().nullable(),
  })
  .partial()
  .passthrough()
const ListProcessesViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), process_filter: ProcessFilterRequest })
  .passthrough()
const Process0 = z
  .object({
    description: z.string(),
    id: z.string().uuid(),
    owner: Identity0,
    code: z.string(),
    name: z.string(),
    status: Status2efEnum,
    appliable: z.boolean(),
    copyable: z.boolean(),
    velosimo_integrated: z.boolean(),
    phone_line: z.string().nullable(),
  })
  .passthrough()
const ListProcessesViewResponseData = z.object({ processes: z.array(Process0) }).passthrough()
const ListProcessesStatsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), process_filter: ProcessFilterRequest })
  .passthrough()
const Process1 = z
  .object({
    description: z.string(),
    id: z.string().uuid(),
    owner: Identity0,
    code: z.string(),
    name: z.string(),
    status: Status2efEnum,
    appliable: z.boolean(),
    copyable: z.boolean(),
    velosimo_integrated: z.boolean(),
    total: z.number().int(),
    completed: z.number().int(),
  })
  .passthrough()
const ListProcessesStatsViewResponseData = z.object({ processes: z.array(Process1) }).passthrough()
const ListRelationsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), case_id: CaseIdField.uuid() })
  .passthrough()
const RelationIdField = z.string()
const Relation = z
  .object({ id: RelationIdField.uuid(), from_field: CaseIdField.uuid(), to: CaseIdField.uuid() })
  .passthrough()
const ListRelationsViewResponseData = z.object({ relations: z.array(Relation) }).passthrough()
const ListSectionSubStepsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    section_step_ids: z.array(SectionStepIdField).optional(),
    process_ids: z.array(ProcessIdField).optional(),
  })
  .passthrough()
const ListSectionSubStepsViewResponseData = z
  .object({ section_substeps: z.array(SectionSubstep0) })
  .passthrough()
const ListSectionsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    section_ids: z.array(SectionIdField).optional(),
    form_ids: z.array(FormIdField).optional(),
    case_ids: z.array(CaseIdField).optional(),
    process_id: ProcessIdField.uuid().optional(),
    section_step_ids: z.array(SectionStepIdField).optional(),
    complete: z.boolean().optional(),
    external_only: z.boolean().optional().default(false),
  })
  .passthrough()
const Section = z
  .object({
    id: SectionIdField.uuid(),
    form: FormIdField.uuid(),
    substep: SectionStepIdField.uuid(),
    form_step: FormStepIdField.uuid(),
    section: SectionIdField.nullish(),
    created: z.string().datetime({ offset: true }).optional(),
    order: z.number().int().gte(-9223372036854776000).lte(9223372036854776000).optional(),
  })
  .passthrough()
const ListSectionsViewResponseData = z.object({ sections: z.array(Section) }).passthrough()
const ListStepAssignmentsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), step_ids: z.array(StepIdField) })
  .passthrough()
const StepNotificationIdField = z.string()
const UserTypeEnum = z.enum(['bool', 'datetime', 'file', 'identity', 'location', 'number', 'text'])
const StepNotification = z
  .object({
    id: StepNotificationIdField.uuid(),
    step: StepIdField.uuid(),
    user: EndUserIdField.uuid(),
    assigned: z.boolean(),
    user_type: UserTypeEnum,
  })
  .passthrough()
const ListStepAssignmentsViewResponseData = z
  .object({ step_assignments: z.array(StepNotification) })
  .passthrough()
const ListTasksViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    task_id: TaskIdField.uuid().optional(),
    item_ids: z.array(ItemIdField).optional(),
    case_ids: z.array(CaseIdField).optional(),
  })
  .passthrough()
const ListTasksViewResponseData = z.object({ tasks: z.array(Task1) }).passthrough()
const ListTemplatesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    template_id: MessageTemplateIdField.uuid().optional(),
    owner_ids: z.array(IdentityIdField).optional(),
    organization_code: z.string().min(1).optional(),
    template_code: z.string().min(1).optional(),
    query: z.string().min(1).optional(),
    limit: z.number().int().optional(),
  })
  .passthrough()
const MessageTemplate0 = z
  .object({
    owner: Identity0,
    attachments: z.array(Attachment),
    name: z.string(),
    body: z.string(),
    id: z.string().uuid(),
    code: z.string(),
    modified: z.string().datetime({ offset: true }),
  })
  .passthrough()
const ListTemplatesViewResponseData = z
  .object({ templates: z.array(MessageTemplate0) })
  .passthrough()
const ListValuesViewRequestDataRequest = z
  .object({ value_ids: z.array(ValueIdField), type: Type623Enum.optional() })
  .passthrough()
const ListValuesViewResponseData = z.object({ values: z.array(Value0) }).passthrough()
const MarkCaseReadViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), case_id: CaseIdField.uuid() })
  .passthrough()
const NotifyArgsRequest = z
  .object({
    item_id: ItemIdField.uuid(),
    assignee: IdentityIdField.uuid(),
    assigned: z.boolean(),
    send: z.boolean(),
    after: z.string().datetime({ offset: true }).nullish(),
  })
  .passthrough()
const NotifyViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), notify_args: NotifyArgsRequest })
  .passthrough()
const RemoveCaseMemberViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    case_id: CaseIdField.uuid(),
    identity_id: IdentityIdField.uuid(),
  })
  .passthrough()
const InputGroupCreateRequest = z
  .object({
    inputs: z.array(InputCreateRequest),
    section_substep: SectionSubstep0Request.nullish(),
    order: z.number().int().nullable(),
  })
  .passthrough()
const SaveOrSubmitFormViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    mark_form_complete: z.boolean(),
    input_groups: z.array(InputGroupCreateRequest),
  })
  .passthrough()
const SearchCasesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    query: z.string().min(1),
    offset: z.number().int(),
    limit: z.number().int(),
  })
  .passthrough()
const SearchCasesViewResponseData = z.object({ cases: z.array(Case2) }).passthrough()
const SearchCasesCountViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), query: z.string().min(1) })
  .passthrough()
const SearchCasesCountViewResponseData = z.object({ count: z.number().int() }).passthrough()
const SearchProcessesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    query: z.string().min(1).optional(),
    has_bill: z.boolean(),
    limit: z.number().int().optional(),
    status: z.array(Status2efEnum).optional(),
  })
  .passthrough()
const SearchProcessesViewResponseData = z.object({ processes: z.array(Process0) }).passthrough()
const VelosimoEventEnum = z.enum([
  'add_note',
  'update_status',
  'submit_form',
  'sync_case',
  'update_form',
])
const SendVelosimoCaseViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    case_id: CaseIdField.uuid(),
    velosimo_event: VelosimoEventEnum,
  })
  .passthrough()
const SetActionCompleteViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    action_id: ActionIdField.uuid(),
    complete: z.boolean(),
    completed: z.string().datetime({ offset: true }).optional(),
  })
  .passthrough()
const SetCaseStatusViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), case_id: CaseIdField.uuid(), status: StatusesEnum })
  .passthrough()
const SetStepAssignmentViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    step_id: StepIdField.uuid(),
    assigned: z.boolean(),
    user_expression_id: ExpressionIdField.uuid(),
  })
  .passthrough()
const StartProcessViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), process_id: ProcessIdField.uuid() })
  .passthrough()
const StartProcessViewResponseData = z
  .object({ case_id: CaseIdField.uuid(), handle: z.string() })
  .passthrough()
const CasesConfigUpsertRequest = z
  .object({
    tab_id: CasesTabIdField.uuid(),
    created_after: z.string().datetime({ offset: true }).nullish(),
    created_before: z.string().datetime({ offset: true }).nullish(),
    next_due_after: z.string().datetime({ offset: true }).nullish(),
    next_due_before: z.string().datetime({ offset: true }).nullish(),
    no_manager: z.boolean().nullish(),
    assignee: z.array(z.string().uuid()).nullish(),
    manager: z.array(z.string().uuid()).nullish(),
    owner: z.array(z.string().uuid()).nullish(),
    process: z.array(z.string().uuid()).nullish(),
    status: z.array(StatusesEnum).nullish(),
    tag: z.array(z.string().uuid()).nullish(),
  })
  .passthrough()
const UpsertCasesConfigViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), config: CasesConfigUpsertRequest })
  .passthrough()
const UpsertTemplateViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    template_id: MessageTemplateIdField.uuid().optional(),
    name: z.string().min(1),
    body: z.string().min(1),
    attachments: z.array(AttachmentRequest),
  })
  .passthrough()
const UpsertTemplateViewResponseData = z
  .object({ template_id: MessageTemplateIdField.uuid() })
  .passthrough()
const AddressIdField = z.string()
const AddAddressViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    address_id: AddressIdField.uuid(),
    contact_id: ContactIdField.uuid().optional(),
  })
  .passthrough()
const AddPhoneNumberViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    contact_id: ContactIdField.uuid().optional(),
    phone_number: z.string().min(1),
  })
  .passthrough()
const GeoPointRequest = z.object({ lon: z.number(), lat: z.number() }).passthrough()
const ContactFilterRequest = z
  .object({
    book: IdentityIdField.nullable(),
    tags: z.array(z.string().uuid()).nullable(),
    points: z.array(GeoPointRequest).nullable(),
    name: z.string().nullable(),
    email: z.string().nullable(),
    phone: z.string().nullable(),
    address_street: z.string().nullable(),
    attribute_name: z.string().nullable(),
    attribution_value: z.string().nullable(),
  })
  .partial()
  .passthrough()
const CountContactsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), contact_filter: ContactFilterRequest })
  .passthrough()
const CountContactsViewResponseData = z.object({ num_contacts: z.number().int() }).passthrough()
const CreateAddressViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    street: z.string().min(1),
    city: z.string().min(1),
    state: z.string().min(1),
    zip: z.string().min(1),
    location: GeoPointRequest,
  })
  .passthrough()
const CreateAddressViewResponseData = z.object({ address: Address0 }).passthrough()
const TagIdField = z.string()
const AttributeIdField = z.string()
const AttributionCreateRequest = z
  .object({ name: z.string(), value: z.string(), attribute_id: AttributeIdField.nullable() })
  .passthrough()
const CreateContactViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    title: z.string().optional(),
    first_name: z.string().min(1),
    middle_name: z.string().optional(),
    last_name: z.string().min(1),
    suffix: z.string().optional(),
    date_of_birth: z.string().nullish(),
    emails: z.array(z.string().min(1)),
    phones: z.array(z.string().min(1)),
    addresses: z.array(AddressIdField),
    tags: z.array(TagIdField),
    attributions: z.array(AttributionCreateRequest),
  })
  .passthrough()
const CreateContactViewResponseData = z
  .object({
    contact_entry_id: ContactEntryIdField.uuid().optional(),
    identity_id: IdentityIdField.uuid().optional(),
    target_id: TargetIdField.uuid().optional(),
    email_address_conflict: z.boolean(),
  })
  .passthrough()
const CreateEmailAddressViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    address: z.string().min(1),
    verify: z.boolean(),
    contact_id: ContactIdField.uuid().optional(),
  })
  .passthrough()
const DeleteContactViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), contact_entry_id: ContactEntryIdField.uuid() })
  .passthrough()
const DownloadContactsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    contact_filter: ContactFilterRequest,
    excludes: z.array(ContactEntryIdField).optional(),
    query: z.string().min(1).optional(),
  })
  .passthrough()
const DownloadContactsViewResponseData = z
  .object({ email_recipient: z.string().email() })
  .passthrough()
const DraftEmailViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    from_id: EmailAddressIdField.uuid(),
    to_ids: z.array(EmailAddressIdField),
    bcc_ids: z.array(EmailAddressIdField),
    subject: z.string().min(1),
    body: z.string().min(1),
    attachments: z.array(AttachmentRequest),
    case_id: CaseIdField.uuid(),
  })
  .passthrough()
const DraftEmailViewResponseData = z.object({ email_id: EmailIdField.uuid() }).passthrough()
const Identity0Request = z
  .object({
    id: z.string().uuid(),
    contact_id: z.string().uuid(),
    handle: z.string(),
    name: z.string(),
    image: z.string().nullable(),
    stripe_id: z.string().nullable(),
    email: z.string().nullable(),
  })
  .passthrough()
const VerifyAdminRequest = z.object({ identity: Identity0Request }).passthrough()
const ChargeSubstepRequest = z
  .object({
    id: ChargeSubstepIdField.uuid(),
    bill: BillStepIdField.uuid(),
    dollars: ExpressionIdField.uuid(),
    code: z.string().regex(/^[a-z0-9-]+$/),
    name: z.string(),
    description: z.string(),
    dollars_type: DollarsTypeEnum,
  })
  .passthrough()
const BillStep1Request = z
  .object({
    charges: z.array(ChargeSubstepRequest),
    id: z.string().uuid(),
    process: z.string().uuid(),
    code: z.string(),
    step: z.string().uuid(),
    name: z.string(),
    description: z.string(),
    installments: z.number().int(),
    order: z.number().int(),
    account: z.string().nullable(),
    distribution: z.string().nullable(),
    set_status: z.union([StatusesEnum, NullEnum]).nullable(),
    added_at: z.string().datetime({ offset: true }).nullable(),
    removed_at: z.string().datetime({ offset: true }).nullable(),
    allow_ach: z.boolean(),
  })
  .passthrough()
const FormStep0Request = z
  .object({
    description: z.string(),
    id: z.string().uuid(),
    process: z.string().uuid(),
    code: z.string(),
    step: z.string().uuid(),
    name: z.string(),
    order: z.number().int(),
    external: z.boolean(),
    due: z.string().nullable(),
    sla: z.string().nullable(),
    button_name: z.string().nullable(),
    set_status: z.union([StatusesEnum, NullEnum]).nullable(),
    added_at: z.string().datetime({ offset: true }).nullable(),
    removed_at: z.string().datetime({ offset: true }).nullable(),
  })
  .passthrough()
const InputStepRequest = z
  .object({
    id: z.string().uuid().optional(),
    type: Type12eEnum,
    name: z.string(),
    description: z.string(),
    style: InputStepStyleEnum,
    required: z.boolean(),
    pinned: z.boolean(),
    order: z.number().int().gte(-9223372036854776000).lte(9223372036854776000),
    removed_at: z.string().datetime({ offset: true }).nullish(),
    added_at: z.string().datetime({ offset: true }).nullish(),
    template: z.string().uuid().nullish(),
    section: z.string().uuid().nullish(),
    form: z.string().uuid(),
    process: z.string().uuid(),
  })
  .passthrough()
const DatetimeStepRequest = z
  .object({
    id: z.string().uuid().optional(),
    input_step: InputStepRequest,
    type: Type12eEnum,
    request_time: z.boolean(),
  })
  .passthrough()
const IdentityStepRequest = z
  .object({
    id: z.string().uuid().optional(),
    input_step: InputStepRequest,
    type: Type12eEnum,
    require_me: z.boolean(),
    show_address: z.boolean(),
    require_address: z.boolean(),
    show_date_of_birth: z.boolean(),
    require_date_of_birth: z.boolean(),
    show_email: z.boolean(),
    require_email: z.boolean(),
    verify_email: z.boolean(),
    show_name: z.boolean(),
    require_name: z.boolean(),
    show_phone: z.boolean(),
    require_phone: z.boolean(),
    add_contact: z.boolean(),
  })
  .passthrough()
const InputStepSub0Request = z
  .object({
    datetime_step_sub: DatetimeStepRequest.nullish(),
    identity_step_sub: IdentityStepRequest.nullish(),
    type: Type12eEnum,
  })
  .passthrough()
const BoolValue0Request = z
  .object({ id: z.string().uuid(), value: z.string().uuid(), checked: z.boolean() })
  .passthrough()
const DatetimeValue0Request = z
  .object({
    id: z.string().uuid(),
    value: z.string().uuid(),
    datetime: z.string().datetime({ offset: true }),
  })
  .passthrough()
const FileValue0Request = z
  .object({ id: z.string().uuid(), value: z.string().uuid(), file: z.string() })
  .passthrough()
const IdentityValue0Request = z
  .object({ id: z.string().uuid(), value: z.string().uuid(), identity: z.string().uuid() })
  .passthrough()
const LocationValue0Request = z
  .object({
    id: z.string().uuid(),
    value: z.string().uuid(),
    location: z.string().uuid(),
    address: z.string().uuid().nullable(),
    street: z.string(),
    city: z.string(),
    state: z.string(),
    zip: z.string(),
  })
  .passthrough()
const NumberValue0Request = z
  .object({ id: z.string().uuid(), value: z.string().uuid(), number: z.string() })
  .passthrough()
const TextValue0Request = z
  .object({ id: z.string().uuid(), value: z.string().uuid(), text: z.string() })
  .passthrough()
const Value0Request = z
  .object({
    bool_value: BoolValue0Request.nullish(),
    datetime_value: DatetimeValue0Request.nullish(),
    file_value: FileValue0Request.nullish(),
    identity_value: IdentityValue0Request.nullish(),
    location_value: LocationValue0Request.nullish(),
    number_value: NumberValue0Request.nullish(),
    text_value: TextValue0Request.nullish(),
    type: Type12eEnum,
  })
  .passthrough()
const InputStepChoice1Request = z
  .object({ value: Value0Request, step: z.string().uuid(), order: z.number().int() })
  .passthrough()
const InputSubstep2Request = z
  .object({
    sub: InputStepSub0Request,
    choices: z.array(InputStepChoice1Request),
    template: Value0Request.nullish(),
    id: z.string().uuid(),
    type: Type12eEnum,
    process: z.string().uuid(),
    code: z.string(),
    name: z.string(),
    description: z.string(),
    order: z.number().int(),
    style: StyleBadEnum,
    form: z.string().uuid(),
    section: z.string().uuid().nullable(),
    required: z.boolean(),
    external: z.boolean(),
    sla: z.string().nullable(),
    due: z.string().nullable(),
    pinned: z.boolean(),
    added_at: z.string().datetime({ offset: true }).nullable(),
    removed_at: z.string().datetime({ offset: true }).nullable(),
  })
  .passthrough()
const Process3Request = z
  .object({
    bill_steps: z.array(BillStep1Request),
    form_steps: z.array(FormStep0Request),
    inputs: z.array(InputSubstep2Request),
    subsections: z.array(SectionSubstep0Request),
    id: z.string().uuid(),
    owner: Identity0Request,
    code: z.string(),
    name: z.string(),
    description: z.string(),
    status: Status2efEnum,
    appliable: z.boolean(),
    copyable: z.boolean(),
    velosimo_integrated: z.boolean(),
  })
  .passthrough()
const VerifyApplyRequest = z.object({ process: Process3Request }).passthrough()
const OrganizationTypeEnum = z.enum([
  'OrganizationCity',
  'OrganizationCountry',
  'OrganizationCounty',
  'OrganizationDepartment',
  'OrganizationNGO',
  'OrganizationPoli',
  'OrganizationState',
])
const Identity1Request = z
  .object({
    id: z.string().uuid(),
    contact_id: z.string().uuid(),
    handle: z.string(),
    name: z.string(),
    image: z.string().nullable(),
    stripe_id: z.string().nullable(),
    email: z.string().nullable(),
    contacts_owner_identity_id: z.string().uuid(),
    phone: z.string().nullable(),
    first_name: z.string().nullable(),
    last_name: z.string().nullable(),
    birthday: z.string().nullable(),
    auto_payout: z.boolean().nullable(),
    organization_type: z.union([OrganizationTypeEnum, NullEnum]).nullable(),
  })
  .passthrough()
const VerifyPaymentRequest = z
  .object({
    identity: Identity1Request,
    message: z.string().nullable(),
    owner: z.string(),
    code: z.string(),
  })
  .passthrough()
const VerifyStandardRequest = z.object({ uri: z.string().nullable() }).passthrough()
const VerifyThreadRequest = z
  .object({ identity: Identity0Request, title: z.string(), handle: z.string() })
  .passthrough()
const VerifyOptionsTypeEnum = z.enum(['apply', 'admin', 'payment', 'signup', 'standard', 'thread'])
const VerifyOptionsRequest = z
  .object({
    admin: VerifyAdminRequest.nullish(),
    apply: VerifyApplyRequest.nullish(),
    payment: VerifyPaymentRequest.nullish(),
    standard: VerifyStandardRequest.nullish(),
    thread: VerifyThreadRequest.nullish(),
    type: VerifyOptionsTypeEnum,
  })
  .passthrough()
const EmailSignupViewRequestDataRequest = z
  .object({ email: z.string().min(1), verify_options: VerifyOptionsRequest })
  .passthrough()
const EmailSignupViewResponseData = z
  .object({ contact_email_id: ContactEmailIdField.uuid() })
  .partial()
  .passthrough()
const GetContactEntryViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), contact_entry_id: ContactEntryIdField.uuid() })
  .passthrough()
const ContactEntry0 = z
  .object({
    id: ContactEntryIdField.uuid(),
    owner: z.string(),
    owner_id: IdentityIdField.uuid(),
    handle: z.string(),
    target: TargetIdField.uuid(),
    name: z.string(),
    image: z.string().nullable(),
    contact: ContactIdField.uuid(),
  })
  .passthrough()
const GetContactEntryViewResponseData = z.object({ contact_entry: ContactEntry0 }).passthrough()
const GetContactEntryExpandedViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    owner_handle: z.string().min(1),
    identity_handle: z.string().min(1),
  })
  .passthrough()
const PhoneNumber0 = z
  .object({
    phone_number_id: PhoneNumberIdField.uuid(),
    contact_id: ContactIdField.uuid(),
    phone_number: z.string(),
  })
  .passthrough()
const Tag = z
  .object({
    id: TagIdField.uuid(),
    owner: IdentityIdField.uuid(),
    name: z.string(),
    color: z.string(),
    code: z.string().regex(/^[a-z0-9-]+$/),
    description: z.string().nullish(),
  })
  .passthrough()
const Attribute = z
  .object({
    id: z.string().uuid().optional(),
    owner_id: IdentityIdField.uuid(),
    code: z.string().regex(/^[a-z0-9-]+$/),
    name: z.string(),
    info: z.string(),
  })
  .passthrough()
const Attribution = z
  .object({
    id: z.string().uuid().optional(),
    target: z.string().uuid(),
    attribute: Attribute,
    value: z.string(),
  })
  .passthrough()
const ContactEntry2 = z
  .object({
    emails: z.array(ContactEmail0),
    phones: z.array(PhoneNumber0),
    addresses: z.array(Address0),
    tags: z.array(Tag),
    attributions: z.array(Attribution),
    id: ContactEntryIdField.uuid(),
    owner: z.string(),
    handle: z.string(),
    name: z.string(),
    image: z.string().nullable(),
  })
  .passthrough()
const Person = z
  .object({
    first_name: z.string(),
    middle_name: z.string().nullish(),
    last_name: z.string(),
    date_of_birth: z.string().nullish(),
    title: z.string().nullish(),
    suffix: z.string().nullish(),
  })
  .passthrough()
const GetContactEntryExpandedViewResponseData = z
  .object({ contact_entry: ContactEntry2, notes: z.array(Note0), person: Person.nullish() })
  .passthrough()
const GetContactEntryIdViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    owner_handle: z.string().min(1),
    identity_handle: z.string().min(1),
  })
  .passthrough()
const GetContactEntryIdViewResponseData = z
  .object({ contact_entry_id: ContactEntryIdField.uuid() })
  .passthrough()
const GetContactEntryIdentityIdsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    owner_handle: z.string().min(1),
    identity_handle: z.string().min(1),
  })
  .passthrough()
const GetContactEntryIdentityIdsViewResponseData = z
  .object({
    identity_id: IdentityIdField.uuid(),
    contact_id: ContactIdField.uuid(),
    target_id: TargetIdField.uuid(),
  })
  .passthrough()
const GetContactsHeaderViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), file_url: z.string().min(1) })
  .passthrough()
const GetContactsHeaderViewResponseData = z
  .object({ contacts_header: z.array(z.string()) })
  .passthrough()
const GetMyLocationViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid() })
  .passthrough()
const GetMyLocationViewResponseData = z.object({ point: GeoPoint }).passthrough()
const GetOrganizationLocationViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), organization_id: IdentityIdField.uuid() })
  .passthrough()
const GetOrganizationLocationViewResponseData = z.object({ point: GeoPoint }).passthrough()
const NameImportConfigRequest = z
  .object({
    title: z.string().nullable(),
    first_name: z.string(),
    middle_name: z.string().nullable(),
    last_name: z.string(),
    suffix: z.string().nullable(),
  })
  .passthrough()
const AddressImportConfigRequest = z
  .object({
    street: z.string(),
    unit: z.string().nullable(),
    city: z.string(),
    state: z.string(),
    zip: z.string(),
    longitude: z.string().nullable(),
    latitude: z.string().nullable(),
  })
  .passthrough()
const ImportContactsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    owner: IdentityIdField.uuid(),
    name: NameImportConfigRequest,
    date_of_birth: z.string(),
    emails: z.array(z.string().min(1)),
    phones: z.array(z.string().min(1)),
    one_line_addresses: z.array(z.string().min(1)),
    structured_addresses: z.array(AddressImportConfigRequest),
    attributions: z.array(z.array(z.string().min(1)).min(2).max(2)),
    tags: z.array(z.string().min(1)),
    title_flag: z.boolean(),
    update_contact_via_email_address_flag: z.boolean(),
    url: z.string().min(1),
  })
  .passthrough()
const ImportContactsViewResponseData = z
  .object({ email_address_conflict: z.boolean() })
  .passthrough()
const ListAddressesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    address_ids: z.array(AddressIdField).optional(),
    contact_ids: z.array(ContactIdField).optional(),
  })
  .passthrough()
const ListAddressesViewResponseData = z.object({ addresses: z.array(Address0) }).passthrough()
const ListContactEmailsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), contact_filter: ContactFilterRequest })
  .passthrough()
const ListContactEmailsViewResponseData = z
  .object({ contact_emails: z.array(ContactEmail0) })
  .passthrough()
const ListContactEntriesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    contact_filter: ContactFilterRequest,
    excludes: z.array(ContactEntryIdField).optional(),
    limit: z.number().int().gte(0).optional(),
    offset: z.number().int().gte(0).optional(),
  })
  .passthrough()
const ListContactEntriesViewResponseData = z
  .object({ contact_entries: z.array(ContactEntry2) })
  .passthrough()
const ListContactEntriesAddressPointsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    contact_filter: ContactFilterRequest,
    excludes: z.array(ContactEntryIdField).optional(),
    limit: z.number().int().gte(0).optional(),
  })
  .passthrough()
const ListContactEntriesAddressPointsViewResponseData = z
  .object({ address_points: z.array(GeoPoint) })
  .passthrough()
const ListContactIdentitiesViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), contact_entry_id: ContactEntryIdField.uuid() })
  .passthrough()
const ListContactIdentitiesViewResponseData = z
  .object({ identities: z.array(IdentityIdField) })
  .passthrough()
const ListContactOwnersViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid() })
  .passthrough()
const ListContactOwnersViewResponseData = z
  .object({ owners: z.array(IdentityIdField) })
  .passthrough()
const ListEmailAddressesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    contact_ids: z.array(ContactIdField).optional(),
    identity_ids: z.array(IdentityIdField).optional(),
  })
  .passthrough()
const ListEmailAddressesViewResponseData = z
  .object({ contact_emails: z.array(ContactEmail0) })
  .passthrough()
const ListPhoneNumbersViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), contact_ids: z.array(ContactIdField).optional() })
  .passthrough()
const ListPhoneNumbersViewResponseData = z
  .object({ phone_numbers: z.array(PhoneNumber0) })
  .passthrough()
const ObtainEmailAddressViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), email: z.string().min(1) })
  .passthrough()
const ObtainEmailAddressViewResponseData = z
  .object({
    contact_email_id: ContactEmailIdField.uuid(),
    identity_id: IdentityIdField.uuid(),
    email_address_id: EmailAddressIdField.uuid(),
  })
  .passthrough()
const QuickCreateAddressViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), address: z.string().min(1) })
  .passthrough()
const QuickCreateAddressViewResponseData = z.object({ address: Address0 }).passthrough()
const RemoveAddressViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    address_id: AddressIdField.uuid(),
    contact_id: ContactIdField.uuid().optional(),
  })
  .passthrough()
const RemoveEmailAddressViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    contact_email_id: ContactEmailIdField.uuid(),
    contact_id: ContactIdField.uuid().optional(),
  })
  .passthrough()
const RemovePhoneNumberViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    phone_number_id: PhoneNumberIdField.uuid(),
    contact_id: ContactIdField.uuid().optional(),
  })
  .passthrough()
const ReverseSearchAddressesViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), location: GeoPointRequest })
  .passthrough()
const ReverseSearchAddressesViewResponseData = z
  .object({ addresses: z.array(z.string()) })
  .passthrough()
const SearchAddressesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    address: z.string().min(1),
    location: GeoPointRequest.optional(),
  })
  .passthrough()
const SearchAddressesViewResponseData = z.object({ addresses: z.array(z.string()) }).passthrough()
const SearchContactEntriesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    query: z.string().min(1),
    contact_filter: ContactFilterRequest,
    limit: z.number().int().optional(),
    excludes: z.array(ContactEntryIdField).optional(),
  })
  .passthrough()
const SearchContactEntriesViewResponseData = z
  .object({ contact_entries: z.array(ContactEntry2), has_more: z.boolean() })
  .passthrough()
const SearchContactsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    query: z.string().min(1),
    names: z.boolean(),
    emails: z.boolean(),
    phones: z.boolean(),
    messages: z.boolean(),
    limit: z.number().int().optional(),
  })
  .passthrough()
const SearchContactsViewResponseData = z.object({ contacts: z.array(Identity2) }).passthrough()
const SearchEmailsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    query: z.string().min(2),
    limit: z.number().int().gte(1).lte(100).optional().default(20),
  })
  .passthrough()
const EmailAddressResult = z
  .object({
    id: EmailAddressIdField.uuid(),
    address: z.string(),
    contact_name: z.string().nullable(),
  })
  .passthrough()
const SearchEmailsViewResponseData = z
  .object({ results: z.array(EmailAddressResult) })
  .passthrough()
const SendEmailViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    from_id: EmailAddressIdField.uuid(),
    to_ids: z.array(EmailAddressIdField),
    cc_ids: z.array(EmailAddressIdField),
    bcc_ids: z.array(EmailAddressIdField),
    subject: z.string().min(1),
    body: z.string().min(1),
    attachments: z.array(AttachmentRequest),
    case_id: CaseIdField.uuid(),
  })
  .passthrough()
const SendEmailViewResponseData = z.object({ email_id: EmailIdField.uuid() }).passthrough()
const SendMassEmailViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    from_id: EmailAddressIdField.uuid(),
    to_ids: z.array(EmailAddressIdField),
    subject: z.string().min(1),
    body: z.string().min(1),
    attachments: z.array(AttachmentRequest),
    case_id: CaseIdField.uuid(),
  })
  .passthrough()
const SetEmailSignatureViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    contact_email_id: ContactEmailIdField.uuid(),
    signature: z.string().min(1),
  })
  .passthrough()
const SetPrimaryEmailAddressViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), contact_email_id: ContactEmailIdField.uuid() })
  .passthrough()
const SetReplyToViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    email_address_id: EmailAddressIdField.uuid().optional(),
  })
  .passthrough()
const UpdateCaseLocationViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    case_id: CaseIdField.uuid(),
    address_id: AddressIdField.nullable(),
  })
  .passthrough()
const VerifyEmailViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    contact_email_id: ContactEmailIdField.uuid(),
    verify_options: VerifyOptionsRequest,
  })
  .passthrough()
const RoleIdField = z.string()
const AddAdminViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    admin_id: IdentityIdField.uuid(),
    role_id: RoleIdField.uuid(),
  })
  .passthrough()
const AddPermissionViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    role_id: RoleIdField.uuid(),
    admin: z.boolean(),
    staff: z.boolean(),
  })
  .passthrough()
const AddRoleViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    role_name: z.string().min(1),
  })
  .passthrough()
const AddRoleViewResponseData = z.object({ role_id: RoleIdField.uuid() }).passthrough()
const BreakdownContactTagsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), organization_identity_id: IdentityIdField.uuid() })
  .passthrough()
const Tag0 = z.object({ tag: Tag, count: z.number().int() }).passthrough()
const BreakdownContactTagsViewResponseData = z.object({ tags: z.array(Tag0) }).passthrough()
const BreakdownTagsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    include_phone: z.boolean(),
    include_email: z.boolean(),
    include_in_person: z.boolean(),
    start_time: z.string().datetime({ offset: true }).optional(),
    end_time: z.string().datetime({ offset: true }).optional(),
    tag_ids: z.array(TagIdField).optional(),
  })
  .passthrough()
const BreakdownTagsViewResponseData = z.object({ tags: z.array(Tag0) }).passthrough()
const ChangePasswordViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    current_password: z.string().min(1),
    new_password: z.string().min(1),
  })
  .passthrough()
const CreateAttributionViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    target_id: TargetIdField.uuid(),
    attribute_id: AttributeIdField.uuid().optional(),
    name: z.string().min(1),
    value: z.string().min(1),
  })
  .passthrough()
const CreateDepartmentViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: OrganizationIdField.uuid(),
    suffix: z.string().min(1),
    name: z.string().min(1),
  })
  .passthrough()
const CreateDepartmentViewResponseData = z
  .object({ organization_id: OrganizationIdField.uuid() })
  .passthrough()
const CreateNoteViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    target_id: TargetIdField.uuid(),
    note: z.string().min(1),
  })
  .passthrough()
const CreateNoteViewResponseData = z.object({ note_id: NoteIdField.uuid() }).passthrough()
const CreateTagViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    name: z.string().min(1),
    color: z.string().min(1),
    description: z.string().optional(),
  })
  .passthrough()
const CreateTagViewResponseData = z.object({ tag: Tag }).passthrough()
const DeleteNoteViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), note_id: NoteIdField.uuid() })
  .passthrough()
const DeleteTagViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), tag_id: TagIdField.uuid() })
  .passthrough()
const EditContactBirthdayViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    contact_id: ContactIdField.uuid().optional(),
    birthday: z.string(),
  })
  .passthrough()
const EditContactImageViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    contact_id: ContactIdField.uuid().optional(),
    identity_id: IdentityIdField.uuid().optional(),
    image: z.string().min(1),
  })
  .passthrough()
const EditContactImageViewResponseData = z.object({ image: z.string() }).passthrough()
const EditContactNameViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    contact_id: ContactIdField.uuid().optional(),
    name: z.string().min(1),
    title: z.string().optional(),
    first: z.string().optional(),
    middle: z.string().optional(),
    last: z.string().optional(),
    suffix: z.string().optional(),
  })
  .passthrough()
const EditRoleViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), role_id: RoleIdField.uuid(), name: z.string().min(1) })
  .passthrough()
const EditTagViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    tag_id: TagIdField.uuid(),
    name: z.string().min(1).optional(),
    color: z.string().min(1).optional(),
    description: z.string().optional(),
  })
  .passthrough()
const GetAccountViewRequestDataRequest = z.object({ user_id: EndUserIdField.uuid() }).passthrough()
const GetAccountViewResponseData = z
  .object({
    title: z.string().nullable(),
    first_name: z.string().nullable(),
    last_name: z.string().nullable(),
    profile: z.string().nullable(),
    notify: z.boolean(),
  })
  .passthrough()
const GetIdentityViewRequestDataRequest = z
  .object({ identity_id: IdentityIdField.uuid(), handle: z.string().min(1) })
  .partial()
  .passthrough()
const Identity1 = z
  .object({
    id: IdentityIdField.uuid(),
    contact_id: ContactIdField.uuid(),
    handle: z.string(),
    name: z.string(),
    image: z.string().nullable(),
    stripe_id: z.string().nullable(),
    email: z.string().nullable(),
    contacts_owner_identity_id: IdentityIdField.uuid(),
    phone: z.string().nullable(),
    first_name: z.string().nullable(),
    last_name: z.string().nullable(),
    birthday: z.string().nullable(),
    auto_payout: z.boolean().nullable(),
    organization_type: z.union([OrganizationTypeEnum, NullEnum]).nullable(),
  })
  .passthrough()
const GetIdentityViewResponseData = z.object({ identity: Identity1 }).passthrough()
const GetIdentityBasicViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), contact_ids: z.array(ContactIdField) })
  .passthrough()
const GetIdentityBasicViewResponseData = z
  .object({ name: z.string(), profile: z.string().nullable(), birthday: z.string().nullable() })
  .passthrough()
const GetNoteViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), note_id: NoteIdField.uuid() })
  .passthrough()
const GetNoteViewResponseData = z.object({ note: Note0 }).passthrough()
const GetUserViewRequestDataRequest = z.object({ user_id: EndUserIdField.uuid() }).passthrough()
const SkinEnum = z.enum(['Casual', 'Professional'])
const GetUserViewResponseData = z
  .object({
    id: EndUserIdField.uuid(),
    identity_id: IdentityIdField.uuid(),
    logged_in: z.boolean(),
    verified: z.boolean().nullable(),
    name: z.string().nullable(),
    image: z.string().nullable(),
    super: z.boolean().nullable(),
    skin: z.union([SkinEnum, NullEnum]).nullable(),
    beta: z.boolean().nullable(),
    has_password: z.boolean(),
    reply_to: EmailAddressIdField.nullable(),
  })
  .passthrough()
const EmptyRequestSerializerEmptyRequestRequest = z
  .object({ _body: z.string() })
  .partial()
  .passthrough()
const GetUserFromSessionViewResponseData = z
  .object({ data: EndUserIdField.nullable() })
  .passthrough()
const GetUserIdsViewRequestDataRequest = z
  .object({ user_ids: z.array(EndUserIdField) })
  .passthrough()
const UserIds = z
  .object({
    user_id: EndUserIdField.uuid(),
    identity_id: IdentityIdField.uuid(),
    contact_id: ContactIdField.uuid(),
  })
  .passthrough()
const GetUserIdsViewResponseData = z.object({ users: z.array(UserIds) }).passthrough()
const IdentityToUserViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), identity_id: IdentityIdField.uuid() })
  .passthrough()
const IdentityToUserViewResponseData = z.object({ user_id: EndUserIdField.uuid() }).passthrough()
const ListAttributionsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), target_ids: z.array(TargetIdField) })
  .passthrough()
const AttributionIdField = z.string()
const Attribution0 = z
  .object({ attribute: Attribute, id: AttributionIdField.uuid(), value: z.string() })
  .passthrough()
const ListAttributionsViewResponseData = z
  .object({ attributions: z.array(Attribution0) })
  .passthrough()
const ListDistinctEmailIdentitiesViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), u_first: z.boolean().optional() })
  .passthrough()
const EmailIdentity = z
  .object({
    id: IdentityIdField.uuid(),
    handle: z.string().nullable(),
    name: z.string().nullable(),
    image: z.string().nullable(),
    email: z.string().nullable(),
    email_address_id: EmailAddressIdField.nullable(),
    email_id: EmailIdField.nullable(),
    email_ses_verified: z.boolean().nullable(),
    email_signature: z.string().nullable(),
    email_stripe_id: z.string().nullable(),
    organization_type: z.string().nullable(),
    organization_id: OrganizationIdField.nullable(),
    is_direct_member: z.boolean(),
  })
  .passthrough()
const ListDistinctEmailIdentitiesViewResponseData = z
  .object({ data: z.array(EmailIdentity) })
  .passthrough()
const ListIdentitiesViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), identity_ids: z.array(IdentityIdField) })
  .passthrough()
const ListIdentitiesViewResponseData = z.object({ identities: z.array(Identity1) }).passthrough()
const ListIdentitiesByIdsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), identity_ids: z.array(IdentityIdField) })
  .passthrough()
const ListIdentitiesByIdsViewResponseData = z
  .object({ identities: z.array(Identity2) })
  .passthrough()
const ListIdentityPermissionsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    only_me: z.boolean(),
  })
  .passthrough()
const IdentityPermissionIdField = z.string()
const IdentityPermission = z
  .object({
    id: IdentityPermissionIdField.uuid(),
    identity: IdentityIdField.uuid(),
    role: RoleIdField.uuid(),
    admin: z.boolean(),
    staff: z.boolean(),
  })
  .passthrough()
const ListIdentityPermissionsViewResponseData = z
  .object({ identity_permissions: z.array(IdentityPermission) })
  .passthrough()
const ListMembersViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), organization_identity_id: IdentityIdField.uuid() })
  .passthrough()
const Member0 = z.object({ identity: Identity0, role_id: RoleIdField.uuid() }).passthrough()
const ListMembersViewResponseData = z.object({ members: z.array(Member0) }).passthrough()
const ListNotesViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), target_ids: z.array(TargetIdField) })
  .passthrough()
const ListNotesViewResponseData = z.object({ notes: z.array(Note0) }).passthrough()
const TypesEnum = z.enum([
  'OrganizationCity',
  'OrganizationCountry',
  'OrganizationCounty',
  'OrganizationDepartment',
  'OrganizationNGO',
  'OrganizationPoli',
  'OrganizationState',
])
const ListOrganizationsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_ids: z.array(OrganizationIdField).optional(),
    identity_ids: z.array(IdentityIdField).optional(),
    phone_id: PhoneNumberIdField.uuid().optional(),
    parent_id: OrganizationIdField.uuid().optional(),
    types: z.array(TypesEnum).optional(),
    query: z.string().min(1).optional(),
  })
  .passthrough()
const OrganizationQuerySettings = z
  .object({
    seed: z.number().int().nullable(),
    search_temperature: z.number().nullable(),
    chat_temperature: z.number().nullable(),
    voice_temperature: z.number().nullable(),
    chat_prompt: z.string().nullable(),
    search_prompt: z.string().nullable(),
    voice_prompt: z.string().nullable(),
  })
  .passthrough()
const Organization0 = z
  .object({
    query_settings: OrganizationQuerySettings,
    information: z.string(),
    id: z.string(),
    identity: z.string(),
    handle: z.string(),
    name: z.string(),
    image: z.string().nullable(),
    type: z.string(),
    banner: z.string().nullable(),
    background_color: z.string(),
    font_color: z.string(),
    search_url: z.string().nullable(),
    active: z.boolean(),
    premium_ai: z.boolean(),
    phone_line: z.string().nullable(),
    phone: z.string().nullable(),
    velosimo_integrated: z.boolean(),
  })
  .passthrough()
const ListOrganizationsViewResponseData = z
  .object({ organizations: z.array(Organization0) })
  .passthrough()
const ListParticipantsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    case_id: CaseIdField.uuid(),
    include_messages: z.boolean(),
  })
  .passthrough()
const ListParticipantsViewResponseData = z
  .object({ participants: z.array(Identity2) })
  .passthrough()
const ListQuillDashboardsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: OrganizationIdField.uuid(),
    organization_id: OrganizationIdField.uuid(),
  })
  .passthrough()
const QuillDashboardIdField = z.string()
const QuillDashboard = z
  .object({
    id: QuillDashboardIdField.uuid(),
    quill_name: z.string(),
    title: z.string(),
    created: z.string().datetime({ offset: true }).optional(),
    organization: z.string().uuid(),
    processes: z.array(z.string().uuid()).optional(),
  })
  .passthrough()
const ListQuillDashboardsViewResponseData = z
  .object({ dashboards: z.array(QuillDashboard) })
  .passthrough()
const ListRolesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    role_ids: z.array(RoleIdField).optional(),
    owner_id: IdentityIdField.uuid().optional(),
  })
  .passthrough()
const Role1 = z.object({ owner: Identity0, name: z.string(), id: RoleIdField.uuid() }).passthrough()
const ListRolesViewResponseData = z.object({ roles: z.array(Role1) }).passthrough()
const ListStaffersViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    identity_ids: z.array(IdentityIdField).optional(),
    limit: z.number().int().optional(),
    query: z.string().optional(),
  })
  .passthrough()
const ListStaffersViewResponseData = z.object({ identities: z.array(Identity0) }).passthrough()
const ListTaggingsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), target_ids: z.array(TargetIdField) })
  .passthrough()
const TaggingIdField = z.string()
const Tagging0 = z
  .object({ tag: Tag, id: TaggingIdField.uuid(), target: TargetIdField.uuid() })
  .passthrough()
const ListTaggingsViewResponseData = z.object({ taggings: z.array(Tagging0) }).passthrough()
const ListTagsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    tag_ids: z.array(TagIdField).optional(),
    owner_ids: z.array(IdentityIdField).optional(),
  })
  .passthrough()
const ListTagsViewResponseData = z.object({ tags: z.array(Tag) }).passthrough()
const RemoveMemberViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    member_identity_id: IdentityIdField.uuid(),
    member_role_id: RoleIdField.uuid(),
  })
  .passthrough()
const RemovePermissionViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    identity_permission_id: IdentityPermissionIdField.uuid(),
  })
  .passthrough()
const RemoveRoleViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), role_id: RoleIdField.uuid() })
  .passthrough()
const ResetPasswordViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    reset_code: z.string().min(1),
    new_password: z.string().min(1),
  })
  .passthrough()
const ResetPasswordRequestViewRequestDataRequest = z
  .object({ email: z.string().min(1).email() })
  .passthrough()
const SearchAttributesViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), query: z.string().min(1) })
  .passthrough()
const SearchAttributesViewResponseData = z.object({ attributes: z.array(Attribute) }).passthrough()
const SearchTagsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    identity_id: IdentityIdField.uuid().optional(),
    query: z.string().optional(),
    limit: z.number().int().optional(),
  })
  .passthrough()
const SearchTagsViewResponseData = z.object({ tags: z.array(Tag) }).passthrough()
const SetIdentityAutoPayoutViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    auto_payout: z.boolean(),
  })
  .passthrough()
const SetIdentityContactBookViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    contact_book: IdentityIdField.uuid(),
  })
  .passthrough()
const SetNotifyViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), notify: z.boolean() })
  .passthrough()
const SetPasswordViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), new_password: z.string().min(1) })
  .passthrough()
const SetPersonNameRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    title: z.string().optional(),
    first_name: z.string().min(1),
    last_name: z.string().min(1),
  })
  .passthrough()
const SetSkinViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), skin: SkinEnum })
  .passthrough()
const TagViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    target_id: TargetIdField.uuid(),
    tag_id: TagIdField.uuid(),
  })
  .passthrough()
const UnattributeViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), attribution_id: AttributionIdField.uuid() })
  .passthrough()
const UntagViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    target_id: TargetIdField.uuid(),
    tag_id: TagIdField.uuid(),
  })
  .passthrough()
const UpdateNoteViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), note_id: NoteIdField.uuid(), note: z.string().min(1) })
  .passthrough()
const AddChargeViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    bill_id: BillIdField.uuid(),
    name: z.string().min(1),
    description: z.string(),
    amount: z.number().int(),
  })
  .passthrough()
const ChargeIdField = z.string()
const AddChargeViewResponseData = z.object({ charge_id: ChargeIdField.uuid() }).passthrough()
const MethodF1dEnum = z.enum([
  'BankPayment',
  'CardPayment',
  'CashPayment',
  'CheckPayment',
  'MoneyOrderPayment',
  'OtherPayment',
])
const AddPaymentViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    bill_id: BillIdField.uuid(),
    amount: z.number().int().optional(),
    method: MethodF1dEnum,
    payer_id: EndUserIdField.uuid().optional(),
  })
  .passthrough()
const StripeSourceIdField = z.string()
const ConfirmPaymentViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    bill_id: BillIdField.uuid(),
    source_id: StripeSourceIdField.uuid(),
    manual: z.number().int().optional(),
    is_installment: z.boolean(),
    is_bank: z.boolean(),
    is_phone: z.boolean(),
  })
  .passthrough()
const PaymentIdField = z.string()
const ConfirmPaymentViewResponseData = z.object({ payment_id: PaymentIdField.uuid() }).passthrough()
const ConnectStripeViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), organization_identity_id: IdentityIdField.uuid() })
  .passthrough()
const ConnectStripeViewResponseData = z.object({ url: z.string().url() }).passthrough()
const MethodsEnum = z.enum([
  'BankPayment',
  'CardPayment',
  'CashPayment',
  'CheckPayment',
  'MoneyOrderPayment',
  'OtherPayment',
])
const StatusE08Enum = z.enum(['waiting', 'processing', 'succeeded'])
const PaymentListArgsRequest = z
  .object({
    bill_ids: z.array(z.string().uuid()).nullable(),
    start: z.string().datetime({ offset: true }).nullable(),
    end: z.string().datetime({ offset: true }).nullable(),
    methods: z.array(MethodsEnum).nullable(),
    is_stripe: z.boolean().nullable(),
    payment_intent: z.string().nullable(),
    reconciled: z.boolean().nullable(),
    key: z.string().nullable(),
    offset: z.number().int().nullable(),
    limit: z.number().int().nullable(),
    complete: z.boolean().nullable(),
    managed_only: z.boolean(),
    payout_ids: z.array(z.string().uuid()).nullable(),
    process_ids: z.array(z.string().uuid()).nullable(),
    lookup: z.array(z.string().uuid()).nullable(),
    bill_receiver: IdentityIdField.nullable(),
    attempted: z.boolean(),
    no_payout: z.boolean(),
    status: z.array(StatusE08Enum).nullable(),
    payment: PaymentIdField.nullable(),
    payment_code: z.string().nullable(),
    bill_code: z.string().nullable(),
  })
  .partial()
  .passthrough()
const CountPaymentsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    payment_id: PaymentIdField.uuid().optional(),
    step_id: StepIdField.uuid().optional(),
    payment_list_args: PaymentListArgsRequest,
  })
  .passthrough()
const CountPaymentsViewResponseData = z.object({ count: z.number().int() }).passthrough()
const CreateLookupViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    name: z.string().min(1),
    key_name: z.string().min(1),
    key_description: z.string().min(1),
    process_name: z.string().min(1),
    process_description: z.string().min(1),
    bill_step_name: z.string().min(1),
    bill_step_description: z.string().min(1),
    allow_ach: z.boolean(),
  })
  .passthrough()
const LookupIdField = z.string()
const CreateLookupViewResponseData = z.object({ lookup_id: LookupIdField.uuid() }).passthrough()
const CreateLookupEntryViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    lookup_id: LookupIdField.uuid(),
    key: z.string().min(1),
    bill_id: BillIdField.uuid(),
  })
  .passthrough()
const Charge = z
  .object({
    id: ChargeIdField.uuid(),
    bill: BillIdField.uuid(),
    code: z.string().regex(/^[a-z0-9-]+$/),
    name: z.string(),
    description: z.string(),
    amount: z.number().int().gte(-9223372036854776000).lte(9223372036854776000),
    created: z.string().datetime({ offset: true }).optional(),
  })
  .passthrough()
const PayoutIdField = z.string()
const Payment0 = z
  .object({
    id: PaymentIdField.uuid(),
    code: z.string(),
    bill_id: BillIdField.uuid(),
    method: MethodF1dEnum,
    amount: z.number().int(),
    complete: z.boolean(),
    reconciled: z.boolean(),
    created: z.string().datetime({ offset: true }),
    completed: z.string().datetime({ offset: true }).nullable(),
    fee: z.number().int(),
    revenue_object_name: z.string(),
    revenue_object_code: z.string().nullable(),
    revenue_object_description: z.string(),
    bill_name: z.string(),
    bill_code: z.string(),
    bill_account: z.string().nullable(),
    bill_distribution: z.string().nullable(),
    is_stripe: z.boolean(),
    key: z.string().nullable(),
    payout: PayoutIdField.nullable(),
    status: StatusE08Enum,
    attempted: z.string().datetime({ offset: true }).nullable(),
    payer: EndUserIdField.nullable(),
    receiver: IdentityIdField.nullable(),
  })
  .passthrough()
const Bill2 = z
  .object({
    charges: z.array(Charge),
    payments: z.array(Payment0),
    payer: Identity0.nullable(),
    receiver: Identity0,
    process: Process0,
    description: z.string(),
    id: BillIdField.uuid(),
    handle: z.string(),
    created: z.string().datetime({ offset: true }),
    due: z.string().datetime({ offset: true }).nullable(),
    complete: z.boolean(),
    completion_time: z.string().datetime({ offset: true }).nullable(),
    editable: z.boolean(),
    installments: z.number().int(),
    name: z.string(),
    is_stripe: z.boolean(),
    step_id: StepIdField.uuid(),
    bill_step_id: BillStepIdField.uuid(),
    allow_ach: z.boolean(),
    case_id: CaseIdField.uuid(),
    order: z.number().int().nullable(),
    key: z.string().nullable(),
    action: ActionIdField.uuid(),
    item: ItemIdField.uuid(),
  })
  .passthrough()
const LookupEntryIdField = z.string()
const LookupEntry2 = z
  .object({ payment: Bill2, id: LookupEntryIdField.uuid(), key: z.string() })
  .passthrough()
const CreateLookupEntryViewResponseData = z.object({ lookup_entry: LookupEntry2 }).passthrough()
const CreatePayoutViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    payment_ids: z.array(PaymentIdField),
    descriptor: z.string().min(1),
  })
  .passthrough()
const CreatePayoutViewResponseData = z.object({ payout_id: PayoutIdField.uuid() }).passthrough()
const DeleteChargeViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), charge_id: ChargeIdField.uuid() })
  .passthrough()
const EditBillViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    bill_id: BillIdField.uuid(),
    name: z.string().min(1).optional(),
    description: z.string().min(1).optional(),
  })
  .passthrough()
const PaymentFilterRequest = z
  .object({
    reconciled: z.boolean().nullable(),
    status: z.union([StatusE08Enum, NullEnum]).nullable(),
    manual: z.boolean().nullable(),
  })
  .partial()
  .passthrough()
const BillFilterRequest = z
  .object({
    ids: z.array(z.string().uuid()).nullable(),
    item_ids: z.array(z.string().uuid()).nullable(),
    case_ids: z.array(z.string().uuid()).nullable(),
    process_id: ProcessIdField.nullable(),
    step_id: StepIdField.nullable(),
    receiver_ids: z.array(z.string().uuid()).nullable(),
    name: z.string().nullable(),
    description: z.string().nullable(),
    complete: z.boolean().nullable(),
    completed_after: z.string().datetime({ offset: true }).nullable(),
    completed_before: z.string().datetime({ offset: true }).nullable(),
    payment_filter: PaymentFilterRequest.nullable(),
    is_bill_member: z.boolean(),
  })
  .partial()
  .passthrough()
const GetBillCountViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), bill_filter: BillFilterRequest })
  .passthrough()
const GetBillCountViewResponseData = z.object({ count: z.number().int() }).passthrough()
const LookupKeyTypeEnum = z.enum(['id', 'code', 'handle', 'process'])
const LookupKeyRequest = z
  .object({
    type: LookupKeyTypeEnum,
    lookup_id: LookupIdField.nullish(),
    receiver_identity_id: IdentityIdField.nullish(),
    code: z.string().nullish(),
    handle: z.string().nullish(),
    process_id: ProcessIdField.nullish(),
  })
  .passthrough()
const GetLookupViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), lookup_key: LookupKeyRequest })
  .passthrough()
const Lookup0 = z
  .object({
    id: LookupIdField.uuid(),
    code: z.string(),
    name: z.string(),
    description: z.string(),
    process_id: ProcessIdField.uuid(),
    step_id: StepIdField.uuid(),
    receiver_identity_id: IdentityIdField.uuid(),
  })
  .passthrough()
const GetLookupViewResponseData = z.object({ lookup: Lookup0 }).passthrough()
const GetPaymentInfoViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    bill_code: z.string().min(1),
    destination: IdentityIdField.uuid(),
  })
  .passthrough()
const GetPaymentInfoViewResponseData = z.object({ bill: Bill2 }).passthrough()
const GetPaymentTotalViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), bill_filter: BillFilterRequest })
  .passthrough()
const GetPaymentTotalViewResponseData = z.object({ total: z.number().int() }).passthrough()
const GranularityEnum = z.enum(['hour', 'day', 'week', 'month'])
const GetPaymentsStatsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    granularity: GranularityEnum,
    bill_filter: BillFilterRequest,
  })
  .passthrough()
const PaymentInterval = z
  .object({ time: z.string().datetime({ offset: true }), total: z.number().int() })
  .passthrough()
const GetPaymentsStatsViewResponseData = z.object({ data: z.array(PaymentInterval) }).passthrough()
const GetRevenueBreakdownViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), active: z.boolean() })
  .passthrough()
const RevenueStat = z
  .object({ process_id: ProcessIdField.nullable(), name: z.string(), amount: z.number().int() })
  .passthrough()
const GetRevenueBreakdownViewResponseData = z.object({ data: z.array(RevenueStat) }).passthrough()
const GetRevenueTypesBreakdownViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), step_id: StepIdField.uuid().optional() })
  .passthrough()
const RevenueTypeBreakdown = z
  .object({ method: MethodF1dEnum, total: z.number().int() })
  .passthrough()
const GetRevenueTypesBreakdownViewResponseData = z
  .object({ data: z.array(RevenueTypeBreakdown) })
  .passthrough()
const FormatEnum = z.enum(['nemrc', 'southern-auto', 'southern-manual', 'stormwater'])
const ImportBillsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    lookup_id: LookupIdField.uuid(),
    format: FormatEnum,
    url: z.string().min(1),
  })
  .passthrough()
const ListBillsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), bill_filter: BillFilterRequest })
  .passthrough()
const ListBillsViewResponseData = z.object({ bills: z.array(Bill2) }).passthrough()
const ListLookupEntriesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    lookup_key: LookupKeyRequest,
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    bill_filter: BillFilterRequest,
    similar_key: z.string().min(1).nullish(),
    digits_key: z.string().min(1).optional(),
    exact_key: z.string().min(1).optional(),
  })
  .passthrough()
const ListLookupEntriesViewResponseData = z
  .object({ lookup_entries: z.array(LookupEntry2) })
  .passthrough()
const ListLookupsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), active: z.boolean() })
  .passthrough()
const LookupStats = z
  .object({ total: z.number().int(), paid: z.number().int(), receipts: z.number().int() })
  .passthrough()
const Lookup2 = z
  .object({
    stats: LookupStats,
    id: LookupIdField.uuid(),
    code: z.string(),
    name: z.string(),
    description: z.string(),
    process_id: ProcessIdField.uuid(),
    step_id: StepIdField.uuid(),
    owner: z.string(),
  })
  .passthrough()
const ListLookupsViewResponseData = z.object({ lookups: z.array(Lookup2) }).passthrough()
const ListPaymentExportRulesViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), organization_identity_id: IdentityIdField.uuid() })
  .passthrough()
const PaymentExportRuleIdField = z.string()
const PaymentExportRuleMethodEnum = z.enum([
  'BankPayment',
  'CardPayment',
  'CashPayment',
  'CheckPayment',
  'MoneyOrderPayment',
  'OtherPayment',
])
const PaymentExportRule = z
  .object({
    id: PaymentExportRuleIdField.uuid(),
    organization: OrganizationIdField.uuid(),
    method: PaymentExportRuleMethodEnum,
    is_stripe: z.boolean(),
    code: z.string(),
  })
  .passthrough()
const ListPaymentExportRulesViewResponseData = z
  .object({ payment_export_rules: z.array(PaymentExportRule) })
  .passthrough()
const ListPaymentsViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), payment_list_args: PaymentListArgsRequest })
  .passthrough()
const Contact0 = z
  .object({
    email: z.string(),
    identity_id: IdentityIdField.uuid(),
    name: z.string(),
    image: z.string().nullable(),
    address: Address0.nullable(),
  })
  .passthrough()
const Payment1 = z
  .object({
    payer: Contact0.nullish(),
    id: PaymentIdField.uuid(),
    code: z.string(),
    bill_id: BillIdField.uuid(),
    method: MethodF1dEnum,
    amount: z.number().int(),
    complete: z.boolean(),
    reconciled: z.boolean(),
    created: z.string().datetime({ offset: true }),
    completed: z.string().datetime({ offset: true }).nullable(),
    fee: z.number().int(),
    revenue_object_name: z.string(),
    revenue_object_code: z.string().nullable(),
    revenue_object_description: z.string(),
    bill_name: z.string(),
    bill_code: z.string(),
    bill_account: z.string().nullable(),
    bill_distribution: z.string().nullable(),
    is_stripe: z.boolean(),
    key: z.string().nullable(),
    payout: PayoutIdField.nullable(),
    status: StatusE08Enum,
    attempted: z.string().datetime({ offset: true }).nullable(),
    receiver: Identity1.nullable(),
  })
  .passthrough()
const ListPaymentsViewResponseData = z.object({ data: z.array(Payment1) }).passthrough()
const ListPayoutsViewRequestDataRequest = z.object({ user_id: EndUserIdField.uuid() }).passthrough()
const Payout2 = z
  .object({
    payments: z.array(Payment1),
    id: PayoutIdField.uuid(),
    descriptor: z.string(),
    stripe_id: z.string().nullable(),
    time: z.string().datetime({ offset: true }),
  })
  .passthrough()
const ListPayoutsViewResponseData = z.object({ payouts: z.array(Payout2) }).passthrough()
const ListStripeSourcesViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid() })
  .passthrough()
const StripeSourceTypeEnum = z.enum(['StripeSourceBank', 'StripeSourceCard'])
const StripeSource = z
  .object({
    id: StripeSourceIdField.uuid(),
    owner: IdentityIdField.uuid(),
    type: StripeSourceTypeEnum,
    stripe_id: z.string(),
    provider: z.string(),
    last4: z.string(),
    verified: z.boolean(),
  })
  .passthrough()
const ListStripeSourcesViewResponseData = z.object({ sources: z.array(StripeSource) }).passthrough()
const ObtainPaymentIntentViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    bill_id: BillIdField.uuid(),
    manual: z.number().int().optional(),
    is_installment: z.boolean(),
    is_phone: z.boolean(),
    is_bank: z.boolean(),
    save: z.boolean(),
    payer_id: EndUserIdField.uuid().optional(),
  })
  .passthrough()
const Intent = z
  .object({ destination: z.string(), secret: z.string(), intent_id: z.string() })
  .passthrough()
const ObtainPaymentIntentViewResponseData = z.object({ intent: Intent }).passthrough()
const ReconcileViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    payment_ids: z.array(PaymentIdField),
    reconcile: z.boolean(),
  })
  .passthrough()
const SearchBillsViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    start: z.string().datetime({ offset: true }).optional(),
    end: z.string().datetime({ offset: true }).optional(),
    lookup_entry_key: z.string().min(1).optional(),
    bill_name: z.string().min(1).optional(),
    bill_description: z.string().min(1).optional(),
    payment_type: ProcessIdField.uuid().optional(),
    active: z.boolean(),
    has_balance: z.boolean(),
  })
  .passthrough()
const SearchBillsViewResponseData = z.object({ bills: z.array(Bill2) }).passthrough()
const SearchLookupEntriesViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    lookup_id: LookupIdField.uuid(),
    query: z.string().min(1),
  })
  .passthrough()
const SearchLookupEntriesViewResponseData = z
  .object({ lookup_entry: LookupEntry2.nullable() })
  .partial()
  .passthrough()
const SendBillRequestViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    bill_id: BillIdField.uuid(),
    email: z.string().min(1),
    url: z.string().min(1).optional(),
  })
  .passthrough()
const UpsertPaymentExportRuleViewRequestDataRequest = z
  .object({
    user_id: EndUserIdField.uuid(),
    organization_identity_id: IdentityIdField.uuid(),
    method: MethodF1dEnum,
    is_stripe: z.boolean(),
    code: z.string(),
  })
  .passthrough()
const VerifyStripeSourceViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), stripe_source_id: StripeSourceIdField.uuid() })
  .passthrough()
const UploadViewRequestDataRequest = z
  .object({ user_id: EndUserIdField.uuid(), documents: z.array(z.instanceof(File)) })
  .passthrough()
const UploadViewResponseData = z
  .object({ uploadedDocuments: z.array(z.string().url()) })
  .passthrough()

export const schemas = {
  EndUserIdField,
  OrganizationIdField,
  IdentityIdField,
  CrawlDomainViewRequestDataRequest,
  EmptyResponseSerializerEmptyResponse,
  Type495Enum,
  CreateChatViewRequestDataRequest,
  ChatIdField,
  CreateChatViewResponseData,
  ChatTypeEnum,
  NullEnum,
  ChatFilterRequest,
  DownloadChatsViewRequestDataRequest,
  DownloadChatsViewResponseData,
  EditOrganizationAiSettingsViewRequestDataRequest,
  Organization,
  EditOrganizationAiSettingsViewResponseData,
  ContextEnum,
  GenerateResponseViewRequestDataRequest,
  QueryAnalysis,
  ResponseIdField,
  ChatDisplaySource,
  GenerateResponseViewResponseData,
  GetChatCountViewRequestDataRequest,
  GetChatCountViewResponseData,
  GetOrganizationAiSettingsViewRequestDataRequest,
  OrganizationAISettings0,
  GetOrganizationAiSettingsViewResponseData,
  GetPageCountViewRequestDataRequest,
  GetPageCountViewResponseData,
  ListChatsViewRequestDataRequest,
  CaseIdField,
  BlankEnum,
  Chat,
  Query,
  Response,
  ScrapeStatusEnum,
  Page,
  Chunk,
  Response1,
  Chat2,
  ListChatsViewResponseData,
  ListPagesViewRequestDataRequest,
  ListPagesViewResponseData,
  ListSourcesViewRequestDataRequest,
  ListSourcesViewResponseData,
  ListSuggestionsViewRequestDataRequest,
  SuggestionIdField,
  Suggestion,
  ListSuggestionsViewResponseData,
  SetImpressionViewRequestDataRequest,
  SetSuggestionsViewRequestDataRequest,
  SetSuggestionsViewResponseData,
  TranslateTextViewRequestDataRequest,
  TranslateTextViewResponseData,
  ItemIdField,
  AddCaseItemViewRequestDataRequest,
  AddCaseMemberViewRequestDataRequest,
  LogIdField,
  AddCityworksCommentViewRequestDataRequest,
  ProcessIdField,
  AttachProcessViewRequestDataRequest,
  NotificationIdField,
  BumpNotificationViewRequestDataRequest,
  CopyProcessViewRequestDataRequest,
  CopyProcessViewResponseData,
  SectionStepIdField,
  FormStepIdField,
  CopySectionSubStepViewRequestDataRequest,
  CopySectionSubStepViewResponseData,
  ExpressionIdField,
  ChargeSubStepCreateRequest,
  StatusesEnum,
  CreateBillStepViewRequestDataRequest,
  BillStepIdField,
  CreateBillStepViewResponseData,
  CreateCaseViewRequestDataRequest,
  CreateCaseViewResponseData,
  CreateCasesTabViewRequestDataRequest,
  CasesTabIdField,
  CreateCasesTabViewResponseData,
  CreateCityworksSRViewRequestDataRequest,
  CreateDefaultCasesTabsViewRequestDataRequest,
  CreateExpressionViewRequestDataTypeEnum,
  ValueIdField,
  InputStepIdField,
  CreateExpressionViewRequestDataOperatorEnum,
  CreateExpressionViewRequestDataRequest,
  CreateExpressionViewResponseData,
  CreateFormStepViewRequestDataRequest,
  FormIdField,
  CreateFormStepViewResponseData,
  LocationIdField,
  ValueCreateRequest,
  Type12eEnum,
  InputIdField,
  InputCreateRequest,
  CreateInputViewRequestDataRequest,
  CreateInputViewResponseData,
  ValueTypeEnum,
  StyleBadEnum,
  CreateInputStepViewRequestDataRequest,
  CreateInputStepViewResponseData,
  MediumEnum,
  AttachmentRequest,
  CreateMessageViewRequestDataRequest,
  MessageIdField,
  CreateMessageViewResponseData,
  Status2efEnum,
  CreateProcessViewRequestDataRequest,
  CreateProcessViewResponseData,
  CreateRelationViewRequestDataRequest,
  CreateSectionStepViewRequestDataRequest,
  CreateSectionStepViewResponseData,
  SectionSubstep0Request,
  CreateSectionsViewRequestDataRequest,
  SectionIdField,
  CreateSectionsViewResponseData,
  CreateTaskViewRequestDataRequest,
  TaskIdField,
  CreateTaskViewResponseData,
  CreateTestCaseViewRequestDataRequest,
  CreateTestCaseViewResponseData,
  DeleteBillStepViewRequestDataRequest,
  DeleteCaseViewRequestDataRequest,
  DeleteCasesTabViewRequestDataRequest,
  DeleteDraftsViewRequestDataRequest,
  DeleteExpressionsViewRequestDataRequest,
  DeleteFormStepViewRequestDataRequest,
  DeleteInputSubstepViewRequestDataRequest,
  DeleteSectionViewRequestDataRequest,
  DeleteSectionSubstepViewRequestDataRequest,
  MessageTemplateIdField,
  DeleteTemplateViewRequestDataRequest,
  ProcessStatusesEnum,
  CaseFilterRequest,
  DownloadCasesViewRequestDataRequest,
  DownloadCasesViewResponseData,
  EditBillStepViewRequestDataRequest,
  EditCaseViewRequestDataRequest,
  EditCasesTabViewRequestDataRequest,
  DatetimeStepIdField,
  EditDateTimeStepViewRequestDataRequest,
  EditDueDateViewRequestDataRequest,
  EditFormStepViewRequestDataRequest,
  IdentityStepIdField,
  EditIdentityStepViewRequestDataRequest,
  Type623Enum,
  EditInputStepViewRequestDataRequest,
  EditProcessViewRequestDataRequest,
  EditSectionSubStepViewRequestDataRequest,
  StepIdField,
  EditStepViewRequestDataRequest,
  EditTaskViewRequestDataRequest,
  GetBillStepViewRequestDataRequest,
  ChargeSubstepIdField,
  DollarsTypeEnum,
  ChargeSubstep,
  BillStep1,
  GetBillStepViewResponseData,
  GetCaseViewRequestDataRequest,
  ContactIdField,
  Identity0,
  GeoPoint,
  Address0,
  Location0,
  ContactEntryIdField,
  ContactEmailIdField,
  EmailAddressIdField,
  ContactEmail0,
  NoteIdField,
  TargetIdField,
  Note0,
  Identity2,
  Case2,
  GetCaseViewResponseData,
  GetCaseCountViewRequestDataRequest,
  GetCaseCountViewResponseData,
  GetCaseIdViewRequestDataRequest,
  GetCaseIdViewResponseData,
  GetCaseOwnerViewRequestDataRequest,
  GetCaseOwnerViewResponseData,
  GetFormStepCountViewRequestDataRequest,
  GetFormStepCountViewResponseData,
  FormStepTimeFilterRequest,
  GetFormStepTimeViewRequestDataRequest,
  GetFormStepTimeViewResponseData,
  ProcessInfoArgsRequest,
  GetProcessViewRequestDataRequest,
  FormStep0,
  InputStepStyleEnum,
  InputStep,
  DatetimeStep,
  IdentityStep,
  InputStepSub0,
  BoolValue0,
  DatetimeValue0,
  FileValue0,
  IdentityValue0,
  LocationValue0,
  NumberValue0,
  TextValue0,
  Value0,
  InputStepChoice1,
  InputSubstep2,
  SectionSubstep0,
  Process3,
  GetProcessViewResponseData,
  ProcessCaseListArgsRequest,
  GetProcessCaseCountViewRequestDataRequest,
  GetProcessCaseCountViewResponseData,
  GetTaskViewRequestDataRequest,
  Task1,
  GetTaskViewResponseData,
  ImportEmailViewRequestDataRequest,
  ImportEmailViewResponseData,
  InstantiateStepsViewRequestDataRequest,
  ListCaseMembersViewRequestDataRequest,
  ListCaseMembersViewResponseData,
  CaseStatusChangeIdField,
  ListCaseStatusChangesViewRequestDataRequest,
  CaseStatusChange0,
  ListCaseStatusChangesViewResponseData,
  SortEnum,
  ListCasesViewRequestDataRequest,
  ListCasesViewResponseData,
  ListCasesTabsViewRequestDataRequest,
  CasesTab,
  CasesConfigTypeEnum,
  CasesConfig,
  CasesConfig1,
  CasesTab1,
  ListCasesTabsViewResponseData,
  ListCasesWithLocationViewRequestDataRequest,
  CaseWithLocation,
  ListCasesWithLocationViewResponseData,
  ListDraftsViewRequestDataRequest,
  Attachment,
  Draft,
  ListDraftsViewResponseData,
  ListExpressionsViewRequestDataRequest,
  ExpressionOperatorEnum,
  ParameterOneTypeEnum,
  ParameterTwoTypeEnum,
  ParameterThreeTypeEnum,
  Expression,
  ListExpressionsViewResponseData,
  ListFormStepsViewRequestDataRequest,
  ListFormStepsViewResponseData,
  ListFormsViewRequestDataRequest,
  Form0,
  ListFormsViewResponseData,
  InputStepFilterRequest,
  ListInputSubStepsViewRequestDataRequest,
  ListInputSubStepsViewResponseData,
  InputFilterRequest,
  ListInputsViewRequestDataRequest,
  Input1,
  ListInputsViewResponseData,
  ListItemsViewRequestDataRequest,
  BillIdField,
  ActionIdField,
  Bill0,
  Message0,
  ListItemsViewResponseData,
  ListMessagesViewRequestDataRequest,
  Case1,
  Log1,
  EmailAddress0,
  EmailIdField,
  EmailParticipant0TypeEnum,
  EmailParticipant0,
  Email1,
  ListMessagesViewResponseData,
  ListNearbyCasesViewRequestDataRequest,
  ListNearbyCasesViewResponseData,
  NotificationFilterRequest,
  ListNotificationsViewRequestDataRequest,
  Notification1,
  ListNotificationsViewResponseData,
  ListProcessCasesViewRequestDataRequest,
  ProcessCase3,
  ListProcessCasesViewResponseData,
  ListProcessSummariesViewRequestDataRequest,
  ProcessSummary,
  ListProcessSummariesViewResponseData,
  PhoneNumberIdField,
  ProcessFilterRequest,
  ListProcessesViewRequestDataRequest,
  Process0,
  ListProcessesViewResponseData,
  ListProcessesStatsViewRequestDataRequest,
  Process1,
  ListProcessesStatsViewResponseData,
  ListRelationsViewRequestDataRequest,
  RelationIdField,
  Relation,
  ListRelationsViewResponseData,
  ListSectionSubStepsViewRequestDataRequest,
  ListSectionSubStepsViewResponseData,
  ListSectionsViewRequestDataRequest,
  Section,
  ListSectionsViewResponseData,
  ListStepAssignmentsViewRequestDataRequest,
  StepNotificationIdField,
  UserTypeEnum,
  StepNotification,
  ListStepAssignmentsViewResponseData,
  ListTasksViewRequestDataRequest,
  ListTasksViewResponseData,
  ListTemplatesViewRequestDataRequest,
  MessageTemplate0,
  ListTemplatesViewResponseData,
  ListValuesViewRequestDataRequest,
  ListValuesViewResponseData,
  MarkCaseReadViewRequestDataRequest,
  NotifyArgsRequest,
  NotifyViewRequestDataRequest,
  RemoveCaseMemberViewRequestDataRequest,
  InputGroupCreateRequest,
  SaveOrSubmitFormViewRequestDataRequest,
  SearchCasesViewRequestDataRequest,
  SearchCasesViewResponseData,
  SearchCasesCountViewRequestDataRequest,
  SearchCasesCountViewResponseData,
  SearchProcessesViewRequestDataRequest,
  SearchProcessesViewResponseData,
  VelosimoEventEnum,
  SendVelosimoCaseViewRequestDataRequest,
  SetActionCompleteViewRequestDataRequest,
  SetCaseStatusViewRequestDataRequest,
  SetStepAssignmentViewRequestDataRequest,
  StartProcessViewRequestDataRequest,
  StartProcessViewResponseData,
  CasesConfigUpsertRequest,
  UpsertCasesConfigViewRequestDataRequest,
  UpsertTemplateViewRequestDataRequest,
  UpsertTemplateViewResponseData,
  AddressIdField,
  AddAddressViewRequestDataRequest,
  AddPhoneNumberViewRequestDataRequest,
  GeoPointRequest,
  ContactFilterRequest,
  CountContactsViewRequestDataRequest,
  CountContactsViewResponseData,
  CreateAddressViewRequestDataRequest,
  CreateAddressViewResponseData,
  TagIdField,
  AttributeIdField,
  AttributionCreateRequest,
  CreateContactViewRequestDataRequest,
  CreateContactViewResponseData,
  CreateEmailAddressViewRequestDataRequest,
  DeleteContactViewRequestDataRequest,
  DownloadContactsViewRequestDataRequest,
  DownloadContactsViewResponseData,
  DraftEmailViewRequestDataRequest,
  DraftEmailViewResponseData,
  Identity0Request,
  VerifyAdminRequest,
  ChargeSubstepRequest,
  BillStep1Request,
  FormStep0Request,
  InputStepRequest,
  DatetimeStepRequest,
  IdentityStepRequest,
  InputStepSub0Request,
  BoolValue0Request,
  DatetimeValue0Request,
  FileValue0Request,
  IdentityValue0Request,
  LocationValue0Request,
  NumberValue0Request,
  TextValue0Request,
  Value0Request,
  InputStepChoice1Request,
  InputSubstep2Request,
  Process3Request,
  VerifyApplyRequest,
  OrganizationTypeEnum,
  Identity1Request,
  VerifyPaymentRequest,
  VerifyStandardRequest,
  VerifyThreadRequest,
  VerifyOptionsTypeEnum,
  VerifyOptionsRequest,
  EmailSignupViewRequestDataRequest,
  EmailSignupViewResponseData,
  GetContactEntryViewRequestDataRequest,
  ContactEntry0,
  GetContactEntryViewResponseData,
  GetContactEntryExpandedViewRequestDataRequest,
  PhoneNumber0,
  Tag,
  Attribute,
  Attribution,
  ContactEntry2,
  Person,
  GetContactEntryExpandedViewResponseData,
  GetContactEntryIdViewRequestDataRequest,
  GetContactEntryIdViewResponseData,
  GetContactEntryIdentityIdsViewRequestDataRequest,
  GetContactEntryIdentityIdsViewResponseData,
  GetContactsHeaderViewRequestDataRequest,
  GetContactsHeaderViewResponseData,
  GetMyLocationViewRequestDataRequest,
  GetMyLocationViewResponseData,
  GetOrganizationLocationViewRequestDataRequest,
  GetOrganizationLocationViewResponseData,
  NameImportConfigRequest,
  AddressImportConfigRequest,
  ImportContactsViewRequestDataRequest,
  ImportContactsViewResponseData,
  ListAddressesViewRequestDataRequest,
  ListAddressesViewResponseData,
  ListContactEmailsViewRequestDataRequest,
  ListContactEmailsViewResponseData,
  ListContactEntriesViewRequestDataRequest,
  ListContactEntriesViewResponseData,
  ListContactEntriesAddressPointsViewRequestDataRequest,
  ListContactEntriesAddressPointsViewResponseData,
  ListContactIdentitiesViewRequestDataRequest,
  ListContactIdentitiesViewResponseData,
  ListContactOwnersViewRequestDataRequest,
  ListContactOwnersViewResponseData,
  ListEmailAddressesViewRequestDataRequest,
  ListEmailAddressesViewResponseData,
  ListPhoneNumbersViewRequestDataRequest,
  ListPhoneNumbersViewResponseData,
  ObtainEmailAddressViewRequestDataRequest,
  ObtainEmailAddressViewResponseData,
  QuickCreateAddressViewRequestDataRequest,
  QuickCreateAddressViewResponseData,
  RemoveAddressViewRequestDataRequest,
  RemoveEmailAddressViewRequestDataRequest,
  RemovePhoneNumberViewRequestDataRequest,
  ReverseSearchAddressesViewRequestDataRequest,
  ReverseSearchAddressesViewResponseData,
  SearchAddressesViewRequestDataRequest,
  SearchAddressesViewResponseData,
  SearchContactEntriesViewRequestDataRequest,
  SearchContactEntriesViewResponseData,
  SearchContactsViewRequestDataRequest,
  SearchContactsViewResponseData,
  SearchEmailsViewRequestDataRequest,
  EmailAddressResult,
  SearchEmailsViewResponseData,
  SendEmailViewRequestDataRequest,
  SendEmailViewResponseData,
  SendMassEmailViewRequestDataRequest,
  SetEmailSignatureViewRequestDataRequest,
  SetPrimaryEmailAddressViewRequestDataRequest,
  SetReplyToViewRequestDataRequest,
  UpdateCaseLocationViewRequestDataRequest,
  VerifyEmailViewRequestDataRequest,
  RoleIdField,
  AddAdminViewRequestDataRequest,
  AddPermissionViewRequestDataRequest,
  AddRoleViewRequestDataRequest,
  AddRoleViewResponseData,
  BreakdownContactTagsViewRequestDataRequest,
  Tag0,
  BreakdownContactTagsViewResponseData,
  BreakdownTagsViewRequestDataRequest,
  BreakdownTagsViewResponseData,
  ChangePasswordViewRequestDataRequest,
  CreateAttributionViewRequestDataRequest,
  CreateDepartmentViewRequestDataRequest,
  CreateDepartmentViewResponseData,
  CreateNoteViewRequestDataRequest,
  CreateNoteViewResponseData,
  CreateTagViewRequestDataRequest,
  CreateTagViewResponseData,
  DeleteNoteViewRequestDataRequest,
  DeleteTagViewRequestDataRequest,
  EditContactBirthdayViewRequestDataRequest,
  EditContactImageViewRequestDataRequest,
  EditContactImageViewResponseData,
  EditContactNameViewRequestDataRequest,
  EditRoleViewRequestDataRequest,
  EditTagViewRequestDataRequest,
  GetAccountViewRequestDataRequest,
  GetAccountViewResponseData,
  GetIdentityViewRequestDataRequest,
  Identity1,
  GetIdentityViewResponseData,
  GetIdentityBasicViewRequestDataRequest,
  GetIdentityBasicViewResponseData,
  GetNoteViewRequestDataRequest,
  GetNoteViewResponseData,
  GetUserViewRequestDataRequest,
  SkinEnum,
  GetUserViewResponseData,
  EmptyRequestSerializerEmptyRequestRequest,
  GetUserFromSessionViewResponseData,
  GetUserIdsViewRequestDataRequest,
  UserIds,
  GetUserIdsViewResponseData,
  IdentityToUserViewRequestDataRequest,
  IdentityToUserViewResponseData,
  ListAttributionsViewRequestDataRequest,
  AttributionIdField,
  Attribution0,
  ListAttributionsViewResponseData,
  ListDistinctEmailIdentitiesViewRequestDataRequest,
  EmailIdentity,
  ListDistinctEmailIdentitiesViewResponseData,
  ListIdentitiesViewRequestDataRequest,
  ListIdentitiesViewResponseData,
  ListIdentitiesByIdsViewRequestDataRequest,
  ListIdentitiesByIdsViewResponseData,
  ListIdentityPermissionsViewRequestDataRequest,
  IdentityPermissionIdField,
  IdentityPermission,
  ListIdentityPermissionsViewResponseData,
  ListMembersViewRequestDataRequest,
  Member0,
  ListMembersViewResponseData,
  ListNotesViewRequestDataRequest,
  ListNotesViewResponseData,
  TypesEnum,
  ListOrganizationsViewRequestDataRequest,
  OrganizationQuerySettings,
  Organization0,
  ListOrganizationsViewResponseData,
  ListParticipantsViewRequestDataRequest,
  ListParticipantsViewResponseData,
  ListQuillDashboardsViewRequestDataRequest,
  QuillDashboardIdField,
  QuillDashboard,
  ListQuillDashboardsViewResponseData,
  ListRolesViewRequestDataRequest,
  Role1,
  ListRolesViewResponseData,
  ListStaffersViewRequestDataRequest,
  ListStaffersViewResponseData,
  ListTaggingsViewRequestDataRequest,
  TaggingIdField,
  Tagging0,
  ListTaggingsViewResponseData,
  ListTagsViewRequestDataRequest,
  ListTagsViewResponseData,
  RemoveMemberViewRequestDataRequest,
  RemovePermissionViewRequestDataRequest,
  RemoveRoleViewRequestDataRequest,
  ResetPasswordViewRequestDataRequest,
  ResetPasswordRequestViewRequestDataRequest,
  SearchAttributesViewRequestDataRequest,
  SearchAttributesViewResponseData,
  SearchTagsViewRequestDataRequest,
  SearchTagsViewResponseData,
  SetIdentityAutoPayoutViewRequestDataRequest,
  SetIdentityContactBookViewRequestDataRequest,
  SetNotifyViewRequestDataRequest,
  SetPasswordViewRequestDataRequest,
  SetPersonNameRequestDataRequest,
  SetSkinViewRequestDataRequest,
  TagViewRequestDataRequest,
  UnattributeViewRequestDataRequest,
  UntagViewRequestDataRequest,
  UpdateNoteViewRequestDataRequest,
  AddChargeViewRequestDataRequest,
  ChargeIdField,
  AddChargeViewResponseData,
  MethodF1dEnum,
  AddPaymentViewRequestDataRequest,
  StripeSourceIdField,
  ConfirmPaymentViewRequestDataRequest,
  PaymentIdField,
  ConfirmPaymentViewResponseData,
  ConnectStripeViewRequestDataRequest,
  ConnectStripeViewResponseData,
  MethodsEnum,
  StatusE08Enum,
  PaymentListArgsRequest,
  CountPaymentsViewRequestDataRequest,
  CountPaymentsViewResponseData,
  CreateLookupViewRequestDataRequest,
  LookupIdField,
  CreateLookupViewResponseData,
  CreateLookupEntryViewRequestDataRequest,
  Charge,
  PayoutIdField,
  Payment0,
  Bill2,
  LookupEntryIdField,
  LookupEntry2,
  CreateLookupEntryViewResponseData,
  CreatePayoutViewRequestDataRequest,
  CreatePayoutViewResponseData,
  DeleteChargeViewRequestDataRequest,
  EditBillViewRequestDataRequest,
  PaymentFilterRequest,
  BillFilterRequest,
  GetBillCountViewRequestDataRequest,
  GetBillCountViewResponseData,
  LookupKeyTypeEnum,
  LookupKeyRequest,
  GetLookupViewRequestDataRequest,
  Lookup0,
  GetLookupViewResponseData,
  GetPaymentInfoViewRequestDataRequest,
  GetPaymentInfoViewResponseData,
  GetPaymentTotalViewRequestDataRequest,
  GetPaymentTotalViewResponseData,
  GranularityEnum,
  GetPaymentsStatsViewRequestDataRequest,
  PaymentInterval,
  GetPaymentsStatsViewResponseData,
  GetRevenueBreakdownViewRequestDataRequest,
  RevenueStat,
  GetRevenueBreakdownViewResponseData,
  GetRevenueTypesBreakdownViewRequestDataRequest,
  RevenueTypeBreakdown,
  GetRevenueTypesBreakdownViewResponseData,
  FormatEnum,
  ImportBillsViewRequestDataRequest,
  ListBillsViewRequestDataRequest,
  ListBillsViewResponseData,
  ListLookupEntriesViewRequestDataRequest,
  ListLookupEntriesViewResponseData,
  ListLookupsViewRequestDataRequest,
  LookupStats,
  Lookup2,
  ListLookupsViewResponseData,
  ListPaymentExportRulesViewRequestDataRequest,
  PaymentExportRuleIdField,
  PaymentExportRuleMethodEnum,
  PaymentExportRule,
  ListPaymentExportRulesViewResponseData,
  ListPaymentsViewRequestDataRequest,
  Contact0,
  Payment1,
  ListPaymentsViewResponseData,
  ListPayoutsViewRequestDataRequest,
  Payout2,
  ListPayoutsViewResponseData,
  ListStripeSourcesViewRequestDataRequest,
  StripeSourceTypeEnum,
  StripeSource,
  ListStripeSourcesViewResponseData,
  ObtainPaymentIntentViewRequestDataRequest,
  Intent,
  ObtainPaymentIntentViewResponseData,
  ReconcileViewRequestDataRequest,
  SearchBillsViewRequestDataRequest,
  SearchBillsViewResponseData,
  SearchLookupEntriesViewRequestDataRequest,
  SearchLookupEntriesViewResponseData,
  SendBillRequestViewRequestDataRequest,
  UpsertPaymentExportRuleViewRequestDataRequest,
  VerifyStripeSourceViewRequestDataRequest,
  UploadViewRequestDataRequest,
  UploadViewResponseData,
}

const endpoints = makeApi([
  {
    method: 'post',
    path: '/ai/crawl-domain/',
    alias: 'ai_crawlDomain',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CrawlDomainViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/create-chat/',
    alias: 'ai_createChat',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateChatViewRequestDataRequest,
      },
    ],
    response: CreateChatViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/download-chats/',
    alias: 'ai_downloadChats',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DownloadChatsViewRequestDataRequest,
      },
    ],
    response: z.object({ email_recipient: z.string().email() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/edit-organization-ai-settings/',
    alias: 'ai_editOrganizationAiSettings',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditOrganizationAiSettingsViewRequestDataRequest,
      },
    ],
    response: EditOrganizationAiSettingsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/edit-page/',
    alias: 'ai_edit_page_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/ai/generate-response/',
    alias: 'ai_generateResponse',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GenerateResponseViewRequestDataRequest,
      },
    ],
    response: GenerateResponseViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/get-chat-count/',
    alias: 'ai_getChatCount',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetChatCountViewRequestDataRequest,
      },
    ],
    response: z.object({ count: z.number().int() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/get-organization-ai-settings/',
    alias: 'ai_getOrganizationAiSettings',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetOrganizationAiSettingsViewRequestDataRequest,
      },
    ],
    response: GetOrganizationAiSettingsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/get-page-count/',
    alias: 'ai_getPageCount',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetPageCountViewRequestDataRequest,
      },
    ],
    response: z.object({ data: z.number().int() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/list-chats/',
    alias: 'ai_listChats',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListChatsViewRequestDataRequest,
      },
    ],
    response: ListChatsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/list-pages/',
    alias: 'ai_listPages',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListPagesViewRequestDataRequest,
      },
    ],
    response: ListPagesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/list-sources/',
    alias: 'ai_listSources',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListSourcesViewRequestDataRequest,
      },
    ],
    response: ListSourcesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/list-suggestions/',
    alias: 'ai_listSuggestions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListSuggestionsViewRequestDataRequest,
      },
    ],
    response: ListSuggestionsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/remove-pages/',
    alias: 'ai_remove_pages_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/ai/scrape-pages/',
    alias: 'ai_scrape_pages_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/ai/send-chat/',
    alias: 'ai_send_chat_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/ai/set-impression/',
    alias: 'ai_setImpression',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SetImpressionViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/set-suggestions/',
    alias: 'ai_setSuggestions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SetSuggestionsViewRequestDataRequest,
      },
    ],
    response: SetSuggestionsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/translate-text/',
    alias: 'ai_translateText',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TranslateTextViewRequestDataRequest,
      },
    ],
    response: z.object({ translated_text: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/ai/upload-documents/',
    alias: 'ai_upload_documents_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/ai/upsert-page/',
    alias: 'ai_upsert_page_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/cases/add-case-item/',
    alias: 'cases_addCaseItem',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AddCaseItemViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/add-case-member/',
    alias: 'cases_addCaseMember',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AddCaseMemberViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/add-cityworks-comment/',
    alias: 'cases_addCityworksComment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AddCityworksCommentViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/attach-process/',
    alias: 'cases_attachProcess',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AttachProcessViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/bump-notification/',
    alias: 'cases_bumpNotification',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BumpNotificationViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/copy-process/',
    alias: 'cases_copyProcess',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CopyProcessViewRequestDataRequest,
      },
    ],
    response: CopyProcessViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/copy-section-substep/',
    alias: 'cases_copySectionSubStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CopySectionSubStepViewRequestDataRequest,
      },
    ],
    response: CopySectionSubStepViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-bill-step/',
    alias: 'cases_createBillStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateBillStepViewRequestDataRequest,
      },
    ],
    response: CreateBillStepViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-case/',
    alias: 'cases_createCase',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateCaseViewRequestDataRequest,
      },
    ],
    response: CreateCaseViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-cases-tab/',
    alias: 'cases_createCasesTab',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateCasesTabViewRequestDataRequest,
      },
    ],
    response: CreateCasesTabViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-cityworks-sr/',
    alias: 'cases_createCityworksSR',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateCityworksSRViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-default-cases-tabs/',
    alias: 'cases_createDefaultCasesTabs',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateDefaultCasesTabsViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-expression/',
    alias: 'cases_createExpression',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateExpressionViewRequestDataRequest,
      },
    ],
    response: CreateExpressionViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-form-step/',
    alias: 'cases_createFormStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateFormStepViewRequestDataRequest,
      },
    ],
    response: CreateFormStepViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-input-step/',
    alias: 'cases_createInputStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateInputStepViewRequestDataRequest,
      },
    ],
    response: CreateInputStepViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-input/',
    alias: 'cases_createInput',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateInputViewRequestDataRequest,
      },
    ],
    response: CreateInputViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-message/',
    alias: 'cases_createMessage',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateMessageViewRequestDataRequest,
      },
    ],
    response: CreateMessageViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-process/',
    alias: 'cases_createProcess',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateProcessViewRequestDataRequest,
      },
    ],
    response: CreateProcessViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-relation/',
    alias: 'cases_createRelation',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateRelationViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-section-step/',
    alias: 'cases_createSectionStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateSectionStepViewRequestDataRequest,
      },
    ],
    response: CreateSectionStepViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-sections/',
    alias: 'cases_createSections',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateSectionsViewRequestDataRequest,
      },
    ],
    response: CreateSectionsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-task/',
    alias: 'cases_createTask',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateTaskViewRequestDataRequest,
      },
    ],
    response: CreateTaskViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-test-case/',
    alias: 'cases_createTestCase',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateTestCaseViewRequestDataRequest,
      },
    ],
    response: CreateTestCaseViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/create-value/',
    alias: 'cases_create_value_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/cases/delete-assignment/',
    alias: 'cases_delete_assignment_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/cases/delete-bill-step/',
    alias: 'cases_deleteBillStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteBillStepViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/delete-case/',
    alias: 'cases_deleteCase',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteCaseViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/delete-cases-tab/',
    alias: 'cases_deleteCasesTab',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteCasesTabViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/delete-drafts/',
    alias: 'cases_deleteDrafts',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteDraftsViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/delete-expressions/',
    alias: 'cases_deleteExpressions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteExpressionsViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/delete-form-step/',
    alias: 'cases_deleteFormStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteFormStepViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/delete-input-substep/',
    alias: 'cases_deleteInputSubstep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteInputSubstepViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/delete-section-substep/',
    alias: 'cases_deleteSectionSubstep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteSectionSubstepViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/delete-section/',
    alias: 'cases_deleteSection',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteSectionViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/delete-step-assignment/',
    alias: 'cases_delete_step_assignment_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/cases/delete-template/',
    alias: 'cases_deleteTemplate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteTemplateViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/download-cases/',
    alias: 'cases_downloadCases',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DownloadCasesViewRequestDataRequest,
      },
    ],
    response: z.object({ email_recipient: z.string().email() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/edit-bill-step/',
    alias: 'cases_editBillStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditBillStepViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/edit-case/',
    alias: 'cases_editCase',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditCaseViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/edit-cases-tab/',
    alias: 'cases_editCasesTab',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditCasesTabViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/edit-datetime-step/',
    alias: 'cases_editDateTimeStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditDateTimeStepViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/edit-due-date/',
    alias: 'cases_editDueDate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditDueDateViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/edit-form-step/',
    alias: 'cases_editFormStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditFormStepViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/edit-identity-step/',
    alias: 'cases_editIdentityStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditIdentityStepViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/edit-input-step/',
    alias: 'cases_editInputStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditInputStepViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/edit-process/',
    alias: 'cases_editProcess',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditProcessViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/edit-section-substep/',
    alias: 'cases_editSectionSubStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditSectionSubStepViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/edit-step/',
    alias: 'cases_editStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditStepViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/edit-task/',
    alias: 'cases_editTask',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditTaskViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/get-bill-step/',
    alias: 'cases_getBillStep',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetBillStepViewRequestDataRequest,
      },
    ],
    response: GetBillStepViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/get-case-count/',
    alias: 'cases_getCaseCount',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetCaseCountViewRequestDataRequest,
      },
    ],
    response: GetCaseCountViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/get-case-id/',
    alias: 'cases_getCaseId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetCaseIdViewRequestDataRequest,
      },
    ],
    response: GetCaseIdViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/get-case-owner/',
    alias: 'cases_getCaseOwner',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetCaseOwnerViewRequestDataRequest,
      },
    ],
    response: GetCaseOwnerViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/get-case/',
    alias: 'cases_getCase',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetCaseViewRequestDataRequest,
      },
    ],
    response: GetCaseViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/get-form-step-count/',
    alias: 'cases_getFormStepCount',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetFormStepCountViewRequestDataRequest,
      },
    ],
    response: z.object({ count: z.number().int() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/get-form-step-time/',
    alias: 'cases_getFormStepTime',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetFormStepTimeViewRequestDataRequest,
      },
    ],
    response: z.object({ average_duration: z.string().nullable() }).partial().passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/get-process-case-count/',
    alias: 'cases_getProcessCaseCount',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetProcessCaseCountViewRequestDataRequest,
      },
    ],
    response: z.object({ count: z.number().int() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/get-process/',
    alias: 'cases_getProcess',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetProcessViewRequestDataRequest,
      },
    ],
    response: GetProcessViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/get-task/',
    alias: 'cases_getTask',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetTaskViewRequestDataRequest,
      },
    ],
    response: GetTaskViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/import-email/',
    alias: 'cases_importEmail',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ImportEmailViewRequestDataRequest,
      },
    ],
    response: ImportEmailViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/instantiate-steps/',
    alias: 'cases_instantiateSteps',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: InstantiateStepsViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-case-members/',
    alias: 'cases_listCaseMembers',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListCaseMembersViewRequestDataRequest,
      },
    ],
    response: ListCaseMembersViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-case-status-changes/',
    alias: 'cases_listCaseStatusChanges',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListCaseStatusChangesViewRequestDataRequest,
      },
    ],
    response: ListCaseStatusChangesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-cases-tabs/',
    alias: 'cases_listCasesTabs',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListCasesTabsViewRequestDataRequest,
      },
    ],
    response: ListCasesTabsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-cases-with-locations/',
    alias: 'cases_listCasesWithLocation',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListCasesWithLocationViewRequestDataRequest,
      },
    ],
    response: ListCasesWithLocationViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-cases/',
    alias: 'cases_listCases',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListCasesViewRequestDataRequest,
      },
    ],
    response: ListCasesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-drafts/',
    alias: 'cases_listDrafts',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListDraftsViewRequestDataRequest,
      },
    ],
    response: ListDraftsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-expressions/',
    alias: 'cases_listExpressions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListExpressionsViewRequestDataRequest,
      },
    ],
    response: ListExpressionsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-form-steps/',
    alias: 'cases_listFormSteps',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListFormStepsViewRequestDataRequest,
      },
    ],
    response: ListFormStepsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-forms/',
    alias: 'cases_listForms',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListFormsViewRequestDataRequest,
      },
    ],
    response: ListFormsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-input-substeps/',
    alias: 'cases_listInputSubSteps',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListInputSubStepsViewRequestDataRequest,
      },
    ],
    response: ListInputSubStepsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-inputs/',
    alias: 'cases_listInputs',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListInputsViewRequestDataRequest,
      },
    ],
    response: ListInputsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-items/',
    alias: 'cases_listItems',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListItemsViewRequestDataRequest,
      },
    ],
    response: ListItemsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-messages/',
    alias: 'cases_listMessages',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListMessagesViewRequestDataRequest,
      },
    ],
    response: ListMessagesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-nearby-cases/',
    alias: 'cases_listNearbyCases',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListNearbyCasesViewRequestDataRequest,
      },
    ],
    response: ListNearbyCasesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-notifications/',
    alias: 'cases_listNotifications',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListNotificationsViewRequestDataRequest,
      },
    ],
    response: ListNotificationsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-process-cases/',
    alias: 'cases_listProcessCases',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListProcessCasesViewRequestDataRequest,
      },
    ],
    response: ListProcessCasesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-process-summaries/',
    alias: 'cases_listProcessSummaries',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListProcessSummariesViewRequestDataRequest,
      },
    ],
    response: ListProcessSummariesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-processes-stats/',
    alias: 'cases_listProcessesStats',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListProcessesStatsViewRequestDataRequest,
      },
    ],
    response: ListProcessesStatsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-processes/',
    alias: 'cases_listProcesses',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListProcessesViewRequestDataRequest,
      },
    ],
    response: ListProcessesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-relations/',
    alias: 'cases_listRelations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListRelationsViewRequestDataRequest,
      },
    ],
    response: ListRelationsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-section-substeps/',
    alias: 'cases_listSectionSubSteps',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListSectionSubStepsViewRequestDataRequest,
      },
    ],
    response: ListSectionSubStepsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-sections/',
    alias: 'cases_listSections',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListSectionsViewRequestDataRequest,
      },
    ],
    response: ListSectionsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-step-assignments',
    alias: 'cases_listStepAssignments',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListStepAssignmentsViewRequestDataRequest,
      },
    ],
    response: ListStepAssignmentsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-tasks/',
    alias: 'cases_listTasks',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListTasksViewRequestDataRequest,
      },
    ],
    response: ListTasksViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-templates/',
    alias: 'cases_listTemplates',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListTemplatesViewRequestDataRequest,
      },
    ],
    response: ListTemplatesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/list-values/',
    alias: 'cases_listValues',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListValuesViewRequestDataRequest,
      },
    ],
    response: ListValuesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/mark-case-read/',
    alias: 'cases_markCaseRead',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: MarkCaseReadViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/notify/',
    alias: 'cases_notify',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: NotifyViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/remove-case-member/',
    alias: 'cases_removeCaseMember',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RemoveCaseMemberViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/save-or-submit-form/',
    alias: 'cases_saveOrSubmitForm',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SaveOrSubmitFormViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/search-cases-count/',
    alias: 'cases_searchCasesCount',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SearchCasesCountViewRequestDataRequest,
      },
    ],
    response: z.object({ count: z.number().int() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/search-cases/',
    alias: 'cases_searchCases',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SearchCasesViewRequestDataRequest,
      },
    ],
    response: SearchCasesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/search-processes/',
    alias: 'cases_searchProcesses',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SearchProcessesViewRequestDataRequest,
      },
    ],
    response: SearchProcessesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/send-velosimo-case/',
    alias: 'cases_sendVelosimoCase',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SendVelosimoCaseViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/set-action-complete/',
    alias: 'cases_setActionComplete',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SetActionCompleteViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/set-case-status/',
    alias: 'cases_setCaseStatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SetCaseStatusViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/set-step-assignment/',
    alias: 'cases_setStepAssignment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SetStepAssignmentViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/start-process/',
    alias: 'cases_startProcess',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: StartProcessViewRequestDataRequest,
      },
    ],
    response: StartProcessViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/upsert-cases-config/',
    alias: 'cases_upsertCasesConfig',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpsertCasesConfigViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/cases/upsert-template/',
    alias: 'cases_upsertTemplate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpsertTemplateViewRequestDataRequest,
      },
    ],
    response: UpsertTemplateViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/add-address/',
    alias: 'contacts_addAddress',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AddAddressViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/add-phone-number/',
    alias: 'contacts_addPhoneNumber',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AddPhoneNumberViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/count-contacts/',
    alias: 'contacts_countContacts',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CountContactsViewRequestDataRequest,
      },
    ],
    response: z.object({ num_contacts: z.number().int() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/create-address/',
    alias: 'contacts_createAddress',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateAddressViewRequestDataRequest,
      },
    ],
    response: CreateAddressViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/create-contact/',
    alias: 'contacts_createContact',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateContactViewRequestDataRequest,
      },
    ],
    response: CreateContactViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/create-email-address/',
    alias: 'contacts_createEmailAddress',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateEmailAddressViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/delete-contact/',
    alias: 'contacts_deleteContact',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteContactViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/download-contacts/',
    alias: 'contacts_downloadContacts',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DownloadContactsViewRequestDataRequest,
      },
    ],
    response: z.object({ email_recipient: z.string().email() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/draft-email/',
    alias: 'contacts_draftEmail',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DraftEmailViewRequestDataRequest,
      },
    ],
    response: DraftEmailViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/email-signup/',
    alias: 'contacts_emailSignup',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EmailSignupViewRequestDataRequest,
      },
    ],
    response: EmailSignupViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/get-contact-entry-expanded/',
    alias: 'contacts_getContactEntryExpanded',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetContactEntryExpandedViewRequestDataRequest,
      },
    ],
    response: GetContactEntryExpandedViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/get-contact-entry-id/',
    alias: 'contacts_getContactEntryId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetContactEntryIdViewRequestDataRequest,
      },
    ],
    response: GetContactEntryIdViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/get-contact-entry-identity-ids/',
    alias: 'contacts_getContactEntryIdentityIds',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetContactEntryIdentityIdsViewRequestDataRequest,
      },
    ],
    response: GetContactEntryIdentityIdsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/get-contact-entry/',
    alias: 'contacts_getContactEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetContactEntryViewRequestDataRequest,
      },
    ],
    response: GetContactEntryViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/get-contacts-header/',
    alias: 'contacts_getContactsHeader',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetContactsHeaderViewRequestDataRequest,
      },
    ],
    response: GetContactsHeaderViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/get-email-address/',
    alias: 'contacts_get_email_address_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/contacts/get-email-signature/',
    alias: 'contacts_get_email_signature_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/contacts/get-my-location/',
    alias: 'contacts_getMyLocation',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetMyLocationViewRequestDataRequest,
      },
    ],
    response: GetMyLocationViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/get-organization-location/',
    alias: 'contacts_getOrganizationLocation',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetOrganizationLocationViewRequestDataRequest,
      },
    ],
    response: GetOrganizationLocationViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/import-contacts/',
    alias: 'contacts_importContacts',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ImportContactsViewRequestDataRequest,
      },
    ],
    response: z.object({ email_address_conflict: z.boolean() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/list-addresses/',
    alias: 'contacts_listAddresses',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListAddressesViewRequestDataRequest,
      },
    ],
    response: ListAddressesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/list-contact-emails/',
    alias: 'contacts_listContactEmails',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListContactEmailsViewRequestDataRequest,
      },
    ],
    response: ListContactEmailsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/list-contact-entries-address-points/',
    alias: 'contacts_listContactEntriesAddressPoints',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListContactEntriesAddressPointsViewRequestDataRequest,
      },
    ],
    response: ListContactEntriesAddressPointsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/list-contact-entries/',
    alias: 'contacts_listContactEntries',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListContactEntriesViewRequestDataRequest,
      },
    ],
    response: ListContactEntriesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/list-contact-identities/',
    alias: 'contacts_listContactIdentities',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListContactIdentitiesViewRequestDataRequest,
      },
    ],
    response: ListContactIdentitiesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/list-contact-owners/',
    alias: 'contacts_listContactOwners',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListContactOwnersViewRequestDataRequest,
      },
    ],
    response: ListContactOwnersViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/list-email-addresses/',
    alias: 'contacts_listEmailAddresses',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListEmailAddressesViewRequestDataRequest,
      },
    ],
    response: ListEmailAddressesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/list-email-participants/',
    alias: 'contacts_list_email_participants_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/contacts/list-emails/',
    alias: 'contacts_list_emails_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/contacts/list-phone-numbers/',
    alias: 'contacts_listPhoneNumbers',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListPhoneNumbersViewRequestDataRequest,
      },
    ],
    response: ListPhoneNumbersViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/obtain-email-address/',
    alias: 'contacts_obtainEmailAddress',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ObtainEmailAddressViewRequestDataRequest,
      },
    ],
    response: ObtainEmailAddressViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/quick-create-address/',
    alias: 'contacts_quickCreateAddress',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: QuickCreateAddressViewRequestDataRequest,
      },
    ],
    response: QuickCreateAddressViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/remove-address/',
    alias: 'contacts_removeAddress',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RemoveAddressViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/remove-email-address/',
    alias: 'contacts_removeEmailAddress',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RemoveEmailAddressViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/remove-phone-number/',
    alias: 'contacts_removePhoneNumber',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RemovePhoneNumberViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/reverse-search-addresses/',
    alias: 'contacts_reverseSearchAddresses',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ReverseSearchAddressesViewRequestDataRequest,
      },
    ],
    response: ReverseSearchAddressesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/search-addresses/',
    alias: 'contacts_searchAddresses',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SearchAddressesViewRequestDataRequest,
      },
    ],
    response: SearchAddressesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/search-contact-entries/',
    alias: 'contacts_searchContactEntries',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SearchContactEntriesViewRequestDataRequest,
      },
    ],
    response: SearchContactEntriesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/search-contacts/',
    alias: 'contacts_searchContacts',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SearchContactsViewRequestDataRequest,
      },
    ],
    response: SearchContactsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/search-emails/',
    alias: 'contacts_searchEmails',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SearchEmailsViewRequestDataRequest,
      },
    ],
    response: SearchEmailsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/send-email/',
    alias: 'contacts_sendEmail',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SendEmailViewRequestDataRequest,
      },
    ],
    response: SendEmailViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/send-mass-email/',
    alias: 'contacts_sendMassEmail',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SendMassEmailViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/set-email-signature/',
    alias: 'contacts_setEmailSignature',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SetEmailSignatureViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/set-primary-email-address/',
    alias: 'contacts_setPrimaryEmailAddress',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SetPrimaryEmailAddressViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/set-reply-to/',
    alias: 'contacts_setReplyTo',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SetReplyToViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/update-case-location/',
    alias: 'contacts_updateCaseLocation',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateCaseLocationViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/verify-email/',
    alias: 'contacts_verifyEmail',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VerifyEmailViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/contacts/verify-login/',
    alias: 'contacts_verify_login_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/core/add-admin/',
    alias: 'core_addAdmin',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AddAdminViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/add-permission/',
    alias: 'core_addPermission',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AddPermissionViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/add-role/',
    alias: 'core_addRole',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AddRoleViewRequestDataRequest,
      },
    ],
    response: AddRoleViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/breakdown-contact-tags/',
    alias: 'core_breakdownContactTags',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BreakdownContactTagsViewRequestDataRequest,
      },
    ],
    response: BreakdownContactTagsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/breakdown-tags/',
    alias: 'core_breakdownTags',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BreakdownTagsViewRequestDataRequest,
      },
    ],
    response: BreakdownTagsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/change-password/',
    alias: 'core_changePassword',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ChangePasswordViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/create-attribution/',
    alias: 'core_createAttribution',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateAttributionViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/create-department/',
    alias: 'core_createDepartment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateDepartmentViewRequestDataRequest,
      },
    ],
    response: CreateDepartmentViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/create-note/',
    alias: 'core_createNote',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateNoteViewRequestDataRequest,
      },
    ],
    response: CreateNoteViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/create-tag/',
    alias: 'core_createTag',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateTagViewRequestDataRequest,
      },
    ],
    response: CreateTagViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/delete-note/',
    alias: 'core_deleteNote',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteNoteViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/delete-tag/',
    alias: 'core_deleteTag',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteTagViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/edit-contact-birthday/',
    alias: 'core_editContactBirthday',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditContactBirthdayViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/edit-contact-image/',
    alias: 'core_editContactImage',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditContactImageViewRequestDataRequest,
      },
    ],
    response: z.object({ image: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/edit-contact-name/',
    alias: 'core_editContactName',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditContactNameViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/edit-role/',
    alias: 'core_editRole',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditRoleViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/edit-tag/',
    alias: 'core_editTag',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditTagViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/get-account/',
    alias: 'core_getAccount',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetAccountViewRequestDataRequest,
      },
    ],
    response: GetAccountViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/get-identity-basic/',
    alias: 'core_getIdentityBasic',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetIdentityBasicViewRequestDataRequest,
      },
    ],
    response: GetIdentityBasicViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/get-identity/',
    alias: 'core_getIdentity',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetIdentityViewRequestDataRequest,
      },
    ],
    response: GetIdentityViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/get-note/',
    alias: 'core_getNote',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetNoteViewRequestDataRequest,
      },
    ],
    response: GetNoteViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/get-person-name/',
    alias: 'core_get_person_name_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/core/get-user-from-session/',
    alias: 'core_getUserFromSession',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ _body: z.string() }).partial().passthrough(),
      },
    ],
    response: GetUserFromSessionViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/get-user-ids/',
    alias: 'core_getUserIds',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetUserIdsViewRequestDataRequest,
      },
    ],
    response: GetUserIdsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/get-user/',
    alias: 'core_getUser',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetUserViewRequestDataRequest,
      },
    ],
    response: GetUserViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/identity-to-user/',
    alias: 'core_identityToUser',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: IdentityToUserViewRequestDataRequest,
      },
    ],
    response: IdentityToUserViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/list-attributions/',
    alias: 'core_listAttributions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListAttributionsViewRequestDataRequest,
      },
    ],
    response: ListAttributionsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/list-distinct-email-identities/',
    alias: 'core_listDistinctEmailIdentities',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListDistinctEmailIdentitiesViewRequestDataRequest,
      },
    ],
    response: ListDistinctEmailIdentitiesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/list-identities-by-ids/',
    alias: 'core_listIdentitiesByIds',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListIdentitiesByIdsViewRequestDataRequest,
      },
    ],
    response: ListIdentitiesByIdsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/list-identities/',
    alias: 'core_listIdentities',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListIdentitiesViewRequestDataRequest,
      },
    ],
    response: ListIdentitiesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/list-identity-permissions/',
    alias: 'core_listIdentityPermissions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListIdentityPermissionsViewRequestDataRequest,
      },
    ],
    response: ListIdentityPermissionsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/list-members/',
    alias: 'core_listMembers',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListMembersViewRequestDataRequest,
      },
    ],
    response: ListMembersViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/list-notes/',
    alias: 'core_listNotes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListNotesViewRequestDataRequest,
      },
    ],
    response: ListNotesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/list-organizations/',
    alias: 'core_listOrganizations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListOrganizationsViewRequestDataRequest,
      },
    ],
    response: ListOrganizationsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/list-participants/',
    alias: 'core_listParticipants',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListParticipantsViewRequestDataRequest,
      },
    ],
    response: ListParticipantsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/list-quill-dashboards/',
    alias: 'core_listQuillDashboards',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListQuillDashboardsViewRequestDataRequest,
      },
    ],
    response: ListQuillDashboardsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/list-roles/',
    alias: 'core_listRoles',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListRolesViewRequestDataRequest,
      },
    ],
    response: ListRolesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/list-staffers/',
    alias: 'core_listStaffers',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListStaffersViewRequestDataRequest,
      },
    ],
    response: ListStaffersViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/list-taggings/',
    alias: 'core_listTaggings',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListTaggingsViewRequestDataRequest,
      },
    ],
    response: ListTaggingsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/list-tags/',
    alias: 'core_listTags',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListTagsViewRequestDataRequest,
      },
    ],
    response: ListTagsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/login/',
    alias: 'core_login_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/core/logout/',
    alias: 'core_logout_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/core/remove-member/',
    alias: 'core_removeMember',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RemoveMemberViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/remove-permission/',
    alias: 'core_removePermission',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RemovePermissionViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/remove-role/',
    alias: 'core_removeRole',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RemoveRoleViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/reset-password-request/',
    alias: 'core_resetPasswordRequest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ email: z.string().min(1).email() }).passthrough(),
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/reset-password/',
    alias: 'core_resetPassword',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ResetPasswordViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/search-attributes/',
    alias: 'core_searchAttributes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SearchAttributesViewRequestDataRequest,
      },
    ],
    response: SearchAttributesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/search-tags/',
    alias: 'core_searchTags',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SearchTagsViewRequestDataRequest,
      },
    ],
    response: SearchTagsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/set-contacts-owner',
    alias: 'core_set_contacts_owner_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/core/set-identity-auto-payout/',
    alias: 'core_setIdentityAutoPayout',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SetIdentityAutoPayoutViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/set-identity-contact-book/',
    alias: 'core_setIdentityContactBook',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SetIdentityContactBookViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/set-notify/',
    alias: 'core_setNotify',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SetNotifyViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/set-password/',
    alias: 'core_setPassword',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SetPasswordViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/set-person-birthday/',
    alias: 'core_set_person_birthday_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/core/set-person-name/',
    alias: 'core_setPersonName',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SetPersonNameRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/set-skin/',
    alias: 'core_setSkin',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SetSkinViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/tag/',
    alias: 'core_tag',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TagViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/unattribute/',
    alias: 'core_unattribute',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UnattributeViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/untag/',
    alias: 'core_untag',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UntagViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/core/update-note/',
    alias: 'core_updateNote',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateNoteViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/health/',
    alias: 'health_retrieve',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/hooks/action-or-play/:chat_id/:org_id/:current_language/:turn_key',
    alias: 'hooks_action_or_play_create',
    requestFormat: 'json',
    parameters: [
      {
        name: 'chat_id',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'current_language',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'org_id',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'turn_key',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/hooks/chat',
    alias: 'hooks_chat_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/hooks/chat-collect-openai/:chat_id/:org_id/:current_language',
    alias: 'hooks_chat_collect_openai_create',
    requestFormat: 'json',
    parameters: [
      {
        name: 'chat_id',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'current_language',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'org_id',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/hooks/chat-collect/:chat_id/:org_id/:current_language',
    alias: 'hooks_chat_collect_create',
    requestFormat: 'json',
    parameters: [
      {
        name: 'chat_id',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'current_language',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'org_id',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/hooks/chat-openai',
    alias: 'hooks_chat_openai_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/hooks/chat-respond/:chat_id/:org_id/:current_language',
    alias: 'hooks_chat_respond_create',
    requestFormat: 'json',
    parameters: [
      {
        name: 'chat_id',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'current_language',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'org_id',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/hooks/handle-cityworks-event/',
    alias: 'hooks_handle_cityworks_event_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/hooks/velosimo/list-cases',
    alias: 'hooks_velosimo_list_cases_retrieve',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/hooks/velosimo/list-processes',
    alias: 'hooks_velosimo_list_processes_retrieve',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/hooks/velosimo/list-tenant-processes',
    alias: 'hooks_velosimo_list_tenant_processes_retrieve',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/hooks/velosimo/upsert-cases',
    alias: 'hooks_velosimo_upsert_cases_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/payments/add-charge/',
    alias: 'payments_addCharge',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AddChargeViewRequestDataRequest,
      },
    ],
    response: AddChargeViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/add-payment/',
    alias: 'payments_addPayment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AddPaymentViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/confirm-payment/',
    alias: 'payments_confirmPayment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ConfirmPaymentViewRequestDataRequest,
      },
    ],
    response: ConfirmPaymentViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/connect-stripe/',
    alias: 'payments_connectStripe',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ConnectStripeViewRequestDataRequest,
      },
    ],
    response: z.object({ url: z.string().url() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/count-payments/',
    alias: 'payments_countPayments',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CountPaymentsViewRequestDataRequest,
      },
    ],
    response: z.object({ count: z.number().int() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/create-lookup-entry/',
    alias: 'payments_createLookupEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateLookupEntryViewRequestDataRequest,
      },
    ],
    response: CreateLookupEntryViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/create-lookup/',
    alias: 'payments_createLookup',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateLookupViewRequestDataRequest,
      },
    ],
    response: CreateLookupViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/create-payout/',
    alias: 'payments_createPayout',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreatePayoutViewRequestDataRequest,
      },
    ],
    response: CreatePayoutViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/delete-charge/',
    alias: 'payments_deleteCharge',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteChargeViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/delete-payment/',
    alias: 'payments_delete_payment_create',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/payments/edit-bill/',
    alias: 'payments_editBill',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EditBillViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/get-bill-count/',
    alias: 'payments_getBillCount',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetBillCountViewRequestDataRequest,
      },
    ],
    response: z.object({ count: z.number().int() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/get-lookup/',
    alias: 'payments_getLookup',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetLookupViewRequestDataRequest,
      },
    ],
    response: GetLookupViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/get-payment-info/',
    alias: 'payments_getPaymentInfo',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetPaymentInfoViewRequestDataRequest,
      },
    ],
    response: GetPaymentInfoViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/get-payment-total/',
    alias: 'payments_getPaymentTotal',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetPaymentTotalViewRequestDataRequest,
      },
    ],
    response: z.object({ total: z.number().int() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/get-payments-stats/',
    alias: 'payments_getPaymentsStats',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetPaymentsStatsViewRequestDataRequest,
      },
    ],
    response: GetPaymentsStatsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/get-revenue-breakdown/',
    alias: 'payments_getRevenueBreakdown',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetRevenueBreakdownViewRequestDataRequest,
      },
    ],
    response: GetRevenueBreakdownViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/get-revenue-types-breakdown/',
    alias: 'payments_getRevenueTypesBreakdown',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetRevenueTypesBreakdownViewRequestDataRequest,
      },
    ],
    response: GetRevenueTypesBreakdownViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/import-bills/',
    alias: 'payments_importBills',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ImportBillsViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/list-bills/',
    alias: 'payments_listBills',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListBillsViewRequestDataRequest,
      },
    ],
    response: ListBillsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/list-lookup-entries/',
    alias: 'payments_listLookupEntries',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListLookupEntriesViewRequestDataRequest,
      },
    ],
    response: ListLookupEntriesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/list-lookups/',
    alias: 'payments_listLookups',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListLookupsViewRequestDataRequest,
      },
    ],
    response: ListLookupsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/list-payment-export-rules/',
    alias: 'payments_listPaymentExportRules',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListPaymentExportRulesViewRequestDataRequest,
      },
    ],
    response: ListPaymentExportRulesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/list-payments/',
    alias: 'payments_listPayments',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListPaymentsViewRequestDataRequest,
      },
    ],
    response: ListPaymentsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/list-payouts/',
    alias: 'payments_listPayouts',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListPayoutsViewRequestDataRequest,
      },
    ],
    response: ListPayoutsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/list-sources/',
    alias: 'payments_listStripeSources',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ListStripeSourcesViewRequestDataRequest,
      },
    ],
    response: ListStripeSourcesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/obtain-payment-intent/',
    alias: 'payments_obtainPaymentIntent',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ObtainPaymentIntentViewRequestDataRequest,
      },
    ],
    response: ObtainPaymentIntentViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/reconcile/',
    alias: 'payments_reconcile',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ReconcileViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/search-bills/',
    alias: 'payments_searchBills',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SearchBillsViewRequestDataRequest,
      },
    ],
    response: SearchBillsViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/search-lookup-entries/',
    alias: 'payments_searchLookupEntries',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SearchLookupEntriesViewRequestDataRequest,
      },
    ],
    response: SearchLookupEntriesViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/send-bill-request/',
    alias: 'payments_sendBillRequest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SendBillRequestViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/upsert-payment-export-rule/',
    alias: 'payments_upsertPaymentExportRule',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpsertPaymentExportRuleViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/payments/verify-stripe-source/',
    alias: 'payments_verifyStripeSource',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VerifyStripeSourceViewRequestDataRequest,
      },
    ],
    response: z
      .object({ status: z.string().default('ok') })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/schema/',
    alias: 'schema_retrieve',
    description: `OpenApi3 schema for this API. Format can be selected via content negotiation.

- YAML: application/vnd.oai.openapi
- JSON: application/vnd.oai.openapi+json`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'format',
        type: 'Query',
        schema: z.enum(['json', 'yaml']).optional(),
      },
      {
        name: 'lang',
        type: 'Query',
        schema: z
          .enum([
            'af',
            'ar',
            'ar-dz',
            'ast',
            'az',
            'be',
            'bg',
            'bn',
            'br',
            'bs',
            'ca',
            'ckb',
            'cs',
            'cy',
            'da',
            'de',
            'dsb',
            'el',
            'en',
            'en-au',
            'en-gb',
            'eo',
            'es',
            'es-ar',
            'es-co',
            'es-mx',
            'es-ni',
            'es-ve',
            'et',
            'eu',
            'fa',
            'fi',
            'fr',
            'fy',
            'ga',
            'gd',
            'gl',
            'he',
            'hi',
            'hr',
            'hsb',
            'hu',
            'hy',
            'ia',
            'id',
            'ig',
            'io',
            'is',
            'it',
            'ja',
            'ka',
            'kab',
            'kk',
            'km',
            'kn',
            'ko',
            'ky',
            'lb',
            'lt',
            'lv',
            'mk',
            'ml',
            'mn',
            'mr',
            'ms',
            'my',
            'nb',
            'ne',
            'nl',
            'nn',
            'os',
            'pa',
            'pl',
            'pt',
            'pt-br',
            'ro',
            'ru',
            'sk',
            'sl',
            'sq',
            'sr',
            'sr-latn',
            'sv',
            'sw',
            'ta',
            'te',
            'tg',
            'th',
            'tk',
            'tr',
            'tt',
            'udm',
            'uk',
            'ur',
            'uz',
            'vi',
            'zh-hans',
            'zh-hant',
          ])
          .optional(),
      },
    ],
    response: z.object({}).partial().passthrough(),
  },
  {
    method: 'post',
    path: '/upload/',
    alias: 'api_app_upload',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UploadViewRequestDataRequest,
      },
    ],
    response: UploadViewResponseData,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Permission denied`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Internal server error`,
        schema: z.void(),
      },
    ],
  },
])

export const api = new Zodios(endpoints)

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options)
}
