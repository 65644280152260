import { useDispatch } from 'react-redux'

import { apiZodios } from '~api/apiZodios'
import { User } from '~types'

import { loginUser, logoutUser, setUnauthenticated } from '../redux/slices/authSlice'
import { AppDispatch } from '../redux/store'

export const useFetchCreds = () => {
  const dispatch: AppDispatch = useDispatch()

  const fetchUserCredentials = async (userId: string | null, user: User | null) => {
    try {
      if (!userId) {
        dispatch(logoutUser())
        return false
      }

      if (!user || !user.logged_in || userId != user.id) {
        {
          const user = await apiZodios.core_getUser({ user_id: userId })
          if (user.logged_in) {
            dispatch(loginUser(user))
            return true
          } else {
            dispatch(setUnauthenticated())
          }
        }
      }
    } catch (_error) {
      dispatch(setUnauthenticated())
    }
    return false
  }

  return { fetchUserCredentials }
}
