import { Action, Reducer, combineReducers } from 'redux'

import authReducer from './slices/authSlice'
import modalReducer from './slices/modalSlice'
import organizationReducer from './slices/organizationSlice'

export interface AppState {
  auth: ReturnType<typeof authReducer>
  modal: ReturnType<typeof modalReducer>
  organization: ReturnType<typeof organizationReducer>
}

const appReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  organization: organizationReducer,
})

const rootReducer: Reducer<AppState | undefined, Action> = (state, action) => {
  if (action.type === 'RESET_STATE') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
