import * as React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@emotion/react'
import * as ReactDOM from 'react-dom/client'
import { PersistGate } from 'redux-persist/integration/react'

import { IS_PROD, IS_STAGING } from '~config'

import App from './App'
import './components/styles/global.less'
if (IS_PROD || IS_STAGING) {
  import('./SentryInit')
}
import { lightTheme } from './createTheme'
import { persistor, store } from './redux/store'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={lightTheme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
